import React from 'react'
import classNames from 'classnames'
import { isAfter, isSameDay, formatDistanceToNow } from 'date-fns'
import Button from '@ingka/button'
import SkapaModal, { Sheets, ModalHeader, ModalBody, ModalFooter } from '@ingka/modal'
import { upperFirst } from 'lodash'

import { useSharedSelections } from '../SharedSelections'
import { DashboardNotification, Notifications } from './Utils/proto_cms'

import './NotificationsModal.scss'
import { Link } from './Link'
import { analyticsEvent } from './Utils/analytics'
import { useNotificationsState } from '../services/general/service'

interface NotificationsModalProps {
  isOpen: boolean
  closeFn: () => void
}

export const NotificationsModal: React.FC<NotificationsModalProps> = ({ isOpen, closeFn }) => {
  const [{ functionArea }] = useSharedSelections()
  const notificationState = useNotificationsState()
  const notificationsInScope = Notifications.filter(({ scope }) => scope.includes(functionArea))
  const unreadNotifications = notificationsInScope.filter(n => isAfter(n.date, notificationState.lastSeen)).length > 0

  return (
    <SkapaModal className="FeedbackModal" visible={isOpen} handleCloseBtn={closeFn}>
      <Sheets
        size="small"
        header={<ModalHeader title="Notifications" />}
        footer={
          unreadNotifications ? (
            <ModalFooter>
              <Button
                className="MarkAllButton"
                text="Mark all as read"
                type="secondary"
                onClick={notificationState.markAsSeen}
              />
            </ModalFooter>
          ) : null
        }
      >
        <ModalBody>
          <div className="NotificationList">
            {notificationsInScope.map(notification => (
              <React.Fragment key={notification.title}>
                {notification.badge && (
                  <span className="NewFeatureBadge">{`New ${notification.badge === 'kpi' ? 'KPI' : 'feature'}!`}</span>
                )}
                {createOptionalLink(notification, notificationState.lastSeen, notificationState.markAsSeen)}
              </React.Fragment>
            ))}
          </div>
        </ModalBody>
      </Sheets>
    </SkapaModal>
  )
}

const createOptionalLink = (
  notification: DashboardNotification,
  notificationsReadLastTime: Date,
  setNotificationsSeen: () => void
) => {
  const content = (
    <>
      <div className="NotificationTexts">
        <div className="NotificationTitle">{notification.title}</div>
        <div className="NotificationText">{notification.text}</div>
        <div className="NotificationDate">
          {isSameDay(new Date(), notification.date)
            ? 'Today'
            : upperFirst(formatDistanceToNow(notification.date, { includeSeconds: false, addSuffix: true }))}
        </div>
      </div>
      <div
        className={classNames('NotificationIndicator', {
          Unread: isAfter(notification.date, notificationsReadLastTime)
        })}
      />
    </>
  )

  if (notification.link) {
    return (
      <Link
        className={classNames('NotificationItem', 'Link')}
        page={notification.link}
        onClick={() => {
          analyticsEvent({
            category: 'Notifications',
            action: 'open',
            label: notification.title
          })
          setNotificationsSeen()
        }}
      >
        {content}
      </Link>
    )
  }
  return <div className="NotificationItem">{content}</div>
}
