import React from 'react'
import '../../KPIPages/KPIPage.scss'
import { TopBar } from '../../../components/TopBar'
import { PageHeader } from '../../../components/PageHeader'
import { useFilters } from '../ExplorePage'
import { getLocationOrDefault } from '../../../components/Utils/utils'
import {
  getRecyclingRateTreatmentMap,
  getRecyclingRateTreatmentWaste,
  getNonRecyclingWaste,
  getGeneratedByTypeWaste,
  getGeneratedByLocationWaste,
  getLandfillShareWaste,
  WasteSelector
} from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'
import {
  ApiTreatmentMap,
  ApiTreatmentWaste,
  ApiNonRecycledAndGeneratedByType,
  ApiGeneratedByLocationAndLandfill,
  ApiGeneratedByLocation
} from '../../../../api/src/common-types'
import { Route } from '../../../routes'
import { WasteGraphs } from './components/WasteGraphs'
import { RecycledWasteGraphs } from './components/RecycledWasteGraphs'
import { range } from 'lodash'
import { useGetDataAvailability } from '../../../services/general/service'

const kpis = ['recyclingrate', 'waste'] as const
const kpiLabels = {
  recyclingrate: 'Recycling Rate',
  waste: 'Waste'
}

interface RecycledRateData {
  allDates?: string[]
  treatmentMap?: ApiTreatmentMap[]
  treatmentWaste?: ApiTreatmentWaste[]
  nonRecyclingWaste?: ApiNonRecycledAndGeneratedByType[]
  generatedByTypeWaste?: ApiNonRecycledAndGeneratedByType[]
  generatedByLocationWaste?: ApiGeneratedByLocation[]
  landfillShare?: ApiGeneratedByLocationAndLandfill[]
}

export const useRecycledRateData = (
  recycledSelector: WasteSelector,
  kpi: 'recyclingrate' | 'energy' | 'waste' | 'water' | 'refrigerants' | 'footprint'
): RecycledRateData => {
  const initialState = {
    allDates: undefined as string[] | undefined,
    treatmentMap: undefined as ApiTreatmentMap[] | undefined,
    treatmentWaste: undefined as ApiTreatmentWaste[] | undefined,
    nonRecyclingWaste: undefined as ApiNonRecycledAndGeneratedByType[] | undefined,
    generatedByTypeWaste: undefined as ApiNonRecycledAndGeneratedByType[] | undefined,
    generatedByLocationWaste: undefined as ApiGeneratedByLocation[] | undefined,
    landfillShare: undefined as ApiGeneratedByLocationAndLandfill[] | undefined
  }

  const [state, setState] = React.useState(initialState)

  const loadData = async (selector: WasteSelector): Promise<void> => {
    const results =
      kpi === 'recyclingrate'
        ? await Promise.all([
            getNonRecyclingWaste(selector),
            getGeneratedByLocationWaste(selector),
            getLandfillShareWaste(selector),
            getGeneratedByTypeWaste(selector)
          ])
        : await Promise.all([
            getRecyclingRateTreatmentMap(selector),
            getRecyclingRateTreatmentWaste(selector),
            getGeneratedByTypeWaste(selector),
            getGeneratedByLocationWaste(selector),
            getLandfillShareWaste(selector)
          ])

    if (kpi === 'recyclingrate') {
      const [
        nonRecyclingWasteData,
        { dates, data: generatedByLocationWasteData },
        landfillShareData,
        generatedByTypeWasteData
      ] = results as [
        ApiNonRecycledAndGeneratedByType[],
        { dates: string[]; data: ApiGeneratedByLocation[] },
        ApiGeneratedByLocationAndLandfill[],
        ApiNonRecycledAndGeneratedByType[]
      ]

      setState({
        allDates: dates,
        nonRecyclingWaste: nonRecyclingWasteData,
        generatedByLocationWaste: generatedByLocationWasteData,
        landfillShare: landfillShareData,
        generatedByTypeWaste: generatedByTypeWasteData,
        treatmentMap: undefined,
        treatmentWaste: undefined
      })
    } else {
      const [
        treatmentMapData,
        treatmentWasteData,
        generatedByTypeWasteData,
        { dates, data: generatedByLocationWasteData },
        landfillShareData
      ] = results as [
        ApiTreatmentMap[],
        ApiTreatmentWaste[],
        ApiNonRecycledAndGeneratedByType[],
        { dates: string[]; data: ApiGeneratedByLocation[] },
        ApiGeneratedByLocationAndLandfill[]
      ]

      setState({
        allDates: dates,
        treatmentMap: treatmentMapData,
        treatmentWaste: treatmentWasteData,
        generatedByTypeWaste: generatedByTypeWasteData,
        generatedByLocationWaste: generatedByLocationWasteData,
        landfillShare: landfillShareData,
        nonRecyclingWaste: undefined
      })
    }
  }

  const resetState = () => setState(initialState)

  React.useEffect(() => {
    const fetchData = async () => {
      resetState()
      await loadData(recycledSelector)
    }
    if (kpi === 'recyclingrate' || kpi === 'waste') {
      fetchData()
    }
  }, [kpi, JSON.stringify(recycledSelector)])

  return { ...state }
}

export const ZeroWasteExplorePage = () => {
  const { data: dataAvailability } = useGetDataAvailability()
  const [{ func }] = useSharedSelections()
  const [selectedDates, setSelectedDates] = React.useState<Date[]>()
  const [{ kpi, rangeFrom, rangeTo, timeRange }, filterSelectors] = useFilters(
    kpis,
    kpiLabels,
    dataAvailability?.planetCurrentFY ?? 2000
  )
  const locationId = getLocationOrDefault()

  const selector = {
    locationId,
    start_fy: parseInt(rangeFrom) - 2000,
    end_fy: parseInt(rangeTo) - 2000,
    func,
    timeRange
  }

  const {
    allDates,
    treatmentMap,
    treatmentWaste,
    nonRecyclingWaste,
    generatedByTypeWaste,
    generatedByLocationWaste,
    landfillShare
  } = useRecycledRateData(selector, kpi)

  const mergedGeneratedByLocationWaste: ApiGeneratedByLocationAndLandfill[] | undefined =
    generatedByLocationWaste &&
    landfillShare &&
    generatedByLocationWaste.map((item1: ApiGeneratedByLocation) => {
      const item2 = landfillShare.find(
        (item: ApiGeneratedByLocation) =>
          (timeRange === 'annual'
            ? item.fiscal_year === item1.fiscal_year
            : item.readable_date === item1.readable_date) &&
          (locationId === 'ALL' ? item.countryCode === item1.countryCode : item.siteId === item1.siteId)
      )
      return item2 ? { ...item1, landfill_share: item2.landfill_share } : item1
    })

  React.useEffect(() => {
    const rangeToFy = parseInt(rangeTo) - 2000
    setSelectedDates(
      timeRange === 'annual'
        ? range(2016 - 2000, rangeToFy + 1).map(fy => new Date(`${fy + 1999}-09-30`))
        : allDates
            ?.filter(date => date >= `${parseInt(rangeFrom) - 1}-09-01` && date <= `${parseInt(rangeTo)}-08-31`)
            .map(d => new Date(d))
    )
  }, [timeRange, rangeFrom, rangeTo, JSON.stringify(allDates)])

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.ZeroWasteExplorePage} useInFlexLayout />
      <PageHeader className="ZeroWasteHeader" route={Route.ZeroWasteExplorePage}></PageHeader>
      <div className="PageContent">
        {filterSelectors}
        {kpi === 'recyclingrate' ? (
          <RecycledWasteGraphs
            locationId={locationId}
            timeRange={timeRange}
            dates={selectedDates}
            nonRecyclingWaste={nonRecyclingWaste}
            mergedGeneratedByLocationWaste={mergedGeneratedByLocationWaste}
            generatedByTypeWaste={generatedByTypeWaste}
          />
        ) : (
          <WasteGraphs
            dates={selectedDates}
            locationId={locationId}
            timeRange={timeRange}
            mergedGeneratedByLocationWaste={mergedGeneratedByLocationWaste}
            generatedByTypeWaste={generatedByTypeWaste}
            treatmentWaste={treatmentWaste}
            treatmentMap={treatmentMap}
          />
        )}
      </div>
    </div>
  )
}
