import React from 'react'
import classNames from 'classnames'
import { format } from 'date-fns'

import {
  FoodFootprint,
  FoodIngredientsBenchmark,
  ClimateIntensityBenchmarkData,
  DataAvailability
} from '../../../../api/src/common-types'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import { TopBar } from '../../../components/TopBar'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import {
  getCountryOrClusterName,
  getCurentAndPreviousFYMonth,
  isCountryCode,
  getLocationLabel,
  getLocationOrDefault,
  isCluster,
  isSiteId
} from '../../../components/Utils/utils'
import {
  getAvailableDatesFood,
  getClimateIntensityBenchmarks,
  getFoodFootprint,
  getFoodIngredientsBenchmarks
} from '../../../lib/APIClient'
import {
  formatValueAsTons,
  formatPercentage,
  formatChangePercentage,
  formatAbsoluteNumber,
  formatRelativeNumber
} from '../../../components/Utils/format'
import { Route } from '../../../routes'
import { useLocations, CurrentLocation } from '../../../context'
import {
  Benchmarking,
  CardRow,
  KPIPerformance,
  Stripe,
  BenchmarkingModal,
  DataSourceAndModalButton,
  MainCard,
  HeadingItem,
  sortBenchmarks
} from '../../../components/KPIPage'

import {
  TotalClimateFootprintChart,
  ClimateIntensityChart,
  SalesShareChart,
  ClimateIntensityBenchmarkChart,
  getTotalFootprint,
  getTotalAmountKg
} from '../../../components/FoodIngredientsGraphs'
import { sumBy } from 'lodash'
import { ClimateGraphProvider } from './providers/ClimateGraphProvider'
import { ChartContainer, isDataPoint, Serie } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import colours from '../../../Colours.module.scss'
import { formatFoodSeries } from '../../../components/SnapshotTopCards'
import { getIngkaFinancialYear } from '../../../components/Utils/dates'
import { useGetDataAvailability } from '../../../services/general/service'

const emptyClimateIntensity = {
  id: '',
  label: '',
  selectable: false,
  currentMonthFootprint: 0,
  currentMonthSoldWeight: 0,
  currentFYFootprint: 0,
  currentFYSoldWeight: 0,
  previousFYFootprint: 0,
  previousFYSoldWeight: 0,
  previousMonthFootprint: 0,
  previousMonthSoldWeight: 0
}

const formatClimateIntensity = (footprint: number, soldWeight: number) =>
  Number.isFinite(footprint) && Number.isFinite(soldWeight) ? formatRelativeNumber(footprint / soldWeight) : 'N/A'

export const FoodIngredientsKPIPage = () => {
  useDocumentTitle('Food Ingredients')

  const page = Route.FoodIngredientsKPIPage
  const { data: dataAvailability } = useGetDataAvailability()
  const { currentLocation } = useLocations()
  const locationId = getLocationOrDefault()
  const [graphData, setGraphData] = React.useState<FoodFootprint[]>()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const [rawBenchmarks, setRawBenchmarks] = React.useState<FoodIngredientsBenchmark[]>()
  const [climateIntensityBenchmarks, setClimateIntensityBenchmarks] = React.useState<ClimateIntensityBenchmarkData[]>(
    []
  )
  const [climateIntensityModalOpen, setClimateIntensityModalOpen] = React.useState<boolean>(false)
  const dates = graphData?.map(d => new Date(d.readableDate)).slice(0, 12)
  const [actualPeriod, setActualPeriod] = React.useState<string | null>(null)
  const [availableDates, setAvailableDates] = React.useState<string[]>([])

  React.useEffect(() => {
    setGraphData(undefined)
    setLastUpdated('')
    getFoodFootprint(locationId).then(({ lastUpdated, data }) => {
      setGraphData(data.filter(x => x.fiscalYear >= (dataAvailability?.foodPreviousFY ?? 2023)))
      setLastUpdated(lastUpdated)
    })

    if (isCluster(locationId)) {
      return
    }
    setRawBenchmarks([])
    getFoodIngredientsBenchmarks(locationId).then(setRawBenchmarks)
    setClimateIntensityBenchmarks([])
    ;(async () => {
      const { data } = await getAvailableDatesFood(locationId)
      if (data.length === 0) return

      const date = new Date(data[data.length - 1])
      const fy = getIngkaFinancialYear(date).getFullYear()
      const month = date.getMonth() + 1

      await getClimateIntensityBenchmarks(locationId, fy, month).then(({ data }) => {
        setClimateIntensityBenchmarks(data ?? [])
      })
      setAvailableDates(data)
      setActualPeriod(data[data.length - 1])
    })()
  }, [locationId])

  const benchmarkingHeaders = selectBenchmarkingHeaders(dataAvailability)
  const benchmarks = selectBenchmarks(rawBenchmarks || [], locationId, currentLocation)

  const totalClimateFootprintSubtitle = graphData ? (
    <span>
      {formatAbsoluteNumber(
        Math.round(
          graphData
            .filter(x => x.fiscalYear === dataAvailability?.foodCurrentFY)
            .reduce((acc, d) => acc + d.plantBasedFootprint + d.nonRedMeatBasedFootprint + d.redMeatBasedFootprint, 0)
        )
      )}{' '}
      <span className="Label">tonnes CO2e YTD</span>
    </span>
  ) : undefined

  const ytdSalesQty = graphData
    ?.filter(d => d.fiscalYear === dataAvailability?.foodCurrentFY)
    .reduce(
      (ytd, currentMonth) => ({
        plantBasedSalesQty: ytd.plantBasedSalesQty + currentMonth.plantBasedSalesQty,
        totalQty:
          ytd.totalQty +
          currentMonth.plantBasedSalesQty +
          currentMonth.nonRedMeatBasedSalesQty +
          currentMonth.redMeatBasedSalesQty
      }),
      { plantBasedSalesQty: 0, totalQty: 0 }
    )

  const plantBasedQtyAvg = formatPercentage(ytdSalesQty ? ytdSalesQty.plantBasedSalesQty / ytdSalesQty?.totalQty : NaN)
  const plantBasedQtyGoal = formatPercentage(benchmarks.selectedLocation?.plantBasedQtyShareGoal || NaN)

  const kpiPerformance = benchmarks.selectedLocation
    ? [
        {
          key: 'Tonnes CO2e',
          value: [
            benchmarks.selectedLocation?.currentFYTotalFootprint
              ? `${formatAbsoluteNumber(Math.round(benchmarks.selectedLocation?.currentFYTotalFootprint))}`
              : 'N/A',
            benchmarks.selectedLocation.goal ? formatValueAsTons(benchmarks.selectedLocation.goal) : 'N/A'
          ],
          colorStyle:
            benchmarks.selectedLocation?.currentFYTotalFootprint > benchmarks.selectedLocation.goalTotalFootprintYTD
              ? 'YTD'
              : 'OnTrack'
        },
        {
          key: 'Plant-based sales quantity share-%',
          value: [`${plantBasedQtyAvg}%`, `${plantBasedQtyGoal}%`],
          colorStyle: plantBasedQtyAvg < plantBasedQtyGoal ? 'YTD' : 'OnTrack'
        }
      ]
    : []

  const ytdSummary = benchmarks.selectedLocation ? (
    <span>
      {Math.round(benchmarks.selectedLocation.currentFYTotalFootprint)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}
      <span className="Label">tonnes CO2e YTD</span>
    </span>
  ) : undefined
  const currentFY = dataAvailability?.foodCurrentFY
  const previousFY = dataAvailability?.foodPreviousFY

  const sortedClimateIntensityBenchmarks = [...climateIntensityBenchmarks]
    .sort((a, b) =>
      a.currentMonthFootprint / a.currentMonthSoldWeight < b.currentMonthFootprint / b.currentMonthSoldWeight ? -1 : 1
    )
    .sort(a => (a.id === locationId ? -1 : 1))
    .sort((a, b) =>
      !Number.isFinite(a.currentMonthFootprint) || !Number.isFinite(a.currentMonthSoldWeight)
        ? 1
        : !Number.isFinite(b.currentMonthFootprint) || !Number.isFinite(b.currentMonthSoldWeight)
        ? -1
        : 0
    )

  const climateItensityFooterBenchmark =
    climateIntensityBenchmarks.reduce(
      (acc, curr) => ({
        id: locationId,
        label: getCountryOrClusterName(currentLocation),
        selectable: true,
        currentMonthFootprint: acc.currentMonthFootprint + curr.currentMonthFootprint,
        currentMonthSoldWeight: acc.currentMonthSoldWeight + curr.currentMonthSoldWeight,
        currentFYFootprint: acc.currentFYFootprint + curr.currentFYFootprint,
        currentFYSoldWeight: acc.currentFYSoldWeight + curr.currentFYSoldWeight,
        previousFYFootprint: acc.previousFYFootprint + curr.previousFYFootprint,
        previousFYSoldWeight: acc.previousFYSoldWeight + curr.previousFYSoldWeight,
        previousMonthFootprint: acc.previousMonthFootprint + curr.previousMonthFootprint,
        previousMonthSoldWeight: acc.previousMonthSoldWeight + curr.previousMonthSoldWeight
      }),
      emptyClimateIntensity
    ) ?? emptyClimateIntensity

  const openClimateIntensityBenchmarking = () => {
    setClimateIntensityModalOpen(true)
  }

  const fetchIntensityData = (fy: number, month: number) => {
    getClimateIntensityBenchmarks(locationId, fy, month).then(({ data }) => {
      const period = availableDates.find(item => {
        const date = new Date(item)
        return fy === getIngkaFinancialYear(date).getFullYear() && date.getMonth() + 1 === month
      })

      setClimateIntensityBenchmarks(data)
      setActualPeriod(period || '')
    })
  }

  const currentFyData = graphData?.filter(x => x.fiscalYear === currentFY)
  const ytdClimateIntensity = sumBy(currentFyData, getTotalFootprint) / sumBy(currentFyData, getTotalAmountKg)
  const lastUpdatedFormatted = lastUpdated ? format(new Date(lastUpdated), 'dd/MM/yyyy') : ''

  const { currentFYMonth, prevFYMonth }: { currentFYMonth: string; prevFYMonth: string } =
    getCurentAndPreviousFYMonth(actualPeriod)
  const climateIntensityBenchmarkingHeaders = selectClimateIntensityBenchmarkingHeaders(currentFYMonth, prevFYMonth)

  return (
    <div className="KPIPage">
      <TopBar currentPage={page} useInFlexLayout />
      <PageHeader className="ClimateFootprintHeader" route={Route.FoodIngredientsKPIPage} />
      <div className="PageContent">
        <Stripe title="Climate Footprint - Food Ingredients">
          <DataSourceAndModalButton
            dataSource="Food Dashboard"
            lastUpdated={lastUpdatedFormatted}
            updateFrequency="daily"
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        <CardRow className="BenchmarkingAndGoals">
          <Benchmarking
            benchmarks={benchmarks.comparison}
            label="tonnes CO2e"
            headers={benchmarkingHeaders}
            locationId={locationId}
            openModal={() => setBenchmarkModalOpen(true)}
            // For countries and global "sortedBenchmarks" includes the comparison (country, global), so that's why -1
            totalLocations={isSiteId(locationId) ? benchmarks.allLocations.length : benchmarks.allLocations.length - 1}
          />
          <KPIPerformance
            heading="KPI Performance"
            key="goals"
            units={[`FY${(currentFY ?? 2024) - 2000} YTD`, `FY${(currentFY ?? 2024) - 2000} Goal`]}
            kpis={kpiPerformance}
          />
        </CardRow>
        <MainCard title="Cumulative Food Emissions" subtitle={ytdSummary}>
          <div className="GraphContainer">
            <ChartContainer
              domain={dates}
              series={formatFootprintSeries(graphData, benchmarks.selectedLocation, dates, true)}
              generator={lineChart}
              dateFormat="month"
              lineChartConfiguration={{ focusStyle: 'none', startFromZero: true }}
              yAxisTitle="tonnes CO2e"
            />
          </div>
        </MainCard>
        <MainCard title="Climate Footprint of Food Ingredients Sold" subtitle={totalClimateFootprintSubtitle}>
          <TotalClimateFootprintChart data={graphData} currentFY={currentFY} previousFY={previousFY} />
        </MainCard>
        <CardRow>
          <ClimateGraphProvider locationId={locationId} countryCode={benchmarks.country?.id} loading={!graphData}>
            <ClimateIntensityChart data={graphData} />
          </ClimateGraphProvider>
          <SalesShareChart
            data={graphData}
            currentFY={currentFY}
            previousFY={previousFY}
            plantBasedAvgYTD={plantBasedQtyAvg}
          />
        </CardRow>
        <ClimateIntensityBenchmarkChart
          data={sortedClimateIntensityBenchmarks.filter(x => x.selectable)}
          benchmarkOpenFn={openClimateIntensityBenchmarking}
          ytdClimateIntensity={ytdClimateIntensity}
          actualPeriod={actualPeriod}
          availableDates={availableDates}
          fetchIntensityData={fetchIntensityData}
        />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdatedFormatted}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {rawBenchmarks && (
        <BenchmarkingModal
          benchmarks={isSiteId(locationId) ? benchmarks.allLocations : benchmarks.allLocationsWithoutSelected}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={benchmarks.country}
          headers={benchmarkingHeaders}
          isOpen={benchmarkModalOpen}
          locationId={locationId}
          sortBy="label"
          sortDirection="asc"
          title="tonnes of CO2e"
        />
      )}
      {sortedClimateIntensityBenchmarks && (
        <BenchmarkingModal<ClimateIntensityBenchmarkData>
          benchmarks={sortedClimateIntensityBenchmarks.filter(x => x.selectable)}
          closeFn={() => setClimateIntensityModalOpen(false)}
          headers={climateIntensityBenchmarkingHeaders}
          isOpen={climateIntensityModalOpen}
          locationId={locationId}
          sortBy={benchmark => benchmark.currentMonthFootprint / benchmark.currentMonthSoldWeight}
          sortDirection="desc"
          title="Benchmark of climate intensity - monthly"
          footerBenchmark={climateItensityFooterBenchmark}
        />
      )}
    </div>
  )
}

export const formatFootprintSeries = (
  data: FoodFootprint[] | undefined,
  currentLocationBenchmark: FoodIngredientsBenchmark | undefined,
  domain: Date[] | undefined,
  showGoals: boolean
): Serie[] | undefined => {
  if (data === undefined || domain === undefined) {
    return undefined
  }
  if (data.length === 0) {
    return []
  }
  const fy = +data[0].fiscalYear.toString().slice(2)
  const graphData = data?.reduce<Record<string, FoodFootprint[]>>((acc, value) => {
    if (acc[value.fiscalYear]) acc[value.fiscalYear].push(value)
    else acc[value.fiscalYear] = [value]
    return acc
  }, {})
  let series: Serie[] = []
  let goalData: {
    x: Date
    y: number
  }[] = []
  if (graphData && domain) {
    series = formatFoodSeries(graphData, domain)
  }
  if (currentLocationBenchmark && domain) {
    goalData = domain.map((d, i) => ({
      x: d,
      y: ((currentLocationBenchmark.goal / 12) * (i + 1) ?? 0) / 1000
    }))
  }

  return [
    {
      name: series[0].name,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: series[0].data.map(d => ({ x: d.x, y: d.y })).filter(isDataPoint)
    },
    ...(series[1]
      ? [
          {
            name: series[1].name,
            color: colours.darkBlue1,
            data: series[1].data.map(d => ({ x: d.x, y: d.y })).filter(isDataPoint)
          }
        ]
      : []),
    showGoals &&
      goalData && {
        name: `FY${fy + 1} Goal`,
        color: colours.lightBlue2,
        data: goalData.map(d => ({
          x: d.x,
          y: d.y
        }))
      }
  ].filter((s): s is Serie => s !== false)
}

export function selectBenchmarks(
  rawBenchmarks: FoodIngredientsBenchmark[],
  locationId: string,
  currentLocation: CurrentLocation
) {
  const sortedBenchmarks = sortBenchmarks(
    (rawBenchmarks?.filter(x => x.selectable) ?? []).slice(isSiteId(locationId) ? 1 : 0), // Parent benchmark shouldn't show up for sites
    'label',
    locationId
  )
  const countryBenchmark =
    rawBenchmarks?.find(benchmark => isCountryCode(benchmark.id)) ??
    ({
      id: locationId,
      label: getLocationLabel(currentLocation),
      selectable: true,
      currentFYTotalFootprint: 0,
      previousFYTotalFootprint: 0,
      goal: 0
    } as FoodIngredientsBenchmark)
  const currentLocationBenchmark = isSiteId(locationId)
    ? rawBenchmarks.find(b => b.id === locationId)
    : countryBenchmark
  const comparisonBenchmarks = sortedBenchmarks.slice(0, 4)

  return {
    country: countryBenchmark,
    selectedLocation: currentLocationBenchmark,
    comparison: comparisonBenchmarks,
    allLocationsWithoutSelected: sortedBenchmarks.filter(b => b.id !== locationId),
    allLocations: sortedBenchmarks
  }
}

export function selectBenchmarkingHeaders(
  dataAvailability?: DataAvailability
): HeadingItem<FoodIngredientsBenchmark>[] {
  const goalsFy = (dataAvailability?.foodCurrentFY ?? 2024) - 2000
  const currFyHeading = `FY${(dataAvailability?.foodCurrentFY ?? 2000) - 2000} YTD`
  const prevFyHeading = `FY${(dataAvailability?.foodPreviousFY ?? 2000) - 2000}`
  const prevFyTDHeading = `FY${(dataAvailability?.foodPreviousFY ?? 2000) - 2000} YTD`
  const goalHeading = `FY${goalsFy} Goal`
  const goalHeadingNext = `FY${goalsFy + 1} Goal`
  const totalGoalHeading = `FY${goalsFy} Goal YTD`
  const totalGoalHeadingNext = `FY${goalsFy + 1} Goal YTD`
  const goalHeadingPrev = `FY${goalsFy - 1} Goal`
  const totalGoalHeadingPrev = `FY${goalsFy - 1} Goal YTD`

  return [
    [
      {
        name: prevFyTDHeading,
        key: 'previousYTDTotalFootprint',
        formatValue: n => (n ? formatAbsoluteNumber(Math.round(Number(n))) : null),
        valueClassNames: 'Right'
      },
      {
        name: prevFyHeading,
        key: 'previousFYTotalFootprint',
        formatValue: n => (n ? formatAbsoluteNumber(Math.round(Number(n))) : null),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: currFyHeading,
        key: 'currentFYTotalFootprint',
        formatValue: n => (n ? formatAbsoluteNumber(Math.round(Number(n))) : null),
        valueClassNames: benchmark =>
          classNames(
            'Right',
            'YTD',
            benchmark.goalTotalFootprintYTD
              ? benchmark.currentFYTotalFootprint < benchmark.goalTotalFootprintYTD
                ? 'OnTrack'
                : 'YTD'
              : 'Black'
          )
      }
    ],
    [
      {
        name: goalHeadingPrev,
        key: 'goalPrevFY',
        formatValue: n => (n ? formatValueAsTons(Number(n)) : null),
        valueClassNames: 'Right'
      },
      {
        name: totalGoalHeadingPrev,
        key: 'goalTotalFootprintPrevYTD',
        formatValue: n => (n ? formatAbsoluteNumber(Number(n)) : null),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: totalGoalHeading,
        key: 'goalTotalFootprintYTD',
        formatValue: n => (n ? formatAbsoluteNumber(Number(n)) : null),
        valueClassNames: 'Right'
      },
      {
        name: goalHeading,
        key: 'goal',
        formatValue: n => (n ? formatValueAsTons(Number(n)) : null),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: goalHeadingNext,
        key: 'goalNextFY',
        formatValue: n => (n ? formatValueAsTons(Number(n)) : null),
        valueClassNames: 'Right'
      },
      {
        name: totalGoalHeadingNext,
        key: 'goalTotalFootprintNextYTD',
        formatValue: n => (n ? formatAbsoluteNumber(Number(n)) : null),
        valueClassNames: 'Right'
      }
    ]
  ]
}

export function selectClimateIntensityBenchmarkingHeaders(
  currentFYMonth: string,
  prevFYMonth: string
): HeadingItem<ClimateIntensityBenchmarkData>[] {
  const getChange = (benchmark: ClimateIntensityBenchmarkData) =>
    benchmark.currentMonthFootprint /
      benchmark.currentMonthSoldWeight /
      (benchmark.previousMonthFootprint / benchmark.previousMonthSoldWeight) -
    1
  return [
    [
      {
        name: currentFYMonth,
        key: 'currentMonthFootprint',
        formatValue: (n, benchmark) =>
          formatClimateIntensity(benchmark.currentMonthFootprint, benchmark.currentMonthSoldWeight),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: prevFYMonth,
        key: 'previousMonthFootprint',
        formatValue: (n, benchmark) =>
          formatClimateIntensity(benchmark.previousMonthFootprint, benchmark.previousMonthSoldWeight),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `Change`,
        key: 'currentMonthFootprint',
        formatValue: (n, benchmark) => formatChangePercentage(getChange(benchmark)),
        valueClassNames: benchmark => ['Right', `${getChange(benchmark) < 0 ? 'OnTrack' : 'NotOnTrack'}`]
      }
    ]
  ]
}
