import React, { useEffect, useState } from 'react'
import _, { Dictionary, find, maxBy, isNil } from 'lodash'
import { isBefore, isEqual, format as fmtDate } from 'date-fns'
import '../KPIPage.scss'
import colours from '../../../Colours.module.scss'

import { DataPoint, Serie } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { TopBar } from '../../../components/TopBar'
import { CustomerDeliverGraphSideBarCards } from '../../../components/GraphSideBarCards'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import { DataSourceAndModalButton, MainCard, Stripe, HeadingItem, sortBenchmarks } from '../../../components/KPIPage'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { getCustomerDeliveriesData, getGoals } from '../../../lib/APIClient'
import {
  CustomerDeliveries,
  DataAvailability,
  GoalsResponse,
  DeliveryResultsBenchmark,
  GoalsBenchmark
} from '../../../../api/src/common-types'
import { useLocations } from '../../../context'
import { useSharedSelections } from '../../../SharedSelections'
import { getMonthName } from '../../../components/Utils/dates'
import {
  getCountry,
  getGoalsForLocation,
  getLocationId,
  isCountryCode,
  isSiteId
} from '../../../components/Utils/utils'
import { formatRelativeNumber, multiFormatNumber } from '../../../components/Utils/format'
import { FinancialYearChartContainer } from '../../../components/BaseGraphs/FinancialYearChartContainer'
import { NoDataView, NoDataViewSmall } from '../../../components/BaseGraphs/NoDataView'
import { Route } from '../../../routes'
import classNames from 'classnames'
import InlineMessage from '@ingka/inline-message'
import { useGetDataAvailability } from '../../../services/general/service'

type DeliveryResultsBenchmarkWithGoal = GoalsBenchmark & DeliveryResultsBenchmark

export const useCustomerDeliveryData = (locationId: string) => {
  const empty = {
    hasData: false,
    lastModified: '',
    data: {},
    domainDeliveries: [],
    prevFyName: '',
    currFyName: '',
    prevFy: [],
    currFy: []
  }

  const [data, setData] = useState<Dictionary<CustomerDeliveries[]>>()
  const [domain, setDomain] = useState<Date[]>([])
  const [lastModified, setLastModified] = useState<string>('')
  const [{ func }] = useSharedSelections()

  useEffect(() => {
    setData(undefined)

    async function loadCustomerDeliverData() {
      const { data, lastModified } = await getCustomerDeliveriesData(locationId)

      const dataGroupedByFy = _.groupBy(data, d => `FY${d.fiscalYear}`)

      setData(dataGroupedByFy)

      const dates = Object.values(dataGroupedByFy)[0].map(d => new Date(d.readableDate))
      setDomain(dates)

      setLastModified(fmtDate(new Date(lastModified), 'yyyy/MM/dd'))
    }

    loadCustomerDeliverData()
  }, [locationId, JSON.stringify(func)])

  if (!data) return { ...empty, isLoading: true }
  if (Object.keys(data).length === 0) return { ...empty, isLoading: false }

  const [prevFyName, currFyName] = _.keys(data).sort()
  const prevFy = data[prevFyName]
  const currFy = data[currFyName]

  return {
    isLoading: false,
    hasData: currFy.some(hasDeliveryData) || prevFy.some(hasDeliveryData),
    lastModified,
    data,
    domainDeliveries: domain,
    prevFy,
    currFy,
    prevFyName,
    currFyName
  }
}

const emptyGoal = {
  countryCode: '',
  siteId: '',
  changeMakersGoal: undefined,
  changeMakersGoalNextFY: undefined,
  changeMakersGoalPrevFY: undefined,
  climateFootprintGoal: undefined,
  climateFootprintGoalNextFY: undefined,
  climateFootprintGoalPrevFY: undefined,
  deliveriesClimateFootprintGoal: undefined,
  deliveriesZeroEmissionGoal: undefined,
  deliveriesZeroEmissionGoalNextFY: undefined,
  deliveriesZeroEmissionGoalPrevFY: undefined,
  foodClimateFootprintGoal: undefined,
  perceptionGoal: undefined,
  pppShareGoal: undefined,
  pppShareGoalNextFY: undefined,
  pppShareGoalPrevFY: undefined,
  socialImpactGoal: undefined,
  socialImpactGoalNextFY: undefined,
  socialImpactGoalPrevFY: undefined,
  zeroWasteGoal: undefined,
  zeroWasteGoalNextFY: undefined,
  zeroWasteGoalPrevFY: undefined,
  energyEfficiencyGoal: undefined,
  energyEfficiencyGoalNextFY: undefined,
  energyEfficiencyGoalPrevFY: undefined,
  recyclingRateGoal: undefined,
  recyclingRateGoalNextFY: undefined,
  recyclingRateGoalPrevFY: undefined
}

export const CustomerDeliveriesKPIPage = () => {
  useDocumentTitle('Climate Footprint — Zero emission deliveries')
  const page = Route.CustomerDeliveriesKPIPage
  const [modalState, setModalState] = useState<KpiModalState>({ isOpen: false })
  const [deliveriesZeroEmissionGoal, setDeliveriesZeroEmissionGoal] = useState<number>()

  const { locations, currentLocation } = useLocations()
  const { data: dataAvailability } = useGetDataAvailability()
  const [{ func }] = useSharedSelections()

  const locationId = getLocationId(currentLocation)
  const { hasData, lastModified, data, currFy, prevFy, currFyName, prevFyName } = useCustomerDeliveryData(locationId)

  React.useEffect(() => {
    getGoals(getCountry(locationId, locations).countryCode, ['ALL'], dataAvailability?.planetCurrentFY ?? 2024).then(
      goals => {
        const goalsForLocation = { ...emptyGoal, ...getGoalsForLocation(locationId, goals) }
        setDeliveriesZeroEmissionGoal(goalsForLocation.deliveriesZeroEmissionGoal)
      }
    )
  }, [locationId, JSON.stringify(func)])

  if (locationId.length > 3)
    return (
      <div className="KPIPage">
        <TopBar currentPage={page} useInFlexLayout />
        <PageHeader className="ClimateFootprintHeader" route={Route.ClimateKPIPage}></PageHeader>
        <div className="PageContent">
          <Stripe title="Climate Footprint — Zero Emission Deliveries" />
          <NoDataView text="Climate footprint — Zero Emission Deliveries data is available only on a country-level. Please select a country or the global option to browse this data." />
        </div>
      </div>
    )

  const lastDefinedDate = new Date(_.findLast(currFy, d => d.footprint !== null)?.readableDate ?? 0)

  const totalFootprintSerie: Serie[] = [
    {
      name: prevFyName,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: prevFy.map(deliveryToDatapoint)
    },
    {
      name: currFyName,
      color: colours.darkBlue1,
      data: currFy.map(deliveryToDatapoint).filter(d => isEqual(d.x, lastDefinedDate) || isBefore(d.x, lastDefinedDate))
    }
  ].filter(Boolean) as Serie[]

  const lastAvailableDate = maxBy(currFy, record => record.footprint !== null && record.readableDate)?.readableDate

  const currentAmount =
    find(currFy, record => record.readableDate === lastAvailableDate)?.zeroEmissionDeliveryShare ?? 0

  const currentFiscalPeriod =
    (find(currFy, record => record.readableDate === lastAvailableDate)?.fiscalPeriod || 1) ?? 1

  const ytdSummary =
    currFy && hasData ? (
      <div>
        <span className="Amount">{formatRelativeNumber(currentAmount * 100)}</span>{' '}
        <span className="Unit">{`% FY${(dataAvailability?.deliveryCurrentFY || 2024) % 100} ${getMonthName(
          currentFiscalPeriod
        )}`}</span>
      </div>
    ) : undefined

  const WarningMessage =
    !currentLocation.isCluster && currentLocation.location.countryCode === 'ALL' ? (
      <div className="InlineMessageWrapper">
        <InlineMessage
          body="Note that this is based on Centiro reported data, and currently not including China and India"
          variant="cautionary"
        />
      </div>
    ) : null

  const InformationMessage = (
    <div className="InlineMessageWrapper">
      <InlineMessage
        body="We are currently improving the calculation methodology to include other fuel types than diesel and EV. FY25 data is therefore delayed but will be uploaded early T2."
        variant="cautionary"
      />
    </div>
  )

  return (
    <div className="KPIPage">
      <TopBar currentPage={page} useInFlexLayout />
      <PageHeader className="ClimateFootprintHeader" route={Route.CustomerDeliveriesKPIPage}></PageHeader>
      <div className="PageContent">
        <Stripe title="Climate Footprint – Zero Emission Deliveries">
          <DataSourceAndModalButton
            dataSource=""
            lastUpdated={lastModified}
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        {WarningMessage}
        {InformationMessage}
        {['CN', 'IN'].includes(locationId) && (
          <div className="InlineMessageWrapper">
            <InlineMessage
              title="No data available yet"
              body="Climate Footprint of Zero emission deliveries can't be estimated for the location you are looking for. We are working on making data available for FY25."
              variant="cautionary"
            />
          </div>
        )}
        <MainCard
          title="Zero Emission Delivery Share"
          subtitle={ytdSummary}
          description="This graph is based on Centiro Fuel flag data. Some countries may see a difference compared to what is show in Flight Tower, as that includes manual reported data."
        >
          <div className="GraphContainer">
            {hasData && currFy.length > 0 ? (
              <FinancialYearChartContainer
                testId="customer-deliveries-zero-emissions-chart"
                domainFromSerie={prevFyName}
                generator={lineChart}
                lineChartConfiguration={{
                  focusStyle: 'none',
                  startFromZero: false
                }}
                series={
                  !hasData
                    ? []
                    : ([
                        {
                          name: prevFyName,
                          color: colours.offWhite1,
                          fill: colours.grey1,
                          data: prevFy.map(d => ({
                            x: new Date(d.readableDate),
                            y: d.zeroEmissionDeliveryShare * 100
                          })),
                          unit: '%'
                        },
                        {
                          name: currFyName,
                          color: colours.salmon1,
                          data: currFy
                            .filter(d => d.zeroEmissionDeliveryShare !== null)
                            .map((d, idx) => ({
                              x: new Date(prevFy[idx]?.readableDate),
                              y: d.zeroEmissionDeliveryShare * 100
                            })),
                          unit: '%'
                        },
                        deliveriesZeroEmissionGoal
                          ? {
                              name: `${currFyName} Goal`,
                              color: colours.lightBlue2,
                              data: currFy.map((d, idx) => ({
                                x: new Date(prevFy[idx]?.readableDate),
                                y: deliveriesZeroEmissionGoal * 100
                              })),
                              unit: '%'
                            }
                          : false
                      ].filter(Boolean) as Serie[])
                }
                disableLegendItems
              />
            ) : (
              <NoDataViewSmall
                text={
                  locationId === 'ALL'
                    ? 'Unfortunately Zero Emissions Delivery Share is unavailable in the global view. Please select a country to see this data!'
                    : undefined
                }
              />
            )}
          </div>
        </MainCard>
        <CustomerDeliverGraphSideBarCards
          className={classNames('GraphRow', 'three-in-row')}
          graphData={data}
          locationId={locationId}
          totalFootprintSerie={totalFootprintSerie}
        />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastModified}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
    </div>
  )
}

export const deliveryToDatapoint = (
  record: CustomerDeliveries,
  idx: number,
  array: CustomerDeliveries[]
): DataPoint => ({
  x: new Date(record.readableDate),
  y: record.footprint && _.sumBy(array.slice(0, idx + 1), 'footprint') / 1000
})

const hasDeliveryData = (record: CustomerDeliveries) => record.deliveries !== null

export function selectBenchmarks(rawBenchmarks: DeliveryResultsBenchmark[], goals: GoalsResponse, locationId: string) {
  const benchmarksWithGoal: DeliveryResultsBenchmarkWithGoal[] = sortBenchmarks(
    rawBenchmarks.map(benchmark => {
      const goal = getGoalsForLocation(benchmark.id, goals) || emptyGoal
      return {
        ...benchmark,
        goal: (goal.deliveriesZeroEmissionGoal ? goal.deliveriesZeroEmissionGoal * 100 : null) as number,
        goalNextFy: (goal.deliveriesZeroEmissionGoalNextFY
          ? goal.deliveriesZeroEmissionGoalNextFY * 100
          : null) as number,
        goalPrevFy: (goal.deliveriesZeroEmissionGoalPrevFY
          ? goal.deliveriesZeroEmissionGoalPrevFY * 100
          : null) as number
      }
    }),
    'label',
    locationId
  )
  const countryBenchmark = benchmarksWithGoal?.find(benchmark => isCountryCode(benchmark.id))
  const currentLocationBenchmark = isSiteId(locationId)
    ? benchmarksWithGoal.find(b => b.id === locationId)
    : countryBenchmark

  return {
    country: countryBenchmark,
    selectedLocation: currentLocationBenchmark,
    allLocationsWithoutSelected: benchmarksWithGoal.filter(b => b.id !== locationId),
    allLocations: benchmarksWithGoal
  }
}

export function selectBenchmarkingHeaders(
  dataAvailability?: DataAvailability,
  customerDeliveriesFiscalPeriod?: number
): HeadingItem<DeliveryResultsBenchmarkWithGoal>[] {
  const currFy = Math.max(
    dataAvailability?.planetCurrentFY ?? -1,
    dataAvailability?.profitCurrentFY ?? -1,
    dataAvailability?.socialImpactCurrentFY ?? -1
  )
  const prevFy = currFy - 1
  const fiscalPeriod = customerDeliveriesFiscalPeriod || 1

  const prevFyLabelMinusOne = `FY${String(prevFy - 1).slice(2)}`
  const currFyLabelMinusOne = `FY${String(currFy - 1).slice(2)}`
  const nextFyLabelMinusOne = `FY${String(currFy).slice(2)}`

  const isFalsy = (value: number | null) => isNil(value) || isNaN(value) || !isFinite(value) || value === 0

  return [
    [
      {
        name: `${prevFyLabelMinusOne} ${getMonthName(fiscalPeriod)}`,
        key: 'previousFy',
        formatValue: n => (isFalsy(Number(n)) ? null : multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      },
      {
        name: `${prevFyLabelMinusOne} Result`,
        key: 'previousYtd',
        formatValue: n => (isFalsy(Number(n)) ? null : multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `${currFyLabelMinusOne} ${getMonthName(fiscalPeriod)}`,
        key: 'currentYtd',
        formatValue: n => (isFalsy(Number(n)) ? null : multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `${prevFyLabelMinusOne} Goal`,
        key: 'goalPrevFy',
        formatValue: n => (isFalsy(Number(n)) ? null : multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `${currFyLabelMinusOne} Goal`,
        key: 'goal',
        formatValue: n => (isFalsy(Number(n)) ? null : multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `${nextFyLabelMinusOne} Goal`,
        key: 'goalNextFy',
        formatValue: n => (isFalsy(Number(n)) ? null : multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ]
  ]
}
