import React from 'react'

import { ChartContainer } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber, formatRelativeNumber } from '../../../components/Utils/format'

import '../KPIPage.scss'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  KPIPerformance,
  MainCard,
  Stripe
} from '../../../components/KPIPage'
import { NoDataView } from '../../../components/BaseGraphs/NoDataView'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { GoalStatus } from '../../../components/SnapshotTopCards'
import { Route } from '../../../routes'
import InlineMessage from '@ingka/inline-message'
import { useGetDataAvailability } from '../../../services/general/service'
import {
  useGetBenchmarks,
  useGetEnergyEfficiencyRolling,
  useGetEnergyGoals
} from '../../../services/energyEfficiencyKpi/service'
import { selectBenchmarkingHeaders, formatEnergyEfficiencySeries } from '../../../services/energyEfficiencyKpi/mappers'
import { useLocationsFilter } from '../../../context'

export const EnergyEfficiencyKPIPage = () => {
  useDocumentTitle('Energy Efficiency')
  const page = Route.EnergyEfficiencyKPIPage

  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const { locationId } = useLocationsFilter()
  const { data: benchmarks } = useGetBenchmarks()
  const { data: dataAvailability } = useGetDataAvailability()
  const { footprint, dates, lastUpdated } = useGetEnergyEfficiencyRolling().data ?? {}
  const currentFYShort = (dataAvailability?.energyCurrentFY ?? 2024) - 2000
  const benchmarkingHeaders = selectBenchmarkingHeaders(currentFYShort)
  const { data: goalsResponse } = useGetEnergyGoals()
  const energyEfficiencyGoal = goalsResponse?.[locationId]?.goal

  const ytdSummary = benchmarks?.selectedLocation?.rolling ? (
    <span>
      {formatRelativeNumber(benchmarks.selectedLocation.rolling)} <span className="Label">kWh/m²</span>
    </span>
  ) : undefined

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.EnergyEfficiencyKPIPage} useInFlexLayout />
      <PageHeader className="ClimateFootprintHeader" route={Route.EnergyEfficiencyKPIPage} />
      <div className="PageContent">
        <Stripe title="Energy Efficiency">
          <DataSourceAndModalButton
            dataSource="Sustain"
            lastUpdated={lastUpdated}
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        <div className="InlineMessageWrapper">
          <InlineMessage
            body="The scope of energy efficiency is changed in FY25, Retail goals now include CFF and tenants while the FY24 goal scope did not. Also sites with less than 12m energy data are excluded in the performance not to skew the results. Centres data do not show due to Sustain update needed first"
            variant="cautionary"
          />
        </div>
        {benchmarks?.allLocations?.length === 0 || footprint?.length === 0 ? (
          <NoDataView />
        ) : (
          <>
            <CardRow className="BenchmarkingAndGoals">
              <Benchmarking
                benchmarks={
                  benchmarks?.comparison
                    ? [...(benchmarks.selectedLocation ? [benchmarks.selectedLocation] : []), ...benchmarks.comparison]
                    : []
                }
                label="building kWh/m²"
                headers={benchmarkingHeaders}
                locationId={locationId}
                openModal={() => setBenchmarkModalOpen(true)}
                totalLocations={benchmarks?.allLocations.length ?? 0}
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={['Rolling 12 mos']}
                kpis={
                  footprint?.length === 0
                    ? []
                    : [
                        {
                          key: 'Building kWh',
                          unit: 'building kWh',
                          value: benchmarks?.selectedLocation?.buildingkwh
                            ? formatAbsoluteNumber(benchmarks.selectedLocation?.buildingkwh)
                            : ''
                        },
                        {
                          key: 'Total m²',
                          unit: 'm²',
                          value: benchmarks?.selectedLocation?.area
                            ? formatAbsoluteNumber(benchmarks.selectedLocation?.area)
                            : ''
                        },
                        {
                          key: 'Energy Efficiency',
                          unit: 'building kWh/m²',
                          value: benchmarks?.selectedLocation?.rolling?.toFixed(1) ?? '',
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold'],
                          colorClass: energyEfficiencyGoal
                            ? (energyEfficiencyGoal ?? 0) >= (benchmarks?.selectedLocation?.rolling ?? 0)
                              ? GoalStatus.OnTrack
                              : GoalStatus.NotOnTrack
                            : undefined
                        },
                        {
                          key: `Full FY${(dataAvailability?.energyCurrentFY || 2025) - 2000} Goal`,
                          unit: 'building kWh/m²',
                          value: benchmarks?.selectedLocation?.goal?.toFixed(1) ?? '',
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold']
                        }
                      ]
                }
              />
            </CardRow>
            <MainCard title="Energy Efficiency" titleExtantion="Rolling 12 mos" subtitle={ytdSummary}>
              <div className="GraphContainer">
                <ChartContainer
                  domain={dates}
                  series={formatEnergyEfficiencySeries(footprint, dates, benchmarks?.selectedLocation?.goal)}
                  generator={lineChart}
                  dateFormat="month"
                  lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
                  yAxisTitle="kWh/m²"
                  showDecimals
                />
              </div>
            </MainCard>
          </>
        )}
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks?.allLocations?.length && (
        <BenchmarkingModal
          benchmarks={benchmarks.allLocations}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={benchmarks.country}
          headers={benchmarkingHeaders}
          isOpen={benchmarkModalOpen}
          locationId={locationId}
          sortBy="label"
          sortDirection="asc"
          title="building kWh/m²"
        />
      )}
    </div>
  )
}
