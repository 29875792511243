import React from 'react'
import { useLocation, useParams } from 'react-router'
import { ApiCluster, CountryCode } from '../../api/src/common-types'
import { getCountry, getLocation, isCluster, isCountryCode, isSiteId } from '../components/Utils/utils'
import { isCountry, isSite, LocationWithType } from '../lib/APIClient'
import { useGetUserInformation } from '../services/general/service'

export type CurrentLocation =
  | { isCluster: false; location: LocationWithType }
  | { isCluster: true; cluster: ApiCluster }

export interface Locations {
  clusters: ApiCluster[]
  locations: LocationWithType[]
}
export const LocationsContext = React.createContext<Locations | undefined>(undefined)

export const useLocations = () => {
  const context = React.useContext(LocationsContext)
  const { data: userInformation } = useGetUserInformation()
  const location = useLocation()

  if (context === undefined) {
    throw new Error('useLocations must be called within LocationsContext')
  }

  if (location.pathname === '/instoreconfigurator') {
    // a dummy location for instore configurator page which doesn't require the current location information
    const currentLocation: CurrentLocation = {
      isCluster: true,
      cluster: { clusterId: 'ALL', clusterName: 'ALL', countryCodes: [] }
    }
    return {
      ...context,
      currentLocation
    }
  }

  const { siteOrCountry } =
    location.pathname === '/'
      ? {
          siteOrCountry: context?.locations.filter(isCountry).find(l => l.countryName === userInformation?.country)
            ?.countryCode
        }
      : useParams()
  let currentLocation: CurrentLocation
  if (siteOrCountry === undefined) {
    throw new Error('Current location not found in useLocations')
  }

  if (isCluster(siteOrCountry)) {
    const cluster = context.clusters.find(c => c.clusterId === siteOrCountry)
    if (cluster === undefined) {
      throw new Error('Cluster not found in useLocations')
    }
    currentLocation = { isCluster: true, cluster }
  } else {
    const location = getLocation(siteOrCountry, context.locations)
    if (location === undefined) {
      throw new Error('Location not found in useLocations')
    }
    currentLocation = { isCluster: false, location }
  }
  return { ...context, currentLocation }
}

interface LocationFilter {
  countryCode: CountryCode | undefined
  siteId: string | undefined
  siteName: string | undefined
  isCountry: boolean
  isSite: boolean
  isGlobal: boolean
  isCluster: boolean
  locationId: string
}

export const useLocationsFilter = (): LocationFilter => {
  const context = React.useContext(LocationsContext)
  const { data: userInformation } = useGetUserInformation()

  if (context === undefined) {
    throw new Error('useLocationsFilter must be called within LocationsContext')
  }

  let { siteOrCountry } = useParams()

  if (!siteOrCountry) {
    siteOrCountry = context.locations
      .filter(isCountry)
      .find(l => l.countryName === userInformation?.country)?.countryCode
  }

  if (!siteOrCountry) {
    throw new Error('Current location not found in useLocations')
  }

  const location = getLocation(siteOrCountry, context.locations)

  if (!location) {
    throw new Error('Location not found in useLocationsFilter')
  }

  return {
    countryCode: isCountryCode(siteOrCountry)
      ? siteOrCountry
      : getCountry(siteOrCountry, context.locations).countryCode,
    siteId: isSiteId(siteOrCountry) ? siteOrCountry : undefined,
    siteName: isSite(location) ? location.siteName : undefined,
    isCountry: isCountryCode(siteOrCountry),
    isSite: isSiteId(siteOrCountry),
    isGlobal: !siteOrCountry || siteOrCountry === 'ALL',
    isCluster: isCluster(siteOrCountry),
    locationId: siteOrCountry
  }
}
