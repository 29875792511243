import React from 'react'
import { GridHr, Title } from '../../../components/Benchmark/GridHelpers'
import { BenchmarkingContext } from './Provider'
import { BenchmarkTableRow, TableRowKey } from '../../../components/Benchmark/BenchmarkTableRow'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { useSharedSelections } from '../../../SharedSelections'
import { Show } from '../../../components/Conditions/Show'
import { getLocationOrDefault } from '../../../components/Utils/utils'
import { useLocations } from '../../../context'
import * as ClimateKPIPage from '../../KPIPages/Climate/ClimateKPIPage'
import * as ZeroWasteKPIMappers from '../../KPIPages/ZeroWaste/utils/mappers'
import * as WaterEfficiencyKPIPage from '../../KPIPages/WaterEfficiency/WaterEfficiencyKPIPage'
import * as FoodIngredientsKPIPage from '../../KPIPages/FoodIngredients/FoodIngredientsKPIPage'
import * as CustomerDeliveriesKPIPage from '../../KPIPages/CustomerDeliveries/CustomerDeliveriesKPIPage'
import { useGetDataAvailability } from '../../../services/general/service'
import * as EnergyEfficiencyMappers from '../../../services/energyEfficiencyKpi/mappers'
import * as EnergyEfficiencyService from '../../../services/energyEfficiencyKpi/service'

export const ClimateTable = () => {
  const context = React.useContext(BenchmarkingContext)
  const [{ func }] = useSharedSelections()
  const isCentres = func.some(item => item === 'Common Areas' || item === 'Tenants')

  if (!context) return null

  return (
    <>
      <div className="TableHeadTitle Climate">CIRCULAR & CLIMATE AND NATURE</div>
      <div className="Table">
        <Title className="KPI">KPI / PI</Title>
        <Title className="SiteCountry">Site / country</Title>
        <GridHr isHead />
        <PlanetFootprintTableRow />
        <GridHr />
        <PlanetRecyclingTableRow />
        <GridHr />
        <EnergyEfficiencyTableRow />
        <GridHr />
        <WaterEfficiencyTableRow />
        <GridHr />

        <Show when={!isCentres}>
          <FoodIngredientsTableRow />
          <GridHr />
          <CustomerDeliveriesTableRow />
        </Show>
      </div>
    </>
  )
}

function PlanetFootprintTableRow() {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const locationId = getLocationOrDefault()
  const [{ func }] = useSharedSelections()
  const { currentLocation } = useLocations()
  const footprint = context.data.climateMonthlyFootprint
  const currentFY = footprint ? footprint[footprint.length - 1].fiscalYear : undefined
  const rawBenchmarks = context.data.climateSBTi
  const tooltip = `The ${
    locationId === 'ALL' ? 'countries' : 'stores'
  } listed below are those whose goals are closest to the selected ${locationId.length === 32 ? 'store' : 'country'}.`

  const benchmarkingHeaders = ClimateKPIPage.selectBenchmarkingHeaders(func, currentFY)
  const benchmarks = ClimateKPIPage.selectBenchmarks(
    footprint && rawBenchmarks ? rawBenchmarks : [],
    locationId,
    currentLocation
  )

  if (benchmarks.allLocations.length === 0) return <LoadingSkeleton />

  return (
    <BenchmarkTableRow
      rowKey={TableRowKey.PlanetFootprint}
      kpiUnits="tonnes CO2e"
      tooltip={tooltip}
      mainBenchmark={benchmarks.selectedLocation}
      childBenchmarks={benchmarks.allLocations}
      headings={benchmarkingHeaders}
      expandedKey={context.data.expandedKey}
      toggleExpanded={context.toggleExpanded}
    />
  )
}

function PlanetRecyclingTableRow() {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const locationId = getLocationOrDefault()
  const { currentLocation } = useLocations()
  const { data: dataAvailability } = useGetDataAvailability()

  const benchmarkingHeaders = ZeroWasteKPIMappers.selectBenchmarkingHeaders(dataAvailability)
  const benchmarks = ZeroWasteKPIMappers.selectBenchmarks(
    context.data.zeroWaste || [],
    context.data.goals,
    locationId,
    currentLocation
  )

  if (benchmarks.allLocations.length === 0) return <LoadingSkeleton />

  return (
    <BenchmarkTableRow
      rowKey={TableRowKey.PlanetRecycling}
      kpiUnits="% of waste being recycled"
      mainBenchmark={benchmarks.selectedLocation}
      childBenchmarks={benchmarks.allLocations}
      headings={benchmarkingHeaders}
      expandedKey={context.data.expandedKey}
      toggleExpanded={context.toggleExpanded}
    />
  )
}

function EnergyEfficiencyTableRow() {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const { data: dataAvailability } = useGetDataAvailability()
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const currentFYShort = currentFY - 2000

  const benchmarkingHeaders = EnergyEfficiencyMappers.selectBenchmarkingHeaders(currentFYShort)

  const { data: benchmarks, isLoading } = EnergyEfficiencyService.useGetBenchmarks()
  if (isLoading) return <LoadingSkeleton />

  return (
    <BenchmarkTableRow
      rowKey={TableRowKey.EnergyEfficiency}
      kpiUnits="building kWh/m²"
      mainBenchmark={benchmarks?.selectedLocation}
      childBenchmarks={benchmarks?.allLocations}
      headings={benchmarkingHeaders}
      expandedKey={context.data.expandedKey}
      toggleExpanded={context.toggleExpanded}
    />
  )
}

function WaterEfficiencyTableRow() {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const locationId = getLocationOrDefault()
  const [{ func }] = useSharedSelections()
  const { currentLocation } = useLocations()
  const { data: dataAvailability } = useGetDataAvailability()
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const currentFYShort = currentFY - 2000

  const benchmarkingHeaders = WaterEfficiencyKPIPage.selectBenchmarkingHeaders(func, currentFYShort)
  const benchmarks = WaterEfficiencyKPIPage.selectBenchmarks(
    context.data.waterEfficiency || [],
    context.data.goals,
    locationId,
    currentLocation
  )

  if (benchmarks.allLocations.length === 0) return <LoadingSkeleton />

  return (
    <BenchmarkTableRow
      rowKey={TableRowKey.WaterEfficiency}
      kpiUnits="litres/visitor"
      mainBenchmark={benchmarks.selectedLocation}
      childBenchmarks={benchmarks.allLocations}
      headings={benchmarkingHeaders}
      expandedKey={context.data.expandedKey}
      toggleExpanded={context.toggleExpanded}
    />
  )
}

function FoodIngredientsTableRow() {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const locationId = getLocationOrDefault()
  const { currentLocation } = useLocations()
  const { data: dataAvailability } = useGetDataAvailability()

  const benchmarkingHeaders = FoodIngredientsKPIPage.selectBenchmarkingHeaders(dataAvailability)
  const benchmarks = FoodIngredientsKPIPage.selectBenchmarks(
    context.data.foodIngredients || [],
    locationId,
    currentLocation
  )

  if (benchmarks.allLocations.length === 0) return <LoadingSkeleton />

  return (
    <BenchmarkTableRow
      rowKey={TableRowKey.FoodIngredients}
      kpiUnits="tonnes CO2e"
      mainBenchmark={benchmarks.selectedLocation}
      childBenchmarks={benchmarks.allLocationsWithoutSelected}
      headings={benchmarkingHeaders}
      expandedKey={context.data.expandedKey}
      toggleExpanded={context.toggleExpanded}
    />
  )
}

function CustomerDeliveriesTableRow() {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const locationId = getLocationOrDefault()
  const { data: dataAvailability } = useGetDataAvailability()

  const benchmarks = CustomerDeliveriesKPIPage.selectBenchmarks(
    context.data.customerDeliveries || [],
    context.data.goals || {
      countries: [],
      sites: []
    },
    locationId
  )
  const benchmarkingHeaders = CustomerDeliveriesKPIPage.selectBenchmarkingHeaders(
    dataAvailability,
    benchmarks.selectedLocation?.fiscalPeriod
  )

  if (!context.data.customerDeliveries?.length) return <LoadingSkeleton />

  return (
    <BenchmarkTableRow
      rowKey={TableRowKey.CustomerDeliveries}
      kpiUnits="%"
      mainBenchmark={benchmarks.selectedLocation}
      childBenchmarks={benchmarks.allLocationsWithoutSelected}
      headings={benchmarkingHeaders}
      expandedKey={context.data.expandedKey}
      toggleExpanded={context.toggleExpanded}
    />
  )
}
