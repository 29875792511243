import React from 'react'
import { useDataContext } from './DataProvider'
import { formatAbsoluteNumber, formatPercentage, formatRelativeNumber } from '../../../../components/Utils/format'
import { ChartContainer } from '../../../../components/BaseGraphs/ChartContainer'
import { barChart, lineChart } from '../../../../components/BaseGraphs/GraphUtil'
import { isSameMonth } from 'date-fns'
import colours from '../../../../Colours.module.scss'
import { useSharedSelections } from '../../../../SharedSelections'
import { useGetDataAvailability } from '../../../../services/general/service'

export const SmallKPIs = () => {
  const { wasteData, recoveredProducts, domain } = useDataContext()
  const { data: dataAvailability } = useGetDataAvailability()
  const [{ functionArea }] = useSharedSelections()

  const totalYTD = React.useMemo(() => {
    if (!wasteData) {
      return 0
    }
    return wasteData.reduce((result, current) => result + (current.currentTotalWaste || 0), 0)
  }, [wasteData])

  const nonRecycledCurrentYTD = React.useMemo(() => {
    if (!wasteData) {
      return 0
    }
    return wasteData.reduce((result, current) => result + (current.currentNonRecycledWaste || 0), 0)
  }, [wasteData])

  const series = React.useMemo(() => {
    if (!wasteData) return undefined
    if (wasteData.length === 0) return []

    return [
      {
        name: `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000}`,
        color: colours.yellow1,
        data: wasteData.map((item, index) => ({ x: new Date(domain[index]), y: Number(item.currentTotalWaste) })),
        secondaryName: `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000}`,
        secondaryColor: colours.lightYellow1,
        secondaryData: wasteData.map((item, index) => ({
          x: new Date(domain[index]),
          y: Number(item.previousTotalWaste)
        }))
      }
    ]
  }, [wasteData, domain, dataAvailability])

  const nonRecycledSeries = React.useMemo(() => {
    if (!wasteData) return undefined
    return [
      {
        name: `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2001}`,
        data: wasteData.map((item, index) => ({
          x: new Date(domain[index]),
          y: Number(item.previousNonRecycledWaste)
        })),
        color: colours.lightPurple
      },
      {
        name: `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000}`,
        data: wasteData.map((item, index) => ({
          x: new Date(domain[index]),
          y: Number(item.currentNonRecycledWaste)
        })),
        color: colours.purple1
      }
    ]
  }, [dataAvailability, wasteData, domain])

  const recoveredSeries = React.useMemo(() => {
    if (!recoveredProducts) return undefined

    return [
      {
        name: `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2001}`,
        data: recoveredProducts
          .filter(item => item.fiscalYear === (dataAvailability?.planetCurrentFY ?? 2000) - 2001)
          .map((item, index) => ({
            x: new Date(domain[index]),
            y: (item.productsRecovered / item.potentialRecoverable) * 100
          })),
        color: colours.offWhite1,
        fill: colours.grey1,
        unit: '%'
      },
      {
        name: `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000}`,
        data: recoveredProducts
          .filter(item => item.fiscalYear === (dataAvailability?.planetCurrentFY ?? 2000) - 2000)
          .map((item, index) => ({
            x: new Date(domain[index]),
            y: (item.productsRecovered / item.potentialRecoverable) * 100
          })),
        color: colours.purple1,
        unit: '%'
      }
    ]
  }, [recoveredProducts, dataAvailability, domain])

  const recoveredProductsYtdShare = React.useMemo(() => {
    if (!recoveredProducts) return 0

    const ytdShare = recoveredProducts
      .filter(r => r.fiscalYear === (dataAvailability?.planetCurrentFY ?? 2000) - 2000)
      .reduce(
        (acc, r) => ({
          recovered: acc.recovered + r.productsRecovered,
          potential: acc.potential + r.potentialRecoverable
        }),
        { recovered: 0, potential: 0 }
      )

    return ytdShare && ytdShare.potential > 0 ? ytdShare.recovered / ytdShare.potential : undefined
  }, [dataAvailability, recoveredProducts])

  return (
    <div className="GraphRow three-in-row SideGraphs">
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Total waste generated</h3>
          {wasteData && wasteData.length > 0 && (
            <div>
              <span className="Amount">{formatAbsoluteNumber(totalYTD)}</span>
              <span className="Unit"> tonnes YTD</span>
            </div>
          )}
        </div>
        <ChartContainer
          generator={barChart}
          series={series}
          dateFormat="month"
          domain={domain}
          yAxisTitle="tonnes"
          tooltipSummary={date => {
            const totalSerie = series ? series[0] : undefined
            if (!totalSerie) {
              return []
            }
            const curr = totalSerie.data.find(w => isSameMonth(w.x, date))
            const prev = totalSerie?.secondaryData?.find(w => isSameMonth(w.x, date))
            const diff = curr && prev && curr.y > 0 ? (curr.y - prev.y) / curr.y : NaN
            return [
              {
                icon: <span style={{ fontWeight: 700 }}>%</span>,
                title: 'Difference',
                value: formatPercentage(diff),
                onTrack: Number.isNaN(diff) ? undefined : diff < 0
              }
            ]
          }}
          isSmallGraph
        />
      </div>
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Non-recycled waste</h3>
          <span>
            {formatAbsoluteNumber(nonRecycledCurrentYTD)}
            <span className="Unit"> tonnes YTD</span>
          </span>
        </div>
        <ChartContainer
          generator={barChart}
          domain={domain}
          series={nonRecycledSeries}
          dateFormat="month"
          yAxisTitle="tonnes"
          isSmallGraph
        />
      </div>
      {functionArea !== 'centres' && (
        <div className="GraphSideBarCard">
          <div className="CardHeading">
            <div>
              <h3>Share of Recovered Products</h3>
            </div>
            {recoveredProductsYtdShare && (
              <div>
                <span className="Amount">{formatRelativeNumber(recoveredProductsYtdShare * 100)}</span>
                <span className="Unit"> % YTD</span>
              </div>
            )}
          </div>
          <ChartContainer
            generator={lineChart}
            series={recoveredSeries}
            dateFormat="month"
            domain={domain}
            lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
            yAxisTitle="%"
            yAxisLabelTooltipOnly
            isSmallGraph
          />
        </div>
      )}
    </div>
  )
}
