import React, { FunctionComponent } from 'react'
import ReactDOM from 'react-dom'
import { MsalProvider } from '@azure/msal-react'
import App from './App'
import { isLocal, msalApp } from './Authentication'

import './skapa-dependencies.scss'
import { BrowserRouter } from 'react-router-dom'
import { initialiseAnalytics } from './components/Utils/analytics'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

initialiseAnalytics()

const Authenticator: FunctionComponent = ({ children }) => {
  if (isLocal()) {
    return <>{children}</>
  } else {
    return <MsalProvider instance={msalApp}>{children}</MsalProvider>
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000 // 5 minutes
    }
  }
})

ReactDOM.render(
  <Authenticator>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </Authenticator>,
  document.getElementById('root')
)
