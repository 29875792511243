import React from 'react'
import { difference } from 'lodash'
import { getEnergyTypes } from '../lib/APIClient'
import { createColorScheme } from '../components/BaseGraphs/Universal'
import colours from '../Colours.module.scss'

const energyColors: { [key: string]: string } = {
  Electricity: colours.darkBlue1,
  'Fossil fuel onsite': colours.blue4,
  'Biofuel onsite': colours.purple2,
  'District Heating': colours.hotPink,
  'District Cooling': colours.pink1,
  Refrigerants: colours.orange2,
  Transport: colours.orange3,
  'district-heating-cooling-building': colours.pink1,
  'electricity-grid-building': colours.darkBlue,
  'fuels-diesel-building': colours.blue4,
  'fuels-diesel-own-vechicles': colours.purple2,
  'upstream-emissions-fuel-production': 'url(#small-yellow-strokes-pattern',
  'upstream-emissions-grid-loss-electricity': 'url(#small-dkblue-strokes-pattern',
  'grid-electricity-building': '#6F5762',
  'onsite-electricity-generated-and-used-building': '#8C5C40',
  'natural-gas-building': colours.yellow3
}

const energyColorScheme: (items: string[]) => { [key: string]: string } = createColorScheme([
  colours.darkBlue1,
  colours.blue4,
  colours.purple2,
  colours.hotPink,
  colours.pink1,
  colours.orange2,
  colours.orange3
])

export const ThemeContext = React.createContext(energyColors)

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = React.useState<Record<string, string>>(energyColors)

  React.useEffect(() => {
    async function refreshColorTheme() {
      const energyTypes = await getEnergyTypes()
      // check if we don't have all returned keys in the energyColors object
      if (difference(energyTypes, Object.keys(energyColors)).length > 0) {
        setTheme(prevTheme => ({ ...prevTheme, ...energyColorScheme(energyTypes) }))
      }
    }
    refreshColorTheme()
  }, [])

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}
