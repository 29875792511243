import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { getEnergyEfficiencyBenchmarking, getEnergyEfficiencyRolling } from '../../lib/APIClient'
import { useLocations, useLocationsFilter } from '../../context'
import { useSharedSelections } from '../../SharedSelections'
import { useGetDataAvailability, useGetGoals } from '../general/service'
import { getLocationId, Goal, mapGoalsResponse } from '../../components/Utils/utils'
import { mapBenchmarks } from './mappers'
import { GoalsResponse } from '../../../api/src/common-types'

const serviceName = 'energy-efficiency-kpi'

export const useGetBenchmarks = () => {
  const { locations, currentLocation } = useLocations()
  const { countryCode, locationId } = useLocationsFilter()
  const [{ func }] = useSharedSelections()
  const { data: dataAvailability } = useGetDataAvailability()
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const { data: goalsResponse } = useGetEnergyGoals()

  return useQuery({
    enabled: locations.length > 0,
    queryKey: [serviceName, 'benchmarks', countryCode, func, currentFY],
    select: rawBenchmarks => mapBenchmarks(rawBenchmarks, goalsResponse, locationId, currentLocation),
    queryFn: () => {
      if (!countryCode) {
        return []
      }

      return getEnergyEfficiencyBenchmarking(countryCode, func, currentFY)
    }
  })
}

export const useGetEnergyGoals = () => {
  const { data: dataAvailability } = useGetDataAvailability()
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024

  return useGetGoals<Record<string, Goal>>(currentFY, {
    select: (data: GoalsResponse) =>
      mapGoalsResponse(data, 'energyEfficiencyGoal', 'energyEfficiencyGoalNextFY', 'energyEfficiencyGoalPrevFY')
  })
}

export const useGetEnergyEfficiencyRolling = () => {
  const { currentLocation, locations } = useLocations()
  const locationId = getLocationId(currentLocation)
  const [{ func }] = useSharedSelections()
  const { data: dataAvailability } = useGetDataAvailability()
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024

  return useQuery({
    enabled: !!locations.length,
    queryKey: [serviceName, 'energy-efficiency-rolling', locationId, func, currentFY],
    queryFn: async () => {
      const rollingData = await getEnergyEfficiencyRolling(locationId, func, currentFY)

      return {
        footprint: rollingData?.data,
        dates: rollingData?.dates.map(d => new Date(d)),
        lastUpdated: rollingData?.lastUpdated ? format(new Date(rollingData.lastUpdated), 'dd/MM/yyyy') : ''
      }
    }
  })
}
