import React from 'react'
import SkapaModal, { Sheets, ModalHeader, ModalBody } from '@ingka/modal'
import Button from '@ingka/button'

import styles from './FeedbackModal.module.scss'
import { sendFeedback } from '../lib/APIClient'
import { useGetUserInformation } from '../services/general/service'
// import { CheckBox } from './Checkbox'

interface FeedbackModalProps {
  isOpen: boolean
  closeFn: () => void
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, closeFn }) => {
  const { data: userInformation } = useGetUserInformation()
  const [hasMessage, setHasMessage] = React.useState(false)
  // const [signup, setSignup] = React.useState(Boolean(userInformation?.signup))
  const subjectRef = React.useRef<HTMLInputElement>(null)
  const messageRef = React.useRef<HTMLTextAreaElement>(null)
  const [messageStatus, setMessageStatus] = React.useState<'notsent' | 'sending' | 'success' | 'failure'>('notsent')

  const isValid = hasMessage && (messageStatus === 'notsent' || messageStatus === 'failure')

  return (
    <SkapaModal className={styles.FeedbackModal} visible={isOpen} handleCloseBtn={closeFn}>
      <Sheets
        size="small"
        header={<ModalHeader title={`Hi ${userInformation?.givenName ?? ''}, how can we help you?`} />}
        footer={null}
      >
        <ModalBody>
          <form>
            <label htmlFor="subject">Your subject</label>
            <input ref={subjectRef} id="subject" type="text" />
            <label htmlFor="message">Your request</label>
            <textarea ref={messageRef} id="message" rows={10} onChange={e => setHasMessage(e.target.textLength > 0)} />
            {/* <div className={styles.SignupCheckBox}>
              <CheckBox id="signup" isSelected={signup} onChange={() => setSignup(!signup)} />
              <div className={styles.SignupLabel}>
                Join Sustainability Dashboard user community! You will receive by email the latest on dashboard data
                updates, improvements and new features.
              </div>
            </div> */}
            <Button
              disabled={!isValid}
              className={styles.SendFeedbackButton}
              text="Send feedback"
              type="primary"
              onClick={() => {
                setMessageStatus('sending')
                sendFeedback(
                  userInformation?.userId ?? '',
                  `${userInformation?.givenName} ${userInformation?.lastName}`,
                  userInformation?.email ?? '',
                  subjectRef.current?.value ?? '',
                  messageRef.current?.value ?? '',
                  // Down here should be signup const after we uncomment back subscribe for newsletters checbox
                  false,
                  window.location.href,
                  window.navigator.userAgent
                )
                  .then(success => setMessageStatus(success ? 'success' : 'failure'))
                  .catch(_ => setMessageStatus('failure'))
              }}
            />
          </form>
          <p className={styles.Email}>You&apos;re sending feedback as {userInformation?.email}.</p>
          {messageStatus === 'success' && <div className={styles.FeedbackSent}>Thank you for your feedback!</div>}
          {messageStatus === 'failure' && (
            <div className={styles.FeedbackSent}>
              Unfortunately sending a message failed, please try again. You might also try refreshing the page, in that
              case copy the feedback text before refreshing so you won&apos;t lose it.
            </div>
          )}
        </ModalBody>
      </Sheets>
    </SkapaModal>
  )
}
