import { format, isBefore, isEqual } from 'date-fns'
import { chain, extend, sumBy, toPairs, uniq } from 'lodash'
import React from 'react'
import {
  ChangeMakers,
  DataAvailability,
  FairAndEqualBenchmark,
  FunctionArea,
  GoalsResponse,
  SiteFunction
} from '../../../../api/src/common-types'
import { Serie } from '../../../components/BaseGraphs/ChartContainer'
import { FinancialYearChartContainer } from '../../../components/BaseGraphs/FinancialYearChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { ChangeMakersGraphSideBarCards } from '../../../components/GraphSideBarCards'

import colours from '../../../Colours.module.scss'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  KPI,
  KPIPerformance,
  HeadingItem,
  MainCard,
  sortBenchmarks,
  Stripe
} from '../../../components/KPIPage'
import { retailSiteFunctions, centreSiteFunctions } from '../../../components/LocationSearch'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber, formatRelativeNumber } from '../../../components/Utils/format'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import {
  getLocationSelector,
  getCluster,
  getLocationOrDefault,
  isCluster,
  getCountry,
  getLocationId,
  getGoalsForLocation,
  isCountryCode
} from '../../../components/Utils/utils'
import { CurrentLocation, useLocations } from '../../../context'
import { getChangeMakers, getGoals, getChangeMakersBenchmarking } from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'
import { Route } from '../../../routes'
import { isSameFairAndEqualLocation } from '../SocialImpact/SocialImpactKPIPage'
import { getCurrentPeriod, getIngkaFinancialYear } from '../../../components/Utils/dates'
import { useGetDataAvailability } from '../../../services/general/service'

export interface ChangeMakersBenchmark {
  id: string
  label: string
  locationName: string
  selectable: boolean
  previousFyResult: number
  previousFyYtd: number
  currentFyYtd: number | null
  goalPrevFY: number | null
  goal: number | null
  goalNextFY: number | null
  ratio: number | string
}

const totalChangeMakers = (d: ChangeMakers) => (d.coworkers ?? 0) + (d.customers ?? 0)

export const getBenchmark = (
  rawBenchmarks: FairAndEqualBenchmark[],
  goals: GoalsResponse | undefined,
  currFy: number,
  locationsRatio: Record<string, number>,
  latestPeriod: number,
  locationId: string
): ChangeMakersBenchmark => {
  const locationName =
    rawBenchmarks?.find(isSameFairAndEqualLocation(locationId))?.[locationId.length > 3 ? 'siteName' : 'countryName'] ??
    'Global (All Locations)'

  const benchmarksForLocation =
    (locationId === 'ALL' ? rawBenchmarks : rawBenchmarks?.filter(isSameFairAndEqualLocation(locationId))) ?? []

  const prevFy = currFy - 1

  const ratio = locationsRatio[locationId] && formatRelativeNumber(locationsRatio[locationId])

  const prevResult = benchmarksForLocation.filter(x => x.fiscalYear === prevFy - 2000)

  const prevYtd = prevResult.filter(x => x.fiscalPeriod <= latestPeriod)

  const currYtd = benchmarksForLocation.filter(x => x.fiscalYear === currFy - 2000)

  return {
    id: locationId,
    label: locationName,
    selectable: true,
    locationName,
    previousFyResult: sumBy(prevResult, 'total'),
    previousFyYtd: sumBy(prevYtd, 'total'),
    currentFyYtd: sumBy(currYtd, 'total'),
    goalPrevFY: getGoalsForLocation(locationId, goals)?.changeMakersGoalPrevFY ?? null,
    goal: getGoalsForLocation(locationId, goals)?.changeMakersGoal ?? null,
    goalNextFY: getGoalsForLocation(locationId, goals)?.changeMakersGoalNextFY ?? null,
    ratio
  }
}

const formatKpi = (data: ChangeMakers[]) => {
  const coworkers = sumBy(data, 'coworkers')
  const customers = sumBy(data, 'customers')
  return {
    Coworkers: coworkers,
    Customers: customers,
    'Total change makers engaged': coworkers + customers
  }
}

export const ChangeMakersKPIPage = () => {
  useDocumentTitle('Change-makers')
  const page = Route.ChangeMakersKPIPage

  const locationId = getLocationOrDefault()
  const [{ functionArea }] = useSharedSelections()
  const { currentLocation, clusters, locations } = useLocations()
  const { data: dataAvailability } = useGetDataAvailability()
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [goal, setGoal] = React.useState<number>()
  const [rawBenchmarks, setRawBenchmarks] = React.useState<FairAndEqualBenchmark[] | null>(null)
  const [locationsRatio, setLocationsRatio] = React.useState({})
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const [goals, setGoals] = React.useState<GoalsResponse>()
  const [actualPeriod, setActualPeriod] = React.useState<string>()

  const [lastUpdated, setLastUpdated] = React.useState('')
  const [data, setData] = React.useState<(ChangeMakers & { ytdTotal: number; date: Date })[]>([])
  const [isDataEmpty, setIsDataEmpty] = React.useState(true)

  const currFy = dataAvailability?.socialImpactCurrentFY ?? 2000
  const prevFy = dataAvailability?.socialImpactPreviousFY ?? 2000
  const currFyName = `FY${currFy - 2000}`
  const prevFyName = `FY${currFy - 2001}`

  const func: SiteFunction[] =
    functionArea === 'ingka' ? ['ALL'] : functionArea === 'retail' ? retailSiteFunctions : centreSiteFunctions

  const locationSelector = getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes)

  React.useEffect(() => {
    if (!dataAvailability) return
    setIsDataEmpty(false)
    setLastUpdated('')
    setData([])
    setGoal(undefined)
    setRawBenchmarks(null)

    async function fetchChangeMakersData() {
      const [{ lastUpdated, data, actualPeriod }, goal] = await Promise.all([
        getChangeMakers({
          ...locationSelector,
          func,
          isOld: true,
          prevFy: true
        }),
        isCluster(locationId)
          ? undefined
          : getGoals(
              getCountry(locationId, locations).countryCode,
              func,
              dataAvailability?.planetCurrentFY ?? 2025
            ).then(x => getGoalsForLocation(locationId, x)?.changeMakersGoal)
      ])

      if (data.every(d => d.coworkers === null && d.customers === null)) {
        setIsDataEmpty(true)
      }

      const dataWithDates = data.map(record => extend(record, { date: new Date(record.readableDate) }))
      const currFyData = dataWithDates.filter(
        item => getIngkaFinancialYear(new Date(item.readableDate)).getFullYear() === currFy
      )
      const prevFyData = dataWithDates.filter(
        item => getIngkaFinancialYear(new Date(item.readableDate)).getFullYear() === prevFy
      )

      const currDataWithYtd = currFyData.map(record => extend(record, { ytdTotal: getYtdSum(record.date, currFyData) }))
      const prevDataWithYtd = prevFyData.map(record => extend(record, { ytdTotal: getYtdSum(record.date, prevFyData) }))

      setActualPeriod(actualPeriod)
      setLastUpdated(format(new Date(lastUpdated), 'dd/MM/yyyy'))
      setData([...currDataWithYtd, ...prevDataWithYtd])
      setGoal(goal)

      function getYtdSum(date: Date, list: (ChangeMakers & { date: Date })[]) {
        return chain(list)
          .filter(d => isEqual(d.date, date) || isBefore(d.date, date))
          .sumBy(totalChangeMakers)
          .value()
      }
    }

    fetchChangeMakersData()

    getChangeMakersBenchmarking(
      locationSelector.countryCodes.length > 0 || currentLocation.isCluster
        ? locationSelector.countryCodes
        : [currentLocation.location.countryCode],
      func,
      dataAvailability.socialImpactCurrentFY,
      dataAvailability.socialImpactPreviousFY
    ).then(({ data, ratio }) => {
      setRawBenchmarks(data)
      setLocationsRatio(ratio)
    })

    if (!isCluster(locationId)) {
      getGoals(getCountry(locationId, locations).countryCode, func, dataAvailability?.planetCurrentFY ?? 2025).then(
        setGoals
      )
    }
  }, [locationId, JSON.stringify(dataAvailability), JSON.stringify(locationSelector), JSON.stringify(func)])

  const dataCurrFY = data.filter(item => getIngkaFinancialYear(item.date).getFullYear() === currFy)
  const dataPrevFY = data.filter(item => {
    return getIngkaFinancialYear(item.date).getFullYear() === prevFy
  })

  const formattedKpiPerformance = formatKpi(dataCurrFY)

  const calcYtd = (values: ChangeMakers[], stopIndex: number) => {
    let total = 0
    for (let i = 0; i <= stopIndex; i++) {
      total += totalChangeMakers(values[i])
    }
    return total
  }
  const series: Serie[] = [
    goal
      ? {
          id: 'goal',
          name: `${currFyName} Goal`,
          color: colours.lightBlue2,
          data: data.slice(-12).map((d, i) => ({
            x: new Date(d.date),
            y: (Number(goal) / 12) * (i + 1)
          }))
        }
      : false,
    {
      name: currFyName,
      color: colours.darkBlue1,
      data: dataCurrFY
        .filter(item => new Date(item.readableDate) <= new Date(actualPeriod || ''))
        .map((item, i, items) => ({
          x: item.date,
          y: calcYtd(items, i)
        }))
    },
    {
      name: prevFyName,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: dataPrevFY.map((d, i, items) => {
        const currData = dataCurrFY.find(item => item.date.getMonth() === d.date.getMonth())
        return { x: currData?.date, y: calcYtd(items, i) }
      })
    }
  ].filter(Boolean) as Serie[]

  const benchmarks = selectBenchmarks(
    rawBenchmarks || [],
    locationsRatio,
    actualPeriod,
    goals,
    currFy,
    locationId,
    currentLocation
  )

  const goalHeadingYTD = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000} Goal YTD`
  const benchmarkingHeaders = selectBenchmarkingHeaders(dataAvailability, functionArea)

  const currentPeriod = getCurrentPeriod(new Date(actualPeriod || ''))

  const ytdGoal = (Number(goal) / 12) * currentPeriod

  return (
    <div className="KPIPage">
      <TopBar currentPage={page} useInFlexLayout />
      <PageHeader className="SocialImpactHeader" route={Route.ChangeMakersKPIPage}></PageHeader>
      <div className="PageContent">
        <Stripe title="Change Makers">
          <DataSourceAndModalButton lastUpdated={lastUpdated} onClick={() => setModalState({ isOpen: true, page })} />
        </Stripe>
        <CardRow className="BenchmarkingAndGoals">
          <Benchmarking<ChangeMakersBenchmark>
            benchmarks={
              !rawBenchmarks
                ? []
                : [
                    ...(isCountryCode(locationId) ? [benchmarks.selectedLocation] : []),
                    ...benchmarks.allLocations
                  ].slice(0, 4)
            }
            label="people engaged"
            headers={benchmarkingHeaders}
            locationId={getLocationId(currentLocation)}
            openModal={() => setBenchmarkModalOpen(true)}
            totalLocations={benchmarks.allLocations.length}
          />
          <KPIPerformance
            key="goals"
            heading="KPI Performance"
            units={['people YTD']}
            kpis={
              data.length > 0
                ? [
                    ...toPairs(formattedKpiPerformance).map(([key, value]): KPI => {
                      const isBold = key === 'Total change makers engaged'
                      const boldClassName = ['Bold']
                      return {
                        key,
                        value: formatAbsoluteNumber(value),
                        keyClassNames: isBold ? boldClassName : [],
                        valueClassNames: isBold ? boldClassName : []
                      }
                    }),
                    ...(goal
                      ? [
                          {
                            key: goalHeadingYTD,
                            value: formatRelativeNumber(ytdGoal),
                            keyClassNames: ['Bold'],
                            valueClassNames: ['Bold']
                          }
                        ]
                      : [])
                  ]
                : []
            }
          />
        </CardRow>
        <MainCard
          title="Total number of change makers engaged"
          description="This chart shows the number of change makers engaged to support people in the local neighborhoods through all the social impact initiatives developed / supported by Ingka Group (*co-workers, customers, and citizens), in the frame of Ingka/IKEA Neighborhoods."
          subtitle={
            <>
              <span>
                {formatAbsoluteNumber(sumBy(dataCurrFY, totalChangeMakers))} <span className="Label">people YTD</span>
              </span>
              {goal ? (
                <>
                  <span className="Label Spacer">•</span>
                  <span>
                    {formatAbsoluteNumber(ytdGoal)} <span className="Label">{goalHeadingYTD}</span>
                  </span>
                </>
              ) : null}
            </>
          }
        >
          <div className="GraphContainer">
            <FinancialYearChartContainer
              generator={lineChart}
              series={series}
              domainFromSerie={prevFyName}
              noData={isDataEmpty}
              lineChartConfiguration={{ startFromZero: true, focusStyle: 'none' }}
              testId="change-makers-main-chart"
            />
          </div>
        </MainCard>
        <ChangeMakersGraphSideBarCards changeMakers={dataCurrFY} currFyName={currFyName} />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks.allLocations.length > 0 && (
        <BenchmarkingModal
          benchmarks={[benchmarks.countryReported, ...benchmarks.allLocations]}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={benchmarks.countryTotal}
          headers={benchmarkingHeaders}
          isOpen={benchmarkModalOpen}
          locationId={locationId}
          sortBy="label"
          sortDirection="asc"
          title="people engaged"
        />
      )}
    </div>
  )
}

export function selectBenchmarks(
  rawBenchmarks: FairAndEqualBenchmark[],
  locationsRatio: Record<string, number>,
  actualPeriod: string | undefined,
  goals: GoalsResponse | undefined,
  currFy: number,
  locationId: string,
  currentLocation: CurrentLocation
) {
  const benchmarkLocationIds = uniq(rawBenchmarks?.map(x => (locationId === 'ALL' ? x.countryCode : x.siteId)))
  const currentLocationBenchmark = getBenchmark(
    rawBenchmarks ?? [],
    goals,
    currFy,
    locationsRatio,
    getCurrentPeriod(new Date(actualPeriod || '')),
    locationId
  )
  const countryTotal = getBenchmark(
    rawBenchmarks ?? [],
    goals,
    currFy,
    locationsRatio,
    getCurrentPeriod(new Date(actualPeriod || '')),
    currentLocation.isCluster || locationId.length <= 3 ? locationId : currentLocation.location.countryCode
  )
  const countryReported = getBenchmark(
    (rawBenchmarks ?? []).filter(x => !x.siteId),
    goals,
    currFy,
    locationsRatio,
    getCurrentPeriod(new Date(actualPeriod || '')),
    currentLocation.isCluster || locationId.length <= 3 ? locationId : currentLocation.location.countryCode
  )
  const allLocations = sortBenchmarks(
    benchmarkLocationIds
      .map(benchmarkLocationId =>
        getBenchmark(
          rawBenchmarks ?? [],
          goals,
          currFy,
          locationsRatio,
          getCurrentPeriod(new Date(actualPeriod || '')),
          benchmarkLocationId
        )
      )
      .filter(x => x.currentFyYtd || x.previousFyYtd || x.previousFyResult),
    'label',
    locationId
  )

  return {
    countryTotal,
    countryReported,
    selectedLocation: currentLocationBenchmark,
    allLocations
  }
}

export function selectBenchmarkingHeaders(
  dataAvailability?: DataAvailability,
  functionArea?: FunctionArea
): HeadingItem<ChangeMakersBenchmark>[] {
  const currFyHeading = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000} YTD`
  const prevFyHeading = `FY${(dataAvailability?.socialImpactPreviousFY ?? 2000) - 2000} YTD`
  const prevFyResultHeading = `FY${(dataAvailability?.socialImpactPreviousFY ?? 2000) - 2000} Result`
  const goalHeading = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000} Goal`
  const goalHeadingNext = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) + 1 - 2000} Goal`
  const goalHeadingPrev = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 1 - 2000} Goal`

  const isCompleteData = dataAvailability?.socialImpactCurrentFY !== 2022
  const isFunctionAreaCentres = functionArea === 'centres'

  const benchmarkingHeadersIncompleteData: HeadingItem<ChangeMakersBenchmark>[] = [
    [
      {
        name: currFyHeading,
        key: 'currentFyYtd',
        formatValue: n => formatAbsoluteNumber(Math.round(Number(n))),
        valueClassNames: benchmark => [
          'Right',
          'YTD',
          benchmark.currentFyYtd && benchmark.goal
            ? benchmark.currentFyYtd > benchmark.goal
              ? 'OnTrack'
              : 'NotOnTrack'
            : 'Black'
        ]
      }
    ]
  ]
  const benchmarkingHeadersCompleteData: HeadingItem<ChangeMakersBenchmark>[] = [
    [
      {
        name: prevFyHeading,
        key: 'previousFyYtd',
        formatValue: n => formatAbsoluteNumber(Math.round(Number(n))),
        valueClassNames: ['Right', 'Black']
      },
      {
        name: prevFyResultHeading,
        key: 'previousFyResult',
        formatValue: n => formatAbsoluteNumber(Math.round(Number(n))),
        valueClassNames: ['Right', 'Black']
      }
    ],
    ...benchmarkingHeadersIncompleteData,
    isFunctionAreaCentres
      ? []
      : [
          {
            name: 'Visits Ratio',
            description:
              'Benchmarking ratio = number of change makers / number of visitors of the country (online & store) * 1.000.000',
            key: 'ratio',
            formatValue: n => formatAbsoluteNumber(Number(n)),
            valueClassNames: ['Right', 'YTD', 'Black']
          }
        ],
    [
      {
        name: goalHeadingPrev,
        key: 'goalPrevFY',
        formatValue: n => formatAbsoluteNumber(Number(n)),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: goalHeading,
        key: 'goal',
        formatValue: n => formatAbsoluteNumber(Number(n)),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: goalHeadingNext,
        key: 'goalNextFY',
        formatValue: n => formatAbsoluteNumber(Number(n)),
        valueClassNames: 'Right'
      }
    ]
  ]

  return isCompleteData ? benchmarkingHeadersCompleteData : benchmarkingHeadersIncompleteData
}
