import React from 'react'
import './water-effiency-content.scss'

export const WaterEfficiencyModalContent = () => (
  <>
    <h3>General description</h3>
    <p>
      In our operations, the use of water is essential. However, we need to make sure we are consuming water in a
      responsible way and not overconsuming water in areas where it is scarce.
    </p>
    <p>There are two indicators that track its consumption:</p>
    <p>- The water efficiency KPI tracks the litres of water consumed per visitor.</p>
    <p>- The water stress indicator is the share of water in high stress areas</p>
    <h3>Describe what metrics are followed in this KPI</h3>
    <p>The KPI Water efficiency follows how much water is consumed per visitor.</p>
    <p>The share of water in high stress areas.</p>
    <h3>Scope</h3>
    <p>This KPI includes Retail and Center sites, at a unit level.</p>
    <p>
      For goal setting in FY25 there are only goals set for Retail including both Customer meeting points and Customer
      fulfillment (CFF) units.
    </p>
    <p>
      No strategic goal for Ingka is set for water but the attention to this topic is expected to increase with
      increased focus on “Nature” as a strategic area.
    </p>
    <h3>Where is the data coming from? How often it is updated?</h3>
    <p>The consumption data is coming from Sustain, on a monthly basis.</p>
    <p>The visitation data is taken from Cognos for Retail.</p>
    <p>
      {' '}
      The water stress index is is using base year classification from{' '}
      <a href="https://www.wri.org/applications/aqueduct/water-risk-atlas/#/?advanced=false&basemap=hydro&indicator=bws_cat&lat=-14.434680215297268&lng=-142.73437500000003&mapMode=analysis&month=1&opacity=0.5&ponderation=DEF&predefined=false&projection=absolute&scenario=optimistic&scope=baseline&timeScale=annual&year=baseline&zoom=2">
        {' '}
        Aqueduct Water Risk Atlas (wri.org)
      </a>
    </p>

    <h3>Depending on the level of water stress, it is highlighted with the following colour scheme.</h3>
    <div className="stress-level-conteiner">
      <div className="level-low">Low</div>
      <div className="level-low-medium">Low - Medium </div>
      <div className="level-medium-high">Medium - High</div>
      <div className="level-high">High</div>
      <div className="level-extremely-high">Extremely High</div>
    </div>
    <h3>How the KPI values are calculated?</h3>
    <p>Calculation of Water efficiency </p>
    <p>
      As the CFF units do not have visitors their goal is in absolute litres of water consumed. The country goal and
      performance sum up the total water consumption and the total visitor for the country and divide to get the
      water/visitor.
    </p>
    <div className="water-formula">
      <div className="water-formula__first">
        <span>Total water consumed (liters)</span>
        <span className="water-formula__denominator">Total visitors</span>
      </div>
      <span>=</span>
      <span>Water efficiency (liter/visitor)</span>
    </div>
    <p>Calculation of &quot;share of water in high stress areas&quot; </p>
    <div className="water-formula">
      <div className="water-formula__first">
        <span>Total water consumed in sites classified as &quot;High&quot; or &quot;Extremely high&quot; </span>
        <span className="water-formula__denominator">Total water consumed </span>
      </div>
      <span>=</span>
      <span>Share of water in high stress area</span>
    </div>
    <h3>How to contribute to improving this KPI?</h3>
    <p>
      The best water is the water never used so anything reducing consumption is good. That can be actions to limit
      consumption by technical solutions as well as to monitor frequently to identify possible consumption peaks or
      leakages.{' '}
    </p>
  </>
)
