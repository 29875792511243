import React from 'react'
import {
  ClimateResultsBenchmark,
  DeliveryResultsBenchmark,
  FairAndEqualBenchmark,
  FoodIngredientsBenchmark,
  RecyclingRateBenchmark,
  WaterEfficiencyBenchmark
} from '../../../../api/src/types/benchmark'
import {
  CountryCode,
  PppSalesBenchmark,
  SalesYtd,
  YtdFootprintAndGoals,
  ClimateBenchmark
} from '../../../../api/src/common-types'
import { getPppBenchmarkWithGoals } from '../../KPIPages/PppSales/PppSalesKPIPage'
import { kpiNames, TableRowKey } from '../../../components/Benchmark/BenchmarkTableRow'
import { GoalsResponse } from '../../../../api/src/types/goals'
import { analyticsEvent } from '../../../components/Utils/analytics'
import { useLocations } from '../../../context'
import { useSharedSelections } from '../../../SharedSelections'
import {
  getChangeMakers,
  getChangeMakersBenchmarking,
  getClimateSBTi,
  getCustomerDeliveriesBenchmarking,
  getFoodIngredientsBenchmarks,
  getGoals,
  getMonthlyFootprint,
  getPeopleImpactedBenchmarks,
  getPppSalesBenchmarking,
  getRecyclingRateBenchmark,
  getWaterEfficiencyBenchmarking,
  getYtdSales
} from '../../../lib/APIClient'
import {
  getCluster,
  getCountry,
  getLocationOrDefault,
  getLocationSelector,
  isCluster,
  isSiteId
} from '../../../components/Utils/utils'
import { CurrMonth } from '../../../components/Utils/dates'
import { useGetDataAvailability } from '../../../services/general/service'

export type PreviousFyTimeRange = 'YTD' | 'Result' | CurrMonth
export type CurrentFyTimeRange = 'YTD' | 'Rolling 12 mos'

type State = {
  climateMonthlyFootprint: YtdFootprintAndGoals[] | null
  climateSBTi: ClimateBenchmark[] | null
  pppSales: PppSalesBenchmark[] | null
  isPppSalesLoading: boolean
  ytdSales: SalesYtd | null
  socialImpact: FairAndEqualBenchmark[] | null
  socialImpactRatio: Record<string, number>
  waterEfficiency: WaterEfficiencyBenchmark[] | null
  zeroWaste: RecyclingRateBenchmark[] | null
  foodIngredients: FoodIngredientsBenchmark[] | null
  customerDeliveries: DeliveryResultsBenchmark[] | null
  changeMakers: FairAndEqualBenchmark[] | null
  changeMakersActualPeriod: string | null
  changeMakersRatio: Record<string, number>
  expandedKey?: TableRowKey
  goals?: GoalsResponse
}

type BenchmarkingContextType = {
  data: State
  dispatch: React.Dispatch<Action>
  toggleExpanded: (row: TableRowKey) => void
  getPppSalesBenchmarks: () => ReturnType<typeof getPppBenchmarkWithGoals>[]
}

type Action =
  | { type: 'climateMonthlyFootprint'; payload: YtdFootprintAndGoals[] | null }
  | { type: 'climateSBTi'; payload: ClimateBenchmark[] | null }
  | { type: 'pppSales'; payload: PppSalesBenchmark[] | null }
  | { type: 'isPppSalesLoading'; payload: boolean }
  | { type: 'ytdSales'; payload: SalesYtd | null }
  | { type: 'socialImpact'; payload: FairAndEqualBenchmark[] | null }
  | { type: 'socialImpactRatio'; payload: Record<string, number> }
  | { type: 'waterEfficiency'; payload: WaterEfficiencyBenchmark[] | null }
  | { type: 'zeroWaste'; payload: RecyclingRateBenchmark[] | null }
  | { type: 'foodIngredients'; payload: FoodIngredientsBenchmark[] | null }
  | { type: 'customerDeliveries'; payload: ClimateResultsBenchmark[] | null }
  | { type: 'changeMakers'; payload: FairAndEqualBenchmark[] | null }
  | { type: 'changeMakersActualPeriod'; payload: string | null }
  | { type: 'changeMakersRatio'; payload: Record<string, number> }
  | { type: 'expandedKey'; payload?: TableRowKey }
  | { type: 'goals'; payload?: GoalsResponse }

const initialData: State = {
  climateMonthlyFootprint: null,
  climateSBTi: null,
  pppSales: null,
  isPppSalesLoading: false,
  ytdSales: null,
  socialImpact: null,
  socialImpactRatio: {},
  waterEfficiency: null,
  foodIngredients: null,
  customerDeliveries: null,
  changeMakers: null,
  changeMakersActualPeriod: null,
  changeMakersRatio: {},
  zeroWaste: []
}

export const BenchmarkingContext = React.createContext<BenchmarkingContextType | null>(null)

export const BenchmarkingProvider: React.FC<React.PropsWithChildren<unknown>> = props => {
  const [data, dispatch] = React.useReducer(benchmarkingReducer, initialData)
  const { currentLocation, clusters } = useLocations()
  const [{ func, functionArea }] = useSharedSelections()
  const { data: dataAvailability } = useGetDataAvailability()
  const locationId = getLocationOrDefault()
  const { locations } = useLocations()

  const countryCodes = currentLocation.isCluster
    ? currentLocation.cluster.countryCodes
    : [currentLocation.location.countryCode]
  const countryCode = isSiteId(locationId) ? getCountry(locationId, locations).countryCode : (locationId as CountryCode)

  const ytdSalesSelector = {
    locationId,
    site: locationId.length > 3 ? locationId : 'ALL',
    countryCodes: locationId.length === 2 ? [locationId as CountryCode] : [],
    isOld: true
  }

  React.useEffect(() => {
    dispatch({ type: 'socialImpact', payload: null })

    getMonthlyFootprint({
      ...getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes),
      func,
      isOld: false
    }).then(({ data }) => {
      dispatch({
        type: 'climateMonthlyFootprint',
        payload: data
      })
    })
    getClimateSBTi(countryCode, func).then(payload => dispatch({ type: 'climateSBTi', payload }))

    getRecyclingRateBenchmark(countryCodes[0], func).then(payload => dispatch({ type: 'zeroWaste', payload }))
    getCustomerDeliveriesBenchmarking(countryCodes[0]).then(payload =>
      dispatch({
        type: 'customerDeliveries',
        payload
      })
    )
    getWaterEfficiencyBenchmarking(countryCodes[0], func).then(payload =>
      dispatch({
        type: 'waterEfficiency',
        payload
      })
    )
    getChangeMakersBenchmarking(
      currentLocation.isCluster ? currentLocation.cluster.countryCodes : [currentLocation.location.countryCode],
      func,
      dataAvailability?.socialImpactCurrentFY ?? 0,
      dataAvailability?.socialImpactPreviousFY ?? 0
    ).then(({ data, ratio }) => {
      dispatch({ type: 'changeMakers', payload: data })
      dispatch({ type: 'changeMakersRatio', payload: ratio })
    })
    getPeopleImpactedBenchmarks(
      currentLocation.isCluster ? currentLocation.cluster.countryCodes : [currentLocation.location.countryCode],
      func,
      dataAvailability?.socialImpactCurrentFY ?? 0,
      dataAvailability?.socialImpactPreviousFY ?? 0
    ).then(({ data, ratio }) => {
      dispatch({ type: 'socialImpact', payload: data })
      dispatch({ type: 'socialImpactRatio', payload: ratio })
    })
    getFoodIngredientsBenchmarks(locationId).then(payload => dispatch({ type: 'foodIngredients', payload }))

    if (!currentLocation.isCluster && functionArea !== 'centres') {
      dispatch({ type: 'isPppSalesLoading', payload: true })
      getPppSalesBenchmarking(currentLocation.location.countryCode).then(payload => {
        dispatch({ type: 'pppSales', payload })
        dispatch({ type: 'isPppSalesLoading', payload: false })
      })
      getYtdSales(ytdSalesSelector).then(({ data }) => {
        dispatch({ type: 'ytdSales', payload: data })
      })
    }
  }, [JSON.stringify(currentLocation), JSON.stringify(func), dataAvailability, locationId])

  React.useEffect(() => {
    dispatch({ type: 'goals' })

    const locationSelector = getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes)
    getChangeMakers({
      ...locationSelector,
      func,
      isOld: true,
      prevFy: true
    }).then(data => {
      dispatch({ type: 'changeMakersActualPeriod', payload: data.actualPeriod })
    })

    !isCluster(locationId) &&
      getGoals(getCountry(locationId, locations).countryCode, func, dataAvailability?.planetCurrentFY || 2025).then(
        payload => dispatch({ type: 'goals', payload })
      )
  }, [locationId, dataAvailability?.planetCurrentFY])

  const toggleExpanded = (row: TableRowKey) => {
    const isExpand = row !== data.expandedKey

    if (isExpand) {
      analyticsEvent({
        category: 'Benchmarking',
        action: 'Expand',
        label: kpiNames[row]
      })
    }
    dispatch({ type: 'expandedKey', payload: isExpand ? row : undefined })
  }

  const getPppSalesBenchmarks = () => {
    if (!data.goals || !data.pppSales) return []

    return data.pppSales.map(benchmark => getPppBenchmarkWithGoals(benchmark, data.goals as GoalsResponse))
  }

  return (
    <BenchmarkingContext.Provider
      value={{
        data,
        dispatch,
        toggleExpanded,
        getPppSalesBenchmarks
      }}
    >
      {props.children}
    </BenchmarkingContext.Provider>
  )
}

const benchmarkingReducer = (data = initialData, action: Action) => {
  return {
    ...data,
    [action.type]: action.payload
  }
}
