import React from 'react'
import { Benchmarking } from '../../../../components/KPIPage'
import { getLocationId } from '../../../../components/Utils/utils'
import { selectBenchmarkingHeaders } from '../utils/mappers'
import { useDataContext } from './DataProvider'
import { useLocations } from '../../../../context'
import { useGetDataAvailability } from '../../../../services/general/service'

export const BenchmarkBlock = () => {
  const { benchmarks, setBenchmarkModalOpen } = useDataContext()
  const { currentLocation } = useLocations()
  const { data: dataAvailability } = useGetDataAvailability()
  const locationId = getLocationId(currentLocation)
  const benchmarkingHeaders = selectBenchmarkingHeaders(dataAvailability)

  return (
    <Benchmarking
      benchmarks={
        benchmarks.allLocations.length > 0
          ? [...(benchmarks.selectedLocation ? [benchmarks.selectedLocation] : []), ...benchmarks.comparison]
          : []
      }
      label=" % of waste being recycled"
      headers={benchmarkingHeaders}
      locationId={locationId}
      openModal={() => setBenchmarkModalOpen(true)}
      totalLocations={benchmarks.allLocationsWithoutSelected.length}
    />
  )
}
