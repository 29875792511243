import { format } from 'date-fns'
import React from 'react'
import classNames from 'classnames'

import {
  CountryCode,
  EnergyEfficiencyRolling,
  GoalsResponse,
  SiteFunction,
  WaterEfficiencyBenchmark,
  WaterEfficiencyBenchmarkWithGoals,
  WaterEfficiencySmallGraphData
} from '../../../../api/src/common-types'
import { ChartContainer } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber, formatRelativeNumber, multiFormatNumber } from '../../../components/Utils/format'
import {
  getGoals,
  getWaterEfficiency,
  getWaterEfficiencyBenchmarking,
  getWaterEfficiencyPerformance,
  getWaterEfficiencySmallGraphData
} from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'
import { useGetDataAvailability } from '../../../services/general/service'
import { WaterEfficiencyPerformanceType } from '../../../../api/src/types/performance'

import '../KPIPage.scss'
import {
  getCountry,
  getGoalsForLocation,
  getLocationId,
  getLocationLabel,
  isSiteId
} from '../../../components/Utils/utils'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  HeadingItem,
  KPIPerformance,
  MainCard,
  sortBenchmarks,
  Stripe
} from '../../../components/KPIPage'
import { NoDataView } from '../../../components/BaseGraphs/NoDataView'
import { useLocations, CurrentLocation } from '../../../context'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { GoalStatus } from '../../../components/SnapshotTopCards'
import { Route } from '../../../routes'
import { formatEnergyEfficiencySeries } from '../../../services/energyEfficiencyKpi/mappers'
import { Link } from '../../../components/Link'
import Button from '@ingka/button'
import { WaterGraphSideBarCards } from '../../../components/GraphSideBarCards'
import ZeroGraph from '../../../components/ZeroGraph'
import InlineMessage from '@ingka/inline-message'

export const WaterEfficiencyKPIPage = () => {
  useDocumentTitle('Water Efficiency')

  const [{ func }] = useSharedSelections()
  const { data: dataAvailability } = useGetDataAvailability()
  const { currentLocation, locations } = useLocations()
  const [footprint, setFootprint] = React.useState<EnergyEfficiencyRolling[]>()
  const [dates, setDates] = React.useState<Date[]>()
  const [goals, setGoals] = React.useState<GoalsResponse>()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [performanceData, setPerformanceData] = React.useState<WaterEfficiencyPerformanceType>()
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [rawBenchmarks, setRawBenchmarks] = React.useState<WaterEfficiencyBenchmark[]>()
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const locationId = getLocationId(currentLocation)
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const currentFYShort = currentFY - 2000

  const [graphData, setGraphData] = React.useState<WaterEfficiencySmallGraphData>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const page = Route.WaterEfficiencyKPIPage

  React.useEffect(() => {
    if (!locations || locations.length === 0) return

    setFootprint(undefined)
    setRawBenchmarks(undefined)
    setLastUpdated('')
    setIsLoading(true)

    const countryCode = isSiteId(locationId)
      ? getCountry(locationId, locations).countryCode
      : (locationId as CountryCode)

    getGoals(countryCode, func, currentFY).then(setGoals)
    getWaterEfficiencyBenchmarking(countryCode, func).then(setRawBenchmarks)
    getWaterEfficiency(locationId, func, currentFY).then(result => {
      setFootprint(result.data)
      setDates(result.dates.map(d => new Date(d)))
      result.lastUpdated && setLastUpdated(format(new Date(result.lastUpdated), 'dd/MM/yyyy'))
    })
    getWaterEfficiencySmallGraphData(locationId, func).then(data => {
      setGraphData(data)
      setIsLoading(false)
    })
    getWaterEfficiencyPerformance(func, countryCode, isSiteId(locationId) ? locationId : undefined).then(
      setPerformanceData
    )
  }, [JSON.stringify(locations), JSON.stringify(func), JSON.stringify(currentLocation)])

  const benchmarkingHeaders = selectBenchmarkingHeaders(func, currentFYShort)
  const benchmarks = selectBenchmarks(rawBenchmarks || [], goals, locationId, currentLocation)

  const ytdSummary = benchmarks.selectedLocation?.rolling ? (
    <span>
      {formatRelativeNumber(benchmarks.selectedLocation.currentYtd)} <span className="Label">liter/visitor YTD </span>
    </span>
  ) : (
    <span>
      0 <span className="Label">liter/visitor YTD </span>
    </span>
  )

  const exploreButton = (
    <Link page={Route.WaterExplorePage}>
      <Button text="Open Explore" type="primary" small />
    </Link>
  )

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.WaterEfficiencyKPIPage} useInFlexLayout exploreButton={exploreButton} />
      <PageHeader className="ClimateFootprintHeader" route={Route.WaterEfficiencyKPIPage} />
      <div className="PageContent">
        <Stripe title="Water Efficiency">
          <DataSourceAndModalButton
            dataSource="Sustain"
            lastUpdated={lastUpdated}
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        <div className="InlineMessageWrapper">
          <InlineMessage
            body="Visitor data is incorrect for the months of December and January. This results in an incorrect water efficiency for these months and year to date. We are currently addressing this issue."
            variant="cautionary"
          />
        </div>
        {rawBenchmarks?.length === 0 || footprint?.length === 0 ? (
          <NoDataView />
        ) : (
          <>
            <CardRow className="BenchmarkingAndGoals">
              <Benchmarking
                benchmarks={
                  benchmarks.comparison
                    ? [...(benchmarks.selectedLocation ? [benchmarks.selectedLocation] : []), ...benchmarks.comparison]
                    : []
                }
                label="litre/visitor"
                headers={benchmarkingHeaders}
                locationId={locationId}
                openModal={() => setBenchmarkModalOpen(true)}
                totalLocations={benchmarks.allLocations.length ?? 0}
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={['YTD']}
                kpis={
                  rawBenchmarks?.length === 0 && !benchmarks.selectedLocation
                    ? []
                    : [
                        {
                          key: 'Total Visitors',
                          unit: '',
                          value: performanceData?.visitorsCurrentYtd
                            ? formatAbsoluteNumber(performanceData.visitorsCurrentYtd)
                            : ''
                        },
                        {
                          key: 'Total Water',
                          unit: 'litres',
                          value: performanceData?.waterCurrentYtd
                            ? formatAbsoluteNumber(performanceData.waterCurrentYtd)
                            : ''
                        },
                        {
                          key: 'Water Efficiency',
                          unit: 'litre/visitor',
                          value: performanceData?.waterPerVisitor
                            ? formatRelativeNumber(performanceData.waterPerVisitor)
                            : '',
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold'],
                          isUnitRegular: true,
                          colorClass:
                            benchmarks.selectedLocation?.goal && performanceData?.waterPerVisitor
                              ? benchmarks.selectedLocation.goal >= performanceData.waterPerVisitor
                                ? GoalStatus.OnTrack
                                : GoalStatus.NotOnTrack
                              : ''
                        },
                        {
                          key: `FY${(dataAvailability?.waterCurrentFY || 2024) - 2000} Goal`,
                          unit: 'litre/visitor',
                          value: benchmarks.selectedLocation?.goal
                            ? formatRelativeNumber(benchmarks.selectedLocation?.goal)
                            : 'N/A',
                          isUnitRegular: true,
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold']
                        }
                      ]
                }
              />
            </CardRow>
            <MainCard
              title="Water Efficiency"
              description="Water Efficiency = total water consumption / visitors"
              subtitle={ytdSummary}
              coWorkers
            >
              {(formatEnergyEfficiencySeries(footprint, dates) ?? []).length > 0 ? (
                <div className="GraphContainer">
                  <ChartContainer
                    domain={dates}
                    series={formatEnergyEfficiencySeries(footprint, dates, benchmarks.selectedLocation?.goal)}
                    generator={lineChart}
                    dateFormat="month"
                    lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
                    showDecimals
                  />
                </div>
              ) : (
                dates && <ZeroGraph domain={dates} fy={currentFYShort} />
              )}
            </MainCard>
          </>
        )}
        <WaterGraphSideBarCards
          className={classNames('GraphRow', 'three-in-row')}
          graphData={graphData}
          isLoading={isLoading}
          isSite={isSiteId(locationId)}
        />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {rawBenchmarks && rawBenchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarks.allLocations}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={benchmarks.country}
          headers={benchmarkingHeaders}
          isOpen={benchmarkModalOpen}
          locationId={getLocationId(currentLocation)}
          sortBy="label"
          sortDirection="asc"
          title="water efficiency litre/visitor"
        />
      )}
    </div>
  )
}

function extendBenchmarkWithGoals(goals?: GoalsResponse) {
  return (benchmark: WaterEfficiencyBenchmark): WaterEfficiencyBenchmarkWithGoals => {
    return {
      ...benchmark,
      goal: getGoalsForLocation(benchmark.id, goals)?.waterEfficiencyGoal ?? 0,
      goalNextFy: getGoalsForLocation(benchmark.id, goals)?.waterEfficiencyGoalNextFY ?? 0,
      goalPrevFy: getGoalsForLocation(benchmark.id, goals)?.waterEfficiencyGoalPrevFY ?? 0
    }
  }
}

export function selectBenchmarks(
  rawBenchmarks: WaterEfficiencyBenchmark[],
  goals: GoalsResponse | undefined,
  locationId: string,
  currentLocation: CurrentLocation
) {
  const benchmarksWithGoals = sortBenchmarks(rawBenchmarks.map(extendBenchmarkWithGoals(goals)), 'label', locationId)
  const countryBenchmark: WaterEfficiencyBenchmarkWithGoals = extendBenchmarkWithGoals(goals)(
    rawBenchmarks?.find(benchmark => benchmark.id === getLocationId(currentLocation)) ?? {
      id: getLocationId(currentLocation),
      label: getLocationLabel(currentLocation),
      readableDate: new Date().toISOString().split('T')[0],
      selectable: true,
      previousYtd: 0,
      previousFy: 0,
      currentYtd: 0,
      rolling: 0
    }
  )
  const selectedLocationBenchmark = isSiteId(locationId)
    ? benchmarksWithGoals.find(b => b.id === locationId)
    : countryBenchmark
  const comparisonBenchmarks = benchmarksWithGoals
    .filter(b => b.id !== locationId)
    .slice(0, selectedLocationBenchmark ? 3 : 4)
  const isSite = isSiteId(locationId)
  const benchmarksWithoutCurrentLocation =
    benchmarksWithGoals?.filter(b => b.id !== locationId && (isSite ? b.id?.length >= 3 : true)) ?? []

  return {
    country: countryBenchmark,
    selectedLocation: selectedLocationBenchmark,
    comparison: comparisonBenchmarks,
    allLocations: benchmarksWithoutCurrentLocation
  }
}

export function selectBenchmarkingHeaders(
  func: SiteFunction[],
  currentFYShort: number
): HeadingItem<WaterEfficiencyBenchmarkWithGoals>[] {
  return [
    [
      {
        name: `FY${currentFYShort - 1} YTD`,
        key: 'previousYtd',
        formatValue: n => String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      },
      {
        name: `FY${currentFYShort - 1} Result`,
        key: 'previousFy',
        formatValue: n => String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort} YTD`,
        key: 'currentYtd',
        formatValue: n => String(multiFormatNumber(Number(n))),
        valueClassNames: benchmark =>
          classNames(
            'Right',
            'YTD',
            !benchmark.goal || !benchmark.currentYtd
              ? 'Black'
              : !benchmark.goal || benchmark.currentYtd < benchmark.goal
              ? 'OnTrack'
              : 'YTD'
          )
      },
      {
        name: 'Rolling 12 mos',
        key: 'rolling',
        formatValue: n => String(multiFormatNumber(Number(n))),
        valueClassNames: ['Right', 'Black']
      }
    ],
    [
      {
        name: `FY${currentFYShort - 1} Goal`,
        key: 'goalPrevFy',
        formatValue: (n, benchmark) =>
          !isSiteId(benchmark.id) && func.includes('ALL') ? null : String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort} Goal`,
        key: 'goal',
        formatValue: (n, benchmark) =>
          !isSiteId(benchmark.id) && func.includes('ALL') ? null : String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort + 1} Goal`,
        key: 'goalNextFy',
        formatValue: (n, benchmark) =>
          !isSiteId(benchmark.id) && func.includes('ALL') ? null : String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ]
  ]
}
