import React from 'react'
import { WasteRecyclingRateGraph } from '../../../../components/ZeroWasteGraph'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import { MainCard } from '../../../../components/KPIPage'
import { useDataContext } from './DataProvider'
import { useLocations } from '../../../../context'
import { getIngkaFinancialYear } from '../../../../components/Utils/dates'
import { formatRelativeNumber } from '../../../../components/Utils/format'
import { getGoalsForLocation, getLocationId } from '../../../../components/Utils/utils'
import { mapRecyclingRateChartData } from '../utils/mappers'
import { useGetDataAvailability } from '../../../../services/general/service'

export const MainChart = () => {
  const { wasteData, domain, goals, performance } = useDataContext()
  const { data: dataAvailability } = useGetDataAvailability()
  const { currentLocation } = useLocations()
  const currentFY = dataAvailability?.recyclingCurrentFY ?? getIngkaFinancialYear(new Date()).getFullYear()
  const locationGoals = getGoalsForLocation(getLocationId(currentLocation), goals)

  const { series } = mapRecyclingRateChartData(wasteData, domain, locationGoals, dataAvailability, currentFY)

  const subTitle = React.useMemo(() => {
    return (
      <div>
        <span className="Amount">
          {performance?.recycling_rate ? formatRelativeNumber(performance.recycling_rate) : ''}
        </span>
        <span className="Label"> % YTD</span>
      </div>
    )
  }, [performance])

  return (
    <MainCard title="Recycling Rate" subtitle={subTitle}>
      <div className="GraphContainer">
        {wasteData ? <WasteRecyclingRateGraph dates={domain} data={series} dateFormat="month" /> : <LoadingSkeleton />}
      </div>
    </MainCard>
  )
}
