import { EnergyEfficiencyBenchmark, EnergyEfficiencyRolling } from '../../../api/src/common-types'
import { HeadingItem, sortBenchmarks } from '../../components/KPIPage'
import { getLocationId, getLocationLabel, Goal, isSiteId } from '../../components/Utils/utils'
import { CurrentLocation } from '../../context/LocationsContext'
import colours from '../../Colours.module.scss'
import { isDataPoint, Serie } from '../../components/BaseGraphs/ChartContainer'
import { formatRelativeNumber } from '../../components/Utils/format'
import classNames from 'classnames'
import { isSameMonth } from 'date-fns'

export function mapBenchmarks(
  rawBenchmarks: EnergyEfficiencyBenchmark[],
  goalsMapped: Record<string, Goal> | undefined,
  locationId: string,
  currentLocation: CurrentLocation
) {
  const rawBenchmarksWithGoals = sortBenchmarks(
    rawBenchmarks.map(benchmark => ({
      ...benchmark,
      goal: goalsMapped?.[benchmark.id]?.goal as number,
      goalPrevFY: goalsMapped?.[benchmark.id]?.goalPrevFY as number,
      goalNextFY: goalsMapped?.[benchmark.id]?.goalNextFY as number
    })),
    'label',
    locationId
  )
  const countryBenchmark: EnergyEfficiencyBenchmark = rawBenchmarksWithGoals?.find(
    benchmark => benchmark.id === getLocationId(currentLocation)
  ) ?? {
    id: getLocationId(currentLocation),
    label: getLocationLabel(currentLocation),
    area: 0,
    goalPrevFY: 0,
    goal: 0,
    goalNextFY: 0,
    selectable: true,
    previousFy: 0,
    previousYtd: 0,
    currentYtd: 0,
    buildingkwh: 0,
    rolling: 0
  }
  const selectedLocationBenchmark = isSiteId(locationId)
    ? rawBenchmarksWithGoals.find(b => b.id === locationId)
    : countryBenchmark
  const comparisonBenchmarks = rawBenchmarksWithGoals
    .filter(b => b.id !== locationId)
    .slice(0, selectedLocationBenchmark ? 3 : 4)
  const isSite = isSiteId(locationId)
  const benchmarksWithoutCurrentLocation =
    rawBenchmarksWithGoals?.filter(b => b.id !== locationId && (isSite ? b.id.length >= 3 : true)) ?? []

  return {
    country: countryBenchmark,
    selectedLocation: selectedLocationBenchmark,
    comparison: comparisonBenchmarks,
    allLocations: benchmarksWithoutCurrentLocation
  }
}

export const formatEnergyEfficiencySeries = (
  data: EnergyEfficiencyRolling[] | undefined,
  domain: Date[] | undefined,
  goal?: number
): Serie[] | undefined => {
  if (data === undefined || domain === undefined) {
    return undefined
  }
  const noData = data.every(d => !d.currentFY && !d.previousFY)
  if (noData) {
    return []
  }
  const fy = data[0].fiscalYear

  const dataWithDates = domain.map(date => {
    const d = data.find(d => isSameMonth(new Date(d.readableDate), date))
    return {
      ...d,
      date,
      curr: d?.currentFY,
      prev: d?.previousFY
    }
  })

  return [
    {
      name: `FY${fy - 1}`,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.prev })).filter(isDataPoint)
    },
    {
      name: `FY${fy}`,
      color: colours.darkBlue1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.curr })).filter(isDataPoint)
    },
    !!goal && {
      name: `FY${fy} Goal`,
      color: colours.lightBlue2,
      data: data.map(d => ({ x: new Date(d.readableDate), y: goal }))
    }
  ].filter((s): s is Serie => s !== false)
}

export function selectBenchmarkingHeaders(currentFYShort: number): HeadingItem<EnergyEfficiencyBenchmark>[] {
  return [
    [
      {
        name: 'Rolling 12 mos',
        key: 'rolling',
        formatValue: n => (isNaN(Number(n)) || !n ? null : formatRelativeNumber(Number(n))),
        valueClassNames: benchmark =>
          classNames(
            'Right',
            { YTD: benchmark.goal },
            !benchmark.goal ? 'Black' : Number(benchmark.rolling) < benchmark.goal ? 'OnTrack' : 'YTD'
          )
      }
    ],
    [
      {
        name: `FY${currentFYShort - 1} Goal`,
        key: 'goalPrevFY',
        formatValue: n => (isNaN(Number(n)) || !n ? null : formatRelativeNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort} Goal`,
        key: 'goal',
        formatValue: n => (isNaN(Number(n)) || !n ? null : formatRelativeNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort + 1} Goal`,
        key: 'goalNextFY',
        formatValue: n => (isNaN(Number(n)) || !n ? null : formatRelativeNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ]
  ]
}
