import React from 'react'
import { getLocationId, getLocationLabel, isSiteId } from '../../../../components/Utils/utils'
import { CustomerTravelContext } from '../CustomerTravelProvider'
import { CustomerTravelBenchmark } from '../../../../../api/src/types/benchmark'
import { useLocations } from '../../../../context'
import { CustomerTravelFootprint } from '../../../../../api/src/types/climate'
import { isDataPoint, Serie } from '../../../../components/BaseGraphs/ChartContainer'
import { isSameMonth } from 'date-fns'
import colours from '../../../../Colours.module.scss'
import { isNil } from 'lodash'

export const useCustomerTravelBenchmarkData = () => {
  const { benchmarks, locationId } = React.useContext(CustomerTravelContext)
  const { currentLocation } = useLocations()

  const countryBenchmark: CustomerTravelBenchmark = React.useMemo(() => {
    return (
      benchmarks?.find(benchmark => benchmark.id === getLocationId(currentLocation)) ?? {
        id: getLocationId(currentLocation),
        label: getLocationLabel(currentLocation),
        selectable: true,
        previousFy: 0,
        previousYtd: 0,
        currentYtd: 0,
        rolling: 0,
        total_footprint: 0,
        distance_person: 0
      }
    )
  }, [JSON.stringify(currentLocation), JSON.stringify(benchmarks)])

  const [selectedLocationBenchmark, comparisonBenchmarks] = React.useMemo(() => {
    if (benchmarks === undefined) {
      return [undefined, []]
    }
    const selectedLocationBenchmark = isSiteId(locationId)
      ? benchmarks.find(b => b.id === locationId)
      : countryBenchmark

    return [
      selectedLocationBenchmark,
      benchmarks.filter(b => b.id !== locationId).slice(0, selectedLocationBenchmark ? 3 : 4)
    ]
  }, [JSON.stringify(benchmarks)])

  return {
    selectedLocationBenchmark,
    comparisonBenchmarks,
    countryBenchmark
  }
}

export const formatCustomerTravelSeries = (
  data: CustomerTravelFootprint[] | undefined,
  domain: Date[] | undefined
): Serie[] | undefined => {
  if (data === undefined || domain === undefined) {
    return undefined
  }
  const noData = data.every(d => !d.currentFY && !d.previousFY)
  if (noData) {
    return []
  }
  const fy = data[0].fiscalYear

  const dataWithDates = domain.map(date => {
    const d = data.find(d => isSameMonth(new Date(d.readableDate), date))
    return {
      ...d,
      date,
      curr: d?.currentFY,
      prev: d?.previousFY
    }
  })

  return [
    {
      name: `FY${fy - 1}`,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.prev })).filter(isDataPoint)
    },
    {
      name: `FY${fy}`,
      color: colours.darkBlue1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.curr })).filter(isDataPoint)
    }
  ]
}

export const formatFootprintYtdSeries = (data: CustomerTravelFootprint[] | undefined, domain: Date[] | undefined) => {
  if (data === undefined || domain === undefined) {
    return undefined
  }
  const noData = data.every(d => !d.currentFY && !d.previousFY)
  if (noData) {
    return []
  }
  const fy = data[0].fiscalYear

  const dataWithDates = domain.reduce((result, date) => {
    const d = data.find(d => isSameMonth(new Date(d.readableDate), date))
    const lastItem = result[result.length - 1] || { curr: 0, prev: 0 }

    if (!d) return result

    const noCurrData =
      isNil(d?.curr_footprint_public) &&
      isNil(d?.curr_footprint_car) &&
      isNil(d?.curr_footprint_moto) &&
      isNil(d?.curr_footprint_foot)

    return [
      ...result,
      {
        date,
        curr: noCurrData
          ? null
          : Number(lastItem.curr) +
            Math.round(
              d?.curr_footprint_public + d?.curr_footprint_car + d?.curr_footprint_moto + d?.curr_footprint_foot
            ),
        prev:
          lastItem.prev +
          Math.round(d?.prev_footprint_public + d?.prev_footprint_car + d?.prev_footprint_moto + d?.prev_footprint_foot)
      }
    ]
  }, [] as { date: Date; curr: number | null; prev: number }[])

  const dataMoreThenTen = dataWithDates.some(item => {
    return item.prev / 1000 > 10 || Number(item.curr) / 1000 > 10
  })

  const formatData = (value: number) => {
    return dataMoreThenTen ? Math.round(value / 1000) : Math.round(value / 10) / 100
  }

  const formattedData = dataWithDates.map(item => {
    return {
      ...item,
      prev: formatData(item.prev),
      curr: item.curr ? formatData(item.curr) : item.curr
    }
  })

  return [
    {
      name: `FY${fy - 1}`,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: formattedData.map(d => ({ x: d.date, y: d.prev })).filter(isDataPoint)
    },
    {
      name: `FY${fy}`,
      color: colours.darkBlue1,
      data: formattedData.map(d => ({ x: d.date, y: d.curr })).filter(isDataPoint)
    }
  ]
}
