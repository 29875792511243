import React from 'react'
import { GridHr, Title } from '../../../components/Benchmark/GridHelpers'
import { BenchmarkingContext } from './Provider'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { BenchmarkTableRow, TableRowKey } from '../../../components/Benchmark/BenchmarkTableRow'
import { selectBenchmarks, selectBenchmarkingHeaders } from '../../KPIPages/PppSales/PppSalesKPIPage'
import { useLocations } from '../../../context'
import { getLocationOrDefault, getCurrencyCode, getCountry, isCluster } from '../../../components/Utils/utils'
import { useSharedSelections } from '../../../SharedSelections'
import { useGetDataAvailability } from '../../../services/general/service'

export const HealthyLivingTable = () => {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const locationId = getLocationOrDefault()
  const [{ scope }] = useSharedSelections()
  const { currentLocation, locations } = useLocations()
  const currencyCode = getCurrencyCode(currentLocation)
  const country = !isCluster(locationId) && locations.length > 0 ? getCountry(locationId, locations) : undefined
  const { data: dataAvailability } = useGetDataAvailability()
  const isSales = !scope.includes('quantity')
  const rawBenchmarks = context.getPppSalesBenchmarks()

  const benchmarkingHeaders = selectBenchmarkingHeaders(
    locationId,
    dataAvailability,
    currencyCode,
    isSales,
    context.data.ytdSales || undefined
  )
  const benchmarks = selectBenchmarks(
    rawBenchmarks,
    context.data.goals,
    country,
    locationId,
    context.data.ytdSales || undefined
  )

  return (
    <>
      <div className="TableHeadTitle Health">Healthy & Sustainable Living</div>
      <div className="Table">
        <Title className="KPI">KPI / PI</Title>
        <Title className="SiteCountry">Site / country</Title>
        <GridHr isHead />
        {context.data.isPppSalesLoading ? (
          <LoadingSkeleton />
        ) : (
          <BenchmarkTableRow
            rowKey={TableRowKey.PppSales}
            kpiUnits="share %"
            mainBenchmark={benchmarks.selectedLocation}
            childBenchmarks={benchmarks.allLocations}
            headings={benchmarkingHeaders}
            expandedKey={context.data.expandedKey}
            toggleExpanded={context.toggleExpanded}
          />
        )}
      </div>
    </>
  )
}
