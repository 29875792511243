import classNames from 'classnames'
import { DataAvailability, WasteFootprint } from '../../../../../api/src/common-types'
import { RecyclingRateBenchmark } from '../../../../../api/src/types/benchmark'
import { CountryGoals, GoalsResponse, SiteGoals } from '../../../../../api/src/types/goals'
import { Serie } from '../../../../components/BaseGraphs/ChartContainer'
import { HeadingItem, sortBenchmarks } from '../../../../components/KPIPage'
import { formatRelativeNumber } from '../../../../components/Utils/format'
import { getGoalsForLocation, getLocationLabel, isSiteId } from '../../../../components/Utils/utils'
import { CurrentLocation } from '../../../../context'
import colours from '../../../../Colours.module.scss'
import { groupBy } from 'lodash'
import { getIngkaFinancialYear } from '../../../../components/Utils/dates'

const mergeBenchmarkWithGoal = (
  benchmarks?: RecyclingRateBenchmark[] | undefined,
  goals?: GoalsResponse
): RecyclingRateBenchmark[] => {
  if (!goals) {
    return benchmarks || []
  }

  return (benchmarks || []).map(benchmark => {
    return {
      ...benchmark,
      goal: getGoalsForLocation(benchmark.id, goals)?.recyclingRateGoal ?? 0,
      goalNextFY: getGoalsForLocation(benchmark.id, goals)?.recyclingRateGoalNextFY ?? 0,
      goalPrevFY: getGoalsForLocation(benchmark.id, goals)?.recyclingRateGoalPrevFY ?? 0
    }
  })
}

export function selectBenchmarks(
  rawBenchmarks: RecyclingRateBenchmark[],
  goals: GoalsResponse | undefined,
  locationId: string,
  currentLocation: CurrentLocation
) {
  const benchmarksWithGoals = sortBenchmarks(mergeBenchmarkWithGoal(rawBenchmarks, goals), 'label', locationId)

  const selectedLocationBenchmark = benchmarksWithGoals.find(b => b.id === locationId)
  const countryBenchmark = {
    id: locationId,
    label: getLocationLabel(currentLocation),
    selectable: true,
    previousYtd: selectedLocationBenchmark?.previousYtd ?? 0,
    currentYtd: selectedLocationBenchmark?.currentYtd ?? 0,
    previousFy: selectedLocationBenchmark?.previousFy ?? 0,
    rolling: 0,
    goal: getGoalsForLocation(locationId, goals)?.recyclingRateGoal ?? 0,
    goalNextFY: getGoalsForLocation(locationId, goals)?.recyclingRateGoalNextFY ?? 0,
    goalPrevFY: getGoalsForLocation(locationId, goals)?.recyclingRateGoalPrevFY ?? 0
  }

  const comparisonBenchmarks = benchmarksWithGoals
    .filter(b => b.id !== locationId)
    .slice(0, selectedLocationBenchmark ? 3 : 4)

  const isSite = isSiteId(locationId)
  const allLocationsWithoutSelected =
    benchmarksWithGoals.filter(b => b.id !== locationId && (isSite ? b.id.length >= 3 : true)) ?? []

  return {
    country: countryBenchmark,
    selectedLocation: selectedLocationBenchmark,
    comparison: comparisonBenchmarks,
    allLocationsWithoutSelected,
    allLocations: benchmarksWithGoals
  }
}

export function selectBenchmarkingHeaders(dataAvailability?: DataAvailability): HeadingItem<RecyclingRateBenchmark>[] {
  const goalsFy = dataAvailability?.planetCurrentFY ?? 2024
  const currFyHeading = `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000} YTD`
  const prevFyHeading = `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000} YTD`
  const prevFyResultHeading = `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000} Result`
  const goalHeading = `FY${goalsFy - 2000} Goal`
  const goalHeadingNext = `FY${goalsFy + 1 - 2000} Goal`
  const goalHeadingPrev = `FY${goalsFy - 1 - 2000} Goal`

  return [
    [
      {
        name: prevFyHeading,
        key: 'previousYtd',
        formatValue: n => formatRelativeNumber(Number(n)),
        valueClassNames: ['Right', 'Black']
      },
      {
        name: prevFyResultHeading,
        key: 'previousFy',
        formatValue: n => formatRelativeNumber(Number(n)),
        valueClassNames: ['Right', 'Black']
      }
    ],
    [
      {
        name: currFyHeading,
        key: 'currentYtd',
        formatValue: n => formatRelativeNumber(Number(n) ?? 0),
        valueClassNames: benchmark =>
          classNames(
            'Right',
            'YTD',
            benchmark.goal && benchmark.currentYtd
              ? benchmark.currentYtd >= benchmark.goal
                ? 'OnTrack'
                : 'YTD'
              : 'Black'
          )
      }
    ],
    [
      {
        name: goalHeadingPrev,
        key: 'goalPrevFY',
        formatValue: n => formatRelativeNumber(Number(n)),
        valueClassNames: 'Right'
      }
    ],
    [{ name: goalHeading, key: 'goal', formatValue: n => formatRelativeNumber(Number(n)), valueClassNames: 'Right' }],
    [
      {
        name: goalHeadingNext,
        key: 'goalNextFY',
        formatValue: n => formatRelativeNumber(Number(n)),
        valueClassNames: 'Right'
      }
    ]
  ]
}

export function mapRecyclingRateChartData(
  wasteData: WasteFootprint[] | undefined,
  domain: Date[],
  locationGoals: SiteGoals | CountryGoals | undefined,
  dataAvailability: DataAvailability | undefined,
  currentFY: number
) {
  const response: { series: Serie[]; domain: Date[] } = { series: [], domain }

  if (wasteData && domain.length > 0) {
    const currentFyGoalDates = groupBy(domain, d => getIngkaFinancialYear(d).getFullYear())[currentFY] || []

    response.series = [
      {
        name: `FY${currentFY - 2000}`,
        data: wasteData.map((item, index) => {
          const date = domain[index]
          return { x: new Date(date), y: item.currentRecyclingRate }
        }),
        color: colours.purple1,
        unit: '%'
      },
      {
        name: `FY${currentFY - 2001}`,
        data: wasteData.map((item, index) => {
          const date = domain[index]
          return { x: new Date(date), y: item.previousRecyclingRate }
        }),
        color: colours.offWhite1,
        fill: colours.grey1,
        unit: '%'
      },
      {
        color: colours.lightBlue2,
        data: currentFyGoalDates.map(date => ({
          x: date,
          y: locationGoals ? locationGoals?.recyclingRateGoal : null
        })),
        name: `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000} Goal`,
        zIndex: 0,
        unit: '%'
      }
    ] as Serie[]
  }

  return response
}
