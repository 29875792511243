import React from 'react'
import classnames from 'classnames'
import { stringify } from 'qs'
import Accordion, { AccordionItem } from '@ingka/accordion'

import { getGoals, isCountry, LocationWithType, SiteWithType } from '../lib/APIClient'
import { FeedbackModal } from '../components/FeedbackModal'
// import { InstructionVideos } from '../components/InstructionVideos'
import { LeaderboardTopPanel } from './Leaderboard/LeaderboardPage'
import { FunctionAreaSelect, LocationSearch } from '../components/LocationSearch'
// import { MsStreamVideo } from '../components/MsStreamVideo'
import { PlanetTopCard, SocialImpactTopCard, ZeroWasteTopCard } from '../components/SnapshotTopCards'
import {
  getLocation,
  getLocationId,
  getLocationLabel,
  getLocationOrDefault,
  getGoalsForLocation,
  isCountryCode,
  isSiteId,
  toSiteFunction,
  isCluster,
  getCountry
} from '../components/Utils/utils'

import { ReactComponent as ArrowLink } from '../images/CircleArrow/Arrow.svg'
import { ReactComponent as DownArrow } from '../images/CircleArrow/White.svg'
import { ReactComponent as FourPLogo } from '../images/Logo.svg'
import { ReactComponent as IkeaLogo } from '../images/ikealogoblack.svg'

import socialImpactHeader from '../images/HowIsThisCalculated/SocialImpactHeader.jpg'
import zeroWasteHeader from '../images/HowIsThisCalculated/ZeroWasteHeader.png'

import './PersonalizedLandingPage.scss'
import { Link } from '../components/Link'
import { CountryGoals, FunctionArea, SiteFunction, SiteGoals } from '../../api/src/common-types'
import { useLocations, useFeedbackContext } from '../context'
import { useSharedSelections } from '../SharedSelections'
import { Route } from '../routes'
import { analyticsEvent } from '../components/Utils/analytics'
import { useGetDataAvailability, useGetUserInformation } from '../services/general/service'

interface FooterProps {
  onFeedback: () => void
}

interface InTheSpotlightSectionProps extends WithClassName {
  tagline: string
}

interface SectionProps extends WithClassName {
  id?: string
}

interface StickyLocationSelectorProps {
  isOnTop: boolean
  locations: LocationWithType[]
  userCountryCode?: string
  userFunctionArea?: FunctionArea
}

interface WithClassName {
  className?: string
}

const Footer: React.FC<FooterProps> = ({ onFeedback }) => (
  <div className="Footer">
    <IkeaLogo />
    <div className="Feedback">
      Questions?{' '}
      <span className="Link" onClick={onFeedback}>
        Get in touch
      </span>
    </div>
  </div>
)

const Section: React.FC<SectionProps> = ({ children, className, id }) => (
  <div className={classnames('Section', className)} id={id}>
    {children}
  </div>
)

const InTheSpotlightSection: React.FC<InTheSpotlightSectionProps> = ({ children, className, tagline }) => (
  <Section className={classnames('InTheSpotlight', className)} id="in-the-spotlight">
    <h3 className="Subtitle">In the Spotlight</h3>
    <p>{tagline}</p>
    {children}
  </Section>
)

const StickyLocationSelector: React.FC<StickyLocationSelectorProps> = ({ isOnTop, locations, userFunctionArea }) => {
  const [changedFunctionArea, setChangedFunctionArea] = React.useState(false)
  const [{ functionArea }, updateSharedSelections] = useSharedSelections()
  const { currentLocation } = useLocations()

  const goToSnapshot = () => {
    const locationId = getLocationId(currentLocation)
    const func: SiteFunction[] = isSiteId(locationId)
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        toSiteFunction((getLocation(locationId, locations)! as SiteWithType).functionCode)
      : functionArea === 'ingka'
      ? ['ALL']
      : functionArea === 'retail'
      ? ['Retail', 'Customer Fulfillment', 'Support units']
      : ['Common Areas', 'Tenants']

    const queryString = stringify(
      {
        functionArea: functionArea,
        func
      },
      { addQueryPrefix: true }
    )
    window.location.assign(`/${locationId}/snapshot${queryString}`)
  }

  const onLocationSelected = (siteOrCountry: string) => {
    updateSharedSelections({ pathname: `/${siteOrCountry}/landing` })
  }

  const onFunctionAreaSelected = (functionArea: FunctionArea) => {
    updateSharedSelections({ functionArea })

    if (!changedFunctionArea) {
      analyticsEvent({
        category: 'FunctionArea',
        action: `changeDefault-${userFunctionArea}`,
        label: functionArea
      })
      setChangedFunctionArea(true)
    }
  }

  return (
    <div className={classnames('StickyLocationSelector', { isFixed: !isOnTop })}>
      <h2>
        <FourPLogo />
        <span>4P Dashboard</span>
      </h2>
      <p>Search for a site or country and enter the 4P Dashboard</p>
      <div className="SelectorContainer">
        <FunctionAreaSelect functionArea={functionArea} onFunctionAreaSelected={onFunctionAreaSelected} />
        <LocationSearch functionArea={functionArea} onLocationSelected={onLocationSelected} />
        <button onClick={goToSnapshot}>Enter</button>
      </div>
    </div>
  )
}

export const PersonalizedLandingPage: React.FC<unknown> = () => {
  const { currentLocation, locations } = useLocations()
  const { data: userInformation } = useGetUserInformation()
  const { isFeedbackOpen, openFeedbackModal, closeFeedbackModal } = useFeedbackContext()
  const [isOnTop, setIsOnTop] = React.useState(true)
  const [prevFYGoals, setPrevFyGoals] = React.useState<SiteGoals | CountryGoals>()
  const [currFYGoals, setCurrFyGoals] = React.useState<SiteGoals | CountryGoals>()
  const [{ func }] = useSharedSelections()
  const { data: dataAvailability } = useGetDataAvailability()
  const locationId = getLocationOrDefault()

  const isDataLoaded = Boolean(userInformation) && locations.length > 0
  const userCountryCode = locations
    .filter(isCountry)
    .find(({ countryName }) => countryName === userInformation?.country)?.countryCode

  const onScroll = React.useCallback(() => {
    if (isOnTop && window.scrollY > 0) setIsOnTop(false)
    if (!isOnTop && window.scrollY === 0) setIsOnTop(true)
  }, [isOnTop])

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  const isValidSelector = (selector: string) => {
    try {
      document.createDocumentFragment().querySelector(selector)
    } catch {
      return false
    }
    return true
  }

  const countryCode = getCountry(locationId, locations).countryCode
  const currentFY = dataAvailability?.foodCurrentFY ?? 0

  React.useEffect(() => {
    if (!isCluster(locationId)) {
      setPrevFyGoals(undefined)
      setCurrFyGoals(undefined)

      getGoals(countryCode, func, currentFY).then(goals => {
        const goalsForLocation = getGoalsForLocation(locationId, goals)
        setPrevFyGoals(goalsForLocation)
      })
      getGoals(countryCode, func, currentFY - 1).then(goals => {
        const goalsForLocation = getGoalsForLocation(locationId, goals)
        setPrevFyGoals(goalsForLocation)
      })
    }
  }, [])

  React.useLayoutEffect(() => {
    if (window.location.hash && isValidSelector(window.location.hash) && document.querySelector(window.location.hash)) {
      scrollIntoView(window.location.hash)
    }
  }, [])

  const countryName = getLocationLabel(currentLocation)

  const topCardsProps = {
    currentLocation,
    siteOrCountry: locationId,
    goals: { [currentFY]: currFYGoals, [currentFY - 1]: prevFYGoals }
  }
  return (
    <div className="PersonalizedLandingPage">
      <Section className="Heading">
        <h1 className="FourPsHeading">Sustainability Performance</h1>
        <p>
          The 4P Dashboard is a tool that helps us measure the impact of INGKA operations, understand what actions we
          can take, and track our progress as we work together to reach our sustainability targets.
        </p>
        <button className="ScrollDownForMore" onClick={() => scrollIntoView('#new-in')}>
          Scroll down for more <DownArrow />
        </button>
      </Section>
      <Section className="BigThree" id="new-in">
        <h3 className="Subtitle">New in 4P Dashboard</h3>
        <p>Measuring the People & Sustainability Performance</p>
        <div className="KPIContainer">
          <div className="KPI">
            <img src={socialImpactHeader} alt="Social Impact header image" />
            <h2>Social Impact</h2>
            <p>
              To begin to measure our positive impact we will start by the social impact of the hundreds of initiatives
              done at local and country level to support our local communities and neighbourhoods. We will also continue
              to develop this during FY22. This KPI will measure the number of people in the Ingka value chain that are
              positively impacted
            </p>
            <Link className="ArrowLink" siteOrCountry={locationId} page={Route.SocialImpactKPIPage}>
              <ArrowLink />
            </Link>
          </div>
          <div className="KPI">
            <img src={zeroWasteHeader} alt="Recycling Rate header image" />
            <h2>Recycling Rate</h2>
            <p>
              To begin to measure our circularity we will start with ZRecycling rate in our operations. We will also
              continue to develop this during FY22. Applying the recognized waste hierarchy, we have the ambition to
              reduce, prevent, re-use and recycle waste, and never incinerate or simply dispose of our waste. The
              Recycling Rate KPI will drive the action into minimizing the quantity of waste going to landfill and
              incineration and maximizing the share of waste going to recycling.
            </p>
            <Link className="ArrowLink" siteOrCountry={locationId} page={Route.ZeroWasteKPIPage}>
              <ArrowLink />
            </Link>
          </div>
        </div>
      </Section>
      {/* <Section className="BigThreeIntroduction" id="new-in-how-to">
        <h3 className="Subtitle">Measuring the People & Planet Positive performance</h3>
        <p>See the introduction video and get to know the new KPIs</p>
        <MsStreamVideo id="21278fba-9ad8-46ea-b081-8ba5835681ef" />
        <p>
          <a
            className="ArrowLink"
            href="https://iweof.sharepoint.com/:p:/r/teams/o365g_sbpsustainabilitydashboard_issemal/_layouts/15/doc2.aspx?action=edit&sourcedoc=%7BF05968AD-57CA-4889-B1D9-B6EE7B3C58D9%7D&cid=6fccc22c-97a6-44ed-865d-5cb3e46bc784"
            target="_blank"
            rel="noreferrer"
          >
            <ArrowLink /> Introductory session presentation
          </a>
        </p>
      </Section> */}
      <InTheSpotlightSection className="KPIGraphs" tagline={`Selected 4P KPIs from ${countryName}`}>
        <div className="GraphContainer">
          <ZeroWasteTopCard {...topCardsProps} />
          <PlanetTopCard {...topCardsProps} />
          <SocialImpactTopCard {...topCardsProps} />
        </div>
        {(isSiteId(locationId) || isCountryCode(locationId)) && (
          <>
            <p>{`${countryName}'s current ranking in the Leaderboard`}</p>
            <LeaderboardTopPanel func={['ALL']} locations={locations} siteOrCountry={locationId} />
            <Link className="ArrowLink" siteOrCountry={locationId} page={Route.LeaderboardPage}>
              <ArrowLink /> Go to the Leaderboard
            </Link>
          </>
        )}
      </InTheSpotlightSection>
      {/* <Section className="MarketManagers" id="market-managers">
        <h3 className="Subtitle">Information For Market Managers</h3>
        <p>Click to watch a recording from the market manager introductory sessions</p>
        <MsStreamVideo id="3ef31420-315d-438a-89d4-faceecffbd66" />
        <p>
          <a
            className="ArrowLink"
            href="https://iweof.sharepoint.com/:p:/t/o365g_sbpsustainabilitydashboard_issemal/ET6N5Fr12SlKrBbosTnJIXUB_yekthOrIhLikF_gKyh2yA"
            target="_blank"
            rel="noreferrer"
          >
            <ArrowLink /> Introductory session presentation
          </a>
        </p>
      </Section> */}
      <Section className="FAQ" id="faq">
        <p>Frequently asked questions</p>
        <Accordion>
          <AccordionItem
            id="leaderboard-vs-benchmarking"
            title="Why does my site rank differently when comparing figures across the Benchmarking feature and the Leaderboard?"
          >
            <p>
              The Leaderboard considers site/country average performance over the last rolling 12-month period. Further,
              the leaderboard uses a <strong>weighted</strong> average towards most recent months to ensure that
              site/country improvements are reflected in the leaderboard sooner
            </p>
          </AccordionItem>
          <AccordionItem id="planetary-footprint" title="How do you calculate the climate footprint?">
            <p>
              We convert raw waste, water, and energy usage (in each stream) to CO2e using <em>conversion factors</em>{' '}
              according to their respective contributions to climate change, then sum them up to get the total
              footprint. Check out our <em>How do we calculate Climate Footprint?</em> section for some calculation
              examples.
            </p>
          </AccordionItem>
          <AccordionItem
            id="flight-tower-footprint"
            title="Why does the Retail Flight Tower show different figures for climate footprint for my country?"
          >
            <p>The most common causes:</p>
            <ul>
              <li>
                Function selection – In order to see comparable numbers to those in the Flight Tower, you need to select
                Retail, CFF & Support Units in the 4P tool
              </li>
              <li>Date range – Confirm that same time periods are being compared between tools</li>
            </ul>
            <p>If the issue persists, please submit a feedback ticket and we will gladly investigate further.</p>
          </AccordionItem>
          <AccordionItem id="footprint-lag" title="Why is there a lag in the availability of climate footprint data?">
            <p>
              We update the 4P Dashboard at the beginning of every month with the latest available data in Sustain. We
              are always 2 months behind the current month as much of the data is based on bills, which naturally lag
              the billing period. Additional time is invested by the Sustainability team to ensure highest data quality.
            </p>
          </AccordionItem>
          <AccordionItem
            id="footprint-what-if-scenario"
            title="How does the Planet What-If Scenario predict future consumption?"
          >
            <p>
              The What-If feature estimates a site’s future consumption based on historical volumes per planet
              indicator. For example, FY21 forecasted total Energy consumption is based on FY19 Energy consumption.
            </p>
            <p>
              Next, the What-If feature estimates the split by fraction according to YTD distributions. That is, if you
              have recently switched to renewable energy, the What-if feature will predict that this is the fraction you
              will continue to use for the rest of the fiscal year.
            </p>
            <p>
              The What-If curve is made up of two components: i. the YTD climate footprint of the current fiscal year,
              and ii. the climate footprint based on the volumes of the reference year for the rest of the fiscal year.
              As there might be quite a difference in climate footprint between the reference year and the current year,
              this combination of curves will sometimes look a bit odd.
            </p>
            <p>
              For more detailed examples of What-If calculations, please refer to the Learn More section of the What-If
              tool.
            </p>
          </AccordionItem>
          <AccordionItem id="ppp-sales-transactions-share" title="How is PPP sales/transaction share calculated?">
            <p>
              PPP share is based on the share of Sustainability Items sold. This consists of two contributing classes of
              items:
            </p>
            <ul>
              <li>
                PPP Items - Items can be classified as PPP for a number of reasons including: Better Materials, Energy
                Saving, Longer Lasting, etc. For more information on the product list click here.
              </li>
              <li>
                As-is Items – Items sold at a markdown that would otherwise have gone to waste. These can be any items
                across the product range.
              </li>
            </ul>
          </AccordionItem>
          <AccordionItem
            id="ppp-sales-transactions-share-jumps"
            title="Why can I see large jumps in my PPP sales/transaction share?"
          >
            <p>
              The PPP product list is updated every 3-6 months to reflect updates to our product range. When new product
              lists come into effect, this is often accompanied with additional items.
            </p>
          </AccordionItem>
        </Accordion>
      </Section>
      {/* <Section className="HowTo" id="how-to">
        <p>Want to know how to use the 4P Dashboard? Watch the videos below.</p>
        <div className="InstructionVideos">
          <InstructionVideos />
        </div>
      </Section> */}
      {isDataLoaded && (
        <StickyLocationSelector
          isOnTop={isOnTop}
          locations={locations}
          userCountryCode={userCountryCode}
          userFunctionArea={userInformation?.functionArea}
        />
      )}
      <Footer onFeedback={() => openFeedbackModal()} />
      {isFeedbackOpen && <FeedbackModal isOpen={isFeedbackOpen} closeFn={() => closeFeedbackModal()} />}
    </div>
  )
}

const scrollDuration = 200
const scrollIntoView = (target: string) => {
  const scrollToElement = document.querySelector(target)

  if (scrollToElement) {
    const startingY = window.pageYOffset

    let start: number
    const step = (timestamp: number) => {
      if (!start) start = timestamp

      const elementY =
        window.pageYOffset +
        scrollToElement.getBoundingClientRect().top -
        (document.querySelector('.StickyLocationSelector')?.getBoundingClientRect().height ?? 0)
      const diff = elementY - startingY

      const time = timestamp - start
      const percent = Math.min(time / scrollDuration, 1)
      window.scrollTo(0, startingY + diff * percent)

      if (time < scrollDuration) {
        window.requestAnimationFrame(step)
      }
    }

    window.requestAnimationFrame(step)
  }
}
