import React from 'react'
import _ from 'lodash'

import {
  ApiGeneratedByLocation,
  ApiGeneratedByLocationAndLandfill,
  ApiTotalFootprint,
  ClimateFootprintAction,
  RefrigerantFactorsType,
  RenewableEnergyShare
} from '../../../../api/src/common-types'
import { PageHeader } from '../../../components/PageHeader'
import { TopBar } from '../../../components/TopBar'
import { useSharedSelections } from '../../../SharedSelections'

import '../../KPIPages/KPIPage.scss'
import './ClimateExplorePage.scss'
import colours from '../../../Colours.module.scss'
import { useLocations, ThemeContext } from '../../../context'
import {
  getCluster,
  getCountry,
  getLocationLabel,
  getLocationOrDefault,
  getLocationSelector,
  isCluster,
  isSiteId
} from '../../../components/Utils/utils'
import {
  getClimateActions,
  getClimateActionsExtended,
  getRefrigerantsFactors,
  getRenewableEnergyShare,
  getSelectedGoals,
  getTotalFootprint,
  getTotalFootprintSBTi,
  isSite,
  useEffectWithAbort
} from '../../../lib/APIClient'
import { getYear, isSameDay, min, format } from 'date-fns'
import { ChartContainer, Serie, DataPoint } from '../../../components/BaseGraphs/ChartContainer'
import { DateFormat, lineChart, stackedBarChart, sortSeriesByName } from '../../../components/BaseGraphs/GraphUtil'
import { formatPercentage, formatRelativeNumber } from '../../../components/Utils/format'
import { SumIndicator } from '../../../components/BaseGraphs/Indicators'
import { ExploreGraphCard } from '../ExploreGraphCard'
import { FootprintUnitSelector } from '../../../components/FootprintUnitSelector'
import { GraphUnit, UnitSelector } from '../../../components/UnitSelector'
import { colorScheme } from '../../../components/BaseGraphs/Universal'
import { getDateRange } from '../ExplorePage'
import { useFilters } from '../ExplorePage'
import { Route } from '../../../routes'
import { getIngkaFinancialYear } from '../../../components/Utils/dates'

import { getYAxisText } from './utils/getYAxisText'
import { TooltipSummaryByType } from './TooltipSummary'
import { getLastYearGap } from './utils/getLastYearGap'
import { TooltipItems } from './TooltipItems'
import { AnnualLegend } from './AnnualLegend'
import { DetailedActionViewModal, KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import {
  countryHeaders,
  globalHeaders,
  KpiFootprintModalContentProps,
  siteHeaders
} from '../../../components/ModalContent/KpiFootprintModalContent'
import { createColorScheme } from './utils/colors'
import { Series } from 'd3-shape'
import { RefrigerantsTooltip } from './RefrigerantsTooltip'
import { SwitchScopeBtn } from '../../../components/SwitchScopeBtn'
import InlineMessage from '@ingka/inline-message'
import { formatNumberValue } from '../../../components/BaseGraphs/Tooltip'
import { WasteGraphs } from '../ZeroWaste/components/WasteGraphs'
import { useRecycledRateData } from '../ZeroWaste/ZeroWasteExplorePage'
import { useGetDataAvailability } from '../../../services/general/service'

const scaledMean = _.flow(_.mean, n => n / 1000)

const footprintColors = {
  energy: colours.purple1,
  refrigerants: colours.salmon2,
  waste: colours.yellow1,
  water: colours.blue8
}

type TimeRange = 'monthly' | 'annual'

const kpisOld = ['footprint', 'energy', 'refrigerants', 'waste', 'water'] as const

const kpisSBTi = ['footprint', 'energy', 'refrigerants', 'waste'] as const

const kpiLabels = {
  footprint: 'Climate Footprint – Own Operations',
  energy: 'Energy',
  refrigerants: 'Refrigerants',
  waste: 'Waste',
  water: 'Water'
}

const waterColorMap = {
  'Water - Wells': colours.pink,
  'Water - Rain harvesting': colours.lightBlue7,
  'Water - Externally supplied': colours.blue7
}

export type ClimateExplorePageContextType = {
  refrigerantFactors: RefrigerantFactorsType | null
}

export const ClimateExplorePageContext = React.createContext<ClimateExplorePageContextType>({
  refrigerantFactors: null
})

export const ClimateExplorePage = () => {
  const locationId = getLocationOrDefault()
  const [{ func, scope }] = useSharedSelections()
  const { data: dataAvailability } = useGetDataAvailability()
  const { currentLocation, clusters, locations } = useLocations()

  const isSbti = scope.includes('sbti')
  const [isOld, setIsOld] = React.useState<boolean>(!isSbti)

  const kpis = isOld ? kpisOld : kpisSBTi

  const [{ kpi, timeRange, rangeFrom, rangeTo }, filterSelectors] = useFilters(
    kpis,
    kpiLabels,
    dataAvailability?.planetCurrentFY ?? 2000
  )
  const [totalFootprint, setTotalFootprint] = React.useState<ApiTotalFootprint[] | undefined>(undefined)
  const [totalRefrigerantsFootprint, setTotalRefrigerantsFootprint] = React.useState<ApiTotalFootprint[] | undefined>(
    undefined
  )
  const [dates, setDates] = React.useState<Date[]>([])
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [modalActionState, setModalActionState] = React.useState<KpiModalState>({ isOpen: false })
  const [goals, setGoals] = React.useState<Record<string, number>>({})
  const [actions, setActions] = React.useState<Record<string, ClimateFootprintAction>>({})
  const [actionsExtended, setActionsExtended] = React.useState<Record<string, KpiFootprintModalContentProps[]>>({})
  const [refrigerantFactors, setRefrigerantFactors] = React.useState<RefrigerantFactorsType | null>(null)

  const country = !isCluster(locationId) && locations.length > 0 ? getCountry(locationId, locations) : undefined

  const selector = {
    locationId,
    start_fy: parseInt(rangeFrom) - 2000,
    end_fy: parseInt(rangeTo) - 2000,
    func,
    timeRange
  }

  const { treatmentMap, treatmentWaste, generatedByTypeWaste, generatedByLocationWaste, landfillShare } =
    useRecycledRateData(selector, kpi)

  const getClimateGoals = async (signal: AbortSignal) => {
    const goalsData = await getSelectedGoals(
      locationId,
      func,
      isOld ? 'climate_goal' : 'sbti_climate_goal',
      signal,
      dataAvailability?.planetCurrentFY
    )
    setGoals(goalsData)
  }

  useEffectWithAbort(
    async signal => {
      try {
        setDates([])
        setTotalFootprint(undefined)
        setTotalRefrigerantsFootprint(undefined)

        if (locations.length === 0 || clusters.length === 0) {
          return
        }

        const countryOrLocationId = isSiteId(locationId) ? getCountry(locationId, locations).countryCode : locationId
        const parameters = {
          ...getLocationSelector(countryOrLocationId, getCluster(clusters, countryOrLocationId)?.countryCodes),
          func,
          isOld,
          start_fy: timeRange === 'monthly' ? parseInt(rangeFrom) : 2016,
          end_fy: timeRange === 'monthly' ? parseInt(rangeTo) : dataAvailability?.planetCurrentFY
        }

        // Fetch total footprint and total footprint SBTi
        const [totalFootprintResponse, totalFootprintSBTiResponse, refrigerantFactors] = await Promise.all([
          getTotalFootprint(parameters, isOld ? undefined : ['waste', 'refrigerants'], signal),
          !isOld ? getTotalFootprintSBTi(parameters, undefined, signal) : Promise.resolve(null),
          getRefrigerantsFactors(signal)
        ])

        setRefrigerantFactors(refrigerantFactors)

        if (isOld) {
          setDates(getDateRange(timeRange === 'annual', totalFootprintResponse.dates, totalFootprintResponse.data))
          setTotalFootprint(totalFootprintResponse.data)
        } else {
          setTotalRefrigerantsFootprint(totalFootprintResponse.data.filter(f => f.footprintType === 'refrigerants'))
        }
        if (!isOld && totalFootprintSBTiResponse) {
          setDates(
            getDateRange(timeRange === 'annual', totalFootprintSBTiResponse.dates, totalFootprintSBTiResponse.data)
          )
          setTotalFootprint(totalFootprintSBTiResponse.data)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [JSON.stringify(func), locationId, JSON.stringify(clusters), rangeFrom, rangeTo, locations.length, timeRange, isOld]
  )

  const getActions = async (signal: AbortSignal) => {
    if (country) {
      const actionsData = await getClimateActions(locationId, func, signal)
      setActions(actionsData)
    }
  }

  const getActionsExtended = async (signal: AbortSignal) => {
    if (country) {
      const actionsData = await getClimateActionsExtended(locationId, func, signal)
      setActionsExtended(actionsData)
    }
  }

  useEffectWithAbort(
    signal => {
      setActions({})
      setGoals({})
      setActionsExtended({})
      !isOld && getActionsExtended(signal)
      !isOld && getActions(signal)
      getClimateGoals(signal)
    },
    [JSON.stringify(func), country, dataAvailability?.planetCurrentFY, locationId, isOld]
  )

  const locationIsSite = !currentLocation.isCluster && isSite(currentLocation.location)

  const countryGlobalHeaders = locationId === 'ALL' ? globalHeaders : countryHeaders

  const actionsFooter = actionsExtended?.[modalActionState.fiscalYear ?? 0]?.[0] || {}

  const actionsSumForEachYear = Object.entries(actionsExtended).reduce(
    (acc, [year, action]) => ({
      ...acc,
      [year]: locationIsSite ? actionsExtended[year].length - 1 : action[0].actions
    }),
    {}
  )

  const detailedActionViewModalSerie = actionsExtended?.[modalActionState.fiscalYear ?? 0]?.slice(1) ?? []
  const filteredFootprint = totalFootprint?.filter(f => f.unit !== 'kWh')

  const mergedGeneratedByLocationWaste: ApiGeneratedByLocationAndLandfill[] | undefined =
    generatedByLocationWaste &&
    landfillShare &&
    generatedByLocationWaste.map((item1: ApiGeneratedByLocation) => {
      const item2 = landfillShare.find(
        (item: ApiGeneratedByLocation) =>
          (timeRange === 'annual'
            ? item.fiscal_year === item1.fiscal_year
            : item.readable_date === item1.readable_date) &&
          (locationId === 'ALL' ? item.countryCode === item1.countryCode : item.siteId === item1.siteId)
      )
      return item2 ? { ...item1, landfill_share: item2.landfill_share } : item1
    })

  return (
    <ClimateExplorePageContext.Provider value={{ refrigerantFactors }}>
      <div className="KPIPage">
        <TopBar currentPage={Route.ClimateExplorePage} useInFlexLayout />
        <PageHeader
          className="ClimateFootprintHeader ClimateFootprintHeader-whithSBTi"
          route={Route.ClimateExplorePage}
        >
          <SwitchScopeBtn
            setIsOld={setIsOld}
            isOld={isOld}
            textLeftBtn={`FY24 - Old Scope`}
            textRightBtn={`FY25 - New Scope`}
            queryParamFirst="oldscope"
            queryParamLast="sbti"
          />
        </PageHeader>
        <div className="PageContent">
          {filterSelectors}
          <div className="InlineMessageWrapper">
            <InlineMessage
              body={
                isOld
                  ? 'To see goals and performance for climate footprint - own operation in new/FY25 scope, please change in top right corner'
                  : 'To see goals and performance for climate footprint - own operation in old/FY24 scope, please change in top right corner'
              }
              variant="cautionary"
            />
          </div>
          {kpi === 'refrigerants' && (
            <div className="InlineMessageWrapper">
              <InlineMessage
                body="FY16-19 footprint is estimated and no leakage kilos are available as reporting was only mandatory from FY20"
                variant="cautionary"
              />
            </div>
          )}
          {kpi === 'footprint' ? (
            <ClimateFootprintGraphs
              footprint={isOld ? totalFootprint : filteredFootprint}
              dates={dates}
              timeRange={timeRange}
              locationId={locationId}
              isSite={locationIsSite}
              modalState={() => setModalState({ isOpen: true, page: Route.ClimateExplorePage })}
              goals={goals}
              actions={actions}
              setModalActionState={setModalActionState}
              actionsSumForEachYear={actionsSumForEachYear}
              isOld={isOld}
            />
          ) : kpi === 'energy' ? (
            <EnergyGraphs
              footprint={totalFootprint ? totalFootprint.filter(f => f.footprintType === 'energy') : undefined}
              dates={dates}
              timeRange={timeRange}
              locationId={locationId}
              rangeFrom={rangeFrom}
              rangeTo={rangeTo}
              isSite={locationIsSite}
              isOld={isOld}
            />
          ) : kpi === 'refrigerants' ? (
            <RefrigerantsGraphs
              footprint={
                isOld ? totalFootprint?.filter(f => f.footprintType === 'refrigerants') : totalRefrigerantsFootprint
              }
              dates={dates}
              timeRange={timeRange}
              locationId={locationId}
              rangeFrom={rangeFrom}
              rangeTo={rangeTo}
              isSite={locationIsSite}
              refrigerantFactors={refrigerantFactors}
            />
          ) : kpi === 'waste' ? (
            <WasteGraphs
              dates={dates}
              timeRange={timeRange}
              locationId={locationId}
              mergedGeneratedByLocationWaste={mergedGeneratedByLocationWaste}
              generatedByTypeWaste={generatedByTypeWaste}
              treatmentWaste={treatmentWaste}
              treatmentMap={treatmentMap}
              isClimatExplore={true}
            />
          ) : (
            <WaterGraphs
              footprint={totalFootprint?.filter(f => f.footprintType === 'water')}
              dates={dates}
              timeRange={timeRange}
              locationId={locationId}
              isSite={locationIsSite}
            />
          )}
        </div>
        <KpiPageLearnMoreModal
          isExplore={true}
          lastUpdated={''}
          modalState={modalState}
          onClose={() => setModalState({ isOpen: false })}
        />
        <DetailedActionViewModal
          isOpen={modalActionState.isOpen}
          onClose={() => setModalActionState({ isOpen: false })}
          isSite={locationIsSite}
          series={detailedActionViewModalSerie}
          headers={locationIsSite ? siteHeaders : countryGlobalHeaders}
          bottom={actionsFooter}
          fy={modalActionState.fiscalYear}
        />
      </div>
    </ClimateExplorePageContext.Provider>
  )
}

interface GraphProps {
  footprint: ApiTotalFootprint[] | undefined
  dates: Date[] | undefined
  timeRange: TimeRange
  locationId: string
  isSite: boolean
  modalState?: () => void
  setModalActionState?: ({ isOpen, page, fiscalYear }: { isOpen: boolean; page: Route; fiscalYear: number }) => void
}

interface ClimateFootprintGraphsProps extends GraphProps {
  goals: Record<string, number>
  actions: Record<string, ClimateFootprintAction>
  actionsSumForEachYear: Record<string, number>
  isOld?: boolean
}

export const ClimateFootprintGraphs = ({
  footprint,
  dates,
  timeRange,
  locationId,
  isSite,
  modalState,
  actions,
  goals,
  setModalActionState,
  actionsSumForEachYear,
  isOld
}: ClimateFootprintGraphsProps) => {
  const [footprintUnit, setFootprintUnit] = React.useState(GraphUnit.ConvertedUnits)
  const [byLocationUnit, setByLocationUnit] = React.useState(GraphUnit.ConvertedUnits)
  const { data: dataAvailability } = useGetDataAvailability()

  const isGlobalSelector = locationId === 'ALL'
  const locationGroupingKey = isGlobalSelector ? 'countryName' : 'siteId'

  const { series, totals, hasRelative } = React.useMemo(() => {
    const byLocation = createSeriesByLocation(
      footprint,
      dates,
      timeRange,
      locationId,
      isSite,
      sumByUnit(byLocationUnit),
      byLocationUnit
    )

    const footprintForLocation = footprint && footprint.filter(f => (isSite ? f.siteId === locationId : true))
    const byContributorData = sortSeriesByName(
      createSeriesWithKey({
        groupingKey: 'footprintType',
        data: footprintForLocation,
        dates,
        timeRange,
        colors: footprintColors,
        dataReduceFn: sumByUnit(GraphUnit.ConvertedUnits),
        withDynamicFormatting: true
      })
    )

    const forTooltipSummaryData = sortSeriesByName(
      createSeriesWithKey({
        groupingKey: 'footprintType',
        data: footprintForLocation,
        dates,
        timeRange,
        colors: footprintColors,
        dataReduceFn: sumByUnit(GraphUnit.ConvertedUnits)
      })
    )

    const isRelative = footprintUnit === GraphUnit.RelativeUnits
    const relativeAnnualTotal = isRelative && timeRange === 'annual' ? { amount: '', unit: '' } : null

    const getAverage = () => {
      const totalAreasByReadableDate = _.fromPairs(
        Object.entries(_.groupBy(footprintForLocation, 'readableDate')).map(([readableDate, footprints]) => [
          format(new Date(readableDate), 'yyy-MM-d'),
          _.sumBy(_.uniqBy(footprints, 'siteId'), 'area')
        ])
      )

      return byContributorData?.map(contributor => ({
        ...contributor,
        data: contributor.data.map(coords => ({
          ...coords,
          y: (coords.y * 1000) / totalAreasByReadableDate[format(coords.x, 'yyyy-MM-d')]
        })),
        unit: '%'
      }))
    }

    const byContributor = isRelative ? getAverage() : byContributorData

    return {
      hasRelative: hasRelativeDataAvailable(footprint),
      series: { byLocation, byContributor, forTooltipSummaryData },
      totals: {
        byContributor: relativeAnnualTotal ?? getTotal(footprintForLocation, footprintUnit, locationGroupingKey),
        byLocation:
          relativeAnnualTotal ??
          getTotal(
            isSite ? footprintForLocation : footprint,
            byLocationUnit,
            locationGroupingKey,
            byLocationUnit !== GraphUnit.ConvertedUnits ? _.mean : scaledMean,
            'avg.'
          )
      }
    }
  }, [JSON.stringify(footprint), JSON.stringify(dates), footprintUnit, byLocationUnit])

  const annualDomainUntil2030 = dates
    ? _.range(getYear(getIngkaFinancialYear(min(dates))) - 1, 2030).map(year => new Date(`${year}-09-30`))
    : []
  const goalSerie: Serie = {
    name: 'goal',
    color: 'none',
    isBackgroundSerie: true,
    data: annualDomainUntil2030
      .map(date => ({
        x: date,
        y: goals[getYear(getIngkaFinancialYear(date)).toString()] ?? NaN,
        color:
          dataAvailability && getYear(getIngkaFinancialYear(date)) <= dataAvailability.planetCurrentFY + 1
            ? colours.lightBlue2
            : colours.grey2
      }))
      .filter(({ y }) => !isNaN(y))
  }

  const actionsSerie: Serie = {
    name: 'Planned activities',
    color: '',
    data: annualDomainUntil2030.reduce((acc: DataPoint[], date): DataPoint[] => {
      const goalCurrentFy = goals[Number(dataAvailability?.planetCurrentFY).toString()] ?? NaN
      const shouldCutCurrYActions =
        Number.isNaN(goalCurrentFy) && getYear(getIngkaFinancialYear(date)) === dataAvailability?.planetCurrentFY
      if (shouldCutCurrYActions) {
        return acc
      }
      if (getYear(getIngkaFinancialYear(date)) > 2025 && getYear(getIngkaFinancialYear(date)) <= 2030) {
        const {
          sum_co2e_tonnes: totalTonnesCo2,
          reltp_co2e_tonnes: reltp,
          expansion_co2e_tonnes: expansions,
          procurement_co2e_tonnes: procurements
        } = actions[getYear(getIngkaFinancialYear(date)).toString().substr(-2)] ?? {
          sum_co2e_tonnes: 0,
          reltp_co2e_tonnes: 0,
          expansion_co2e_tonnes: 0,
          procurement_co2e_tonnes: 0
        }
        const startPoint = _.last(acc) ? Number(_.last(acc)?.startPoint) + totalTonnesCo2 : null

        return [
          ...acc,
          {
            actionTypes: { reltp, expansions, procurements },
            color: totalTonnesCo2 > 0 ? 'url(#small-orange-strokes-pattern' : 'url(#small-green-strokes-pattern',
            x: date,
            y: totalTonnesCo2,
            startPoint: startPoint ?? Number(Object.values(goals)[0]) + totalTonnesCo2
          }
        ] as DataPoint[]
      }
      return acc
    }, [])
  }

  const isConvertedUnits = footprintUnit === GraphUnit.ConvertedUnits
  const getTotalSummary = TooltipSummaryByType(
    series.forTooltipSummaryData,
    goalSerie,
    actionsSerie,
    isConvertedUnits,
    isOld
  )
  const gapSerie = getLastYearGap(actionsSerie.data, goalSerie.data)

  const getDomain = (goalSerie: Serie): Date[] | undefined => {
    const domain = annualDomainUntil2030
    for (const { x } of goalSerie.data) {
      if (!domain?.some(date => isSameDay(date, x))) domain?.push(x)
    }
    return domain
  }

  const domain = timeRange === 'annual' && isConvertedUnits ? getDomain(goalSerie) : dates

  if (goalSerie.data.every(({ x }) => getYear(x) === 2023)) {
    actionsSerie.data = []
  }

  return (
    <div>
      <ExploreGraphCard
        heading="Climate footprint – Own operations by type"
        description="Each location's waste, water, and energy consumption are converted into the equivalent amount of Carbon Dioxide released into the atmosphere. This graph shows those values over time."
        {...totals.byContributor}
        timeRange={timeRange}
        onClickHandler={modalState}
      >
        <ChartContainer
          testId="footprint-type-chart"
          generator={stackedBarChart('none')}
          series={
            !footprint
              ? undefined
              : timeRange === 'annual' && isConvertedUnits
              ? Object.keys(actions).length
                ? [...(series.byContributor ?? []), goalSerie, actionsSerie, gapSerie]
                : [...(series.byContributor ?? []), goalSerie]
              : series.byContributor
          }
          domain={domain}
          dateFormat={timeRange === 'monthly' ? 'monthWithYear' : 'fy'}
          tooltipSummary={getTotalSummary}
          tooltipItemsFn={TooltipItems}
          customLegend={<AnnualLegend />}
          additionalComponents={
            <FootprintUnitSelector enabled={hasRelative} onChange={setFootprintUnit} value={footprintUnit} />
          }
          yAxisTitle={getYAxisText(footprintUnit)}
          modalHandler={(year: number) => () =>
            setModalActionState &&
            setModalActionState({ isOpen: true, page: Route.ClimateExplorePage, fiscalYear: year })}
          actionsSumForEachYear={actionsSumForEachYear}
          withDynamicFormatting
          notShowNA
        />
      </ExploreGraphCard>
      <ExploreGraphCard heading="Climate footprint – Own operations by location" {...totals.byLocation}>
        <ChartContainer
          testId="footprint-location-chart"
          generator={lineChart}
          series={series.byLocation}
          lineChartConfiguration={{ focusStyle: isSite ? 'top' : 'nearest', startFromZero: false }}
          domain={dates}
          dateFormat={timeRange === 'monthly' ? 'monthWithYear' : 'fy'}
          yAxisTitle={getYAxisText(byLocationUnit)}
          disableLegendItems={isSite}
          showDeselectAll={!isSite}
          highLightedSerieName={isSite ? series.byLocation?.find(s => s.name !== 'Other locations')?.name : undefined}
          additionalComponents={
            <FootprintUnitSelector enabled={hasRelative} onChange={setByLocationUnit} value={byLocationUnit} />
          }
        />
      </ExploreGraphCard>
    </div>
  )
}

interface EnergyGraphProps extends GraphProps {
  rangeFrom: string
  rangeTo: string
  isOld?: boolean
}

interface RefrigerantsGraphProps extends GraphProps {
  rangeFrom: string
  rangeTo: string
  refrigerantFactors: RefrigerantFactorsType | null
}

const EnergyGraphs = ({
  footprint,
  dates,
  timeRange,
  locationId,
  rangeFrom,
  rangeTo,
  isSite,
  isOld
}: EnergyGraphProps) => {
  const energyColors = React.useContext(ThemeContext)
  const { currentLocation, locations, clusters } = useLocations()
  const [{ func }] = useSharedSelections()
  const { data: dataAvailability } = useGetDataAvailability()
  const [renewableShare, setRenewableShare] = React.useState<RenewableEnergyShare[] | undefined>(undefined)
  const [byContributorUnit, setByContributorUnit] = React.useState(GraphUnit.ConvertedUnits)
  const [byLocationUnit, setByLocationUnit] = React.useState(GraphUnit.ConvertedUnits)
  const [averageRenewableShare, setAverageRenewableShare] = React.useState(0)

  React.useEffect(() => {
    setRenewableShare(undefined)
    setAverageRenewableShare(0)

    const countryOrLocationId = isSiteId(locationId) ? getCountry(locationId, locations).countryCode : locationId
    getRenewableEnergyShare({
      ...getLocationSelector(countryOrLocationId, getCluster(clusters, countryOrLocationId)?.countryCodes),
      func,
      isOld: isOld ?? true,
      start_fy: timeRange === 'monthly' ? parseInt(rangeFrom) : 2016,
      end_fy: timeRange === 'monthly' ? parseInt(rangeTo) : dataAvailability?.planetCurrentFY
    }).then(response => {
      setRenewableShare(response.data)
      const renewableForLocation = response.data.filter(f => (isSite ? f.id === locationId : true))
      const avg = _.sumBy(renewableForLocation, 'renewableShare') / renewableForLocation.length
      setAverageRenewableShare(avg * 100)
    })
  }, [locationId, locations.length, timeRange, rangeFrom, rangeTo])

  const isGlobalSelector = locationId === 'ALL'
  const locationGroupingKey = isGlobalSelector ? 'countryName' : 'siteId'

  const { series, totals, hasRelative } = React.useMemo(() => {
    const contributorFootprint = ['raw', 'relativeraw'].includes(byContributorUnit)
      ? footprint?.filter(f => f.unit === 'kWh').filter(f => (isSite ? f.siteId === locationId : true))
      : footprint?.filter(f => f.unit !== 'kWh').filter(f => (isSite ? f.siteId === locationId : true))

    let locationFootprint = footprint && footprint.filter(f => (isSite ? f.siteId === locationId : true))

    !isOld &&
      (locationFootprint = ['raw', 'relativeraw'].includes(byLocationUnit)
        ? footprint?.filter(f => f.unit === 'kWh').filter(f => (isSite ? f.siteId === locationId : true))
        : footprint?.filter(f => f.unit !== 'kWh').filter(f => (isSite ? f.siteId === locationId : true)))

    const totalAreasByReadableDate = _.fromPairs(
      Object.entries(_.groupBy(locationFootprint, 'readableDate')).map(([readableDate, footprints]) => [
        readableDate,
        _.sumBy(_.uniqBy(footprints, 'siteId'), 'area')
      ])
    )

    let byContributor = createSeriesWithKey({
      groupingKey: isOld ? 'footprintContributor' : 'treatmentType',
      data: isOld ? locationFootprint : contributorFootprint,
      dates,
      timeRange,
      colors: energyColors,
      dataReduceFn:
        byContributorUnit === GraphUnit.RelativeRawUnits
          ? byUnitAndTotalArea(byContributorUnit, timeRange, totalAreasByReadableDate)
          : sumByUnit(byContributorUnit),
      unit: byContributorUnit === GraphUnit.RelativeRawUnits ? '%' : '',
      withDynamicFormatting: true,
      customNameFunc: (groupKey: string, slice: Array<ApiTotalFootprint>) => {
        return slice[0]['footprintContributor'] || groupKey
      },
      customIdFunc: (groupKey: string, slice: Array<ApiTotalFootprint>) => {
        return slice[0]['treatmentType'] || groupKey
      }
    })

    if (['raw', 'relativeraw'].includes(byContributorUnit))
      byContributor = byContributor?.filter(({ name }) => name !== 'Refrigerants')

    const byLocation = createSeriesByLocation(
      isOld
        ? footprint?.filter(f => f.unit === 'kWh')
        : ['raw', 'relativeraw'].includes(byLocationUnit)
        ? footprint?.filter(f => f.unit === 'kWh')
        : footprint?.filter(f => f.unit !== 'kWh'),
      dates,
      timeRange,
      locationId,
      isSite,
      sumByUnit(byLocationUnit),
      byLocationUnit
    )
    const byContributorData = isOld ? locationFootprint : contributorFootprint

    return {
      hasRelative: hasRelativeDataAvailable(footprint),
      series: { byContributor, byLocation },
      totals: {
        byContributor: getTotal(byContributorData, byContributorUnit),
        byLocation: getTotal(
          isSite ? locationFootprint : footprint,
          byLocationUnit,
          locationGroupingKey,
          byLocationUnit !== GraphUnit.ConvertedUnits ? _.mean : scaledMean,
          'avg.'
        )
      }
    }
  }, [JSON.stringify(footprint), JSON.stringify(dates), timeRange, locationId, byContributorUnit, byLocationUnit])

  const isEitherOnlyCommonAreaOrTenants = _.isEqual(func, ['Common Areas']) || _.isEqual(func, ['Tenants'])
  const renewableSeries = React.useMemo(
    () =>
      !isEitherOnlyCommonAreaOrTenants
        ? createRenewableEnergySeriesByLocation(
            renewableShare,
            dates,
            timeRange,
            getLocationLabel(currentLocation),
            isSite
          )
        : [],
    [
      isEitherOnlyCommonAreaOrTenants,
      JSON.stringify(renewableShare),
      JSON.stringify(dates),
      locations.length,
      locationId
    ]
  )

  const renewableSeriesWithUnit = renewableSeries?.map(r => ({ ...r, unit: '%' }))

  const sortedSeries = sortAndReplace(series)

  return (
    <>
      <ExploreGraphCard
        heading="Energy consumption by type"
        description="This visualization shows energy in different ways. You can change in bottom right corner between showing energy as raw data in kWh, energy efficiency as kWh/m2 or as the equivalent amount of Carbon Dioxide (CO2e) released into the atmosphere by energy used in this location’s operation."
        {...totals.byContributor}
      >
        <ChartContainer
          testId="energy-type-chart"
          generator={stackedBarChart(isOld ? 'descending' : 'reverse')}
          series={isOld ? series.byContributor : sortedSeries.byContributor}
          domain={dates}
          dateFormat={timeRange === 'monthly' ? 'monthWithYear' : 'fy'}
          additionalComponents={
            <UnitSelector
              enabled
              options={[
                GraphUnit.ConvertedUnits,
                GraphUnit.RawUnits,
                ...(hasRelative ? [GraphUnit.RelativeRawUnits] : [])
              ]}
              onChange={setByContributorUnit}
              value={byContributorUnit}
              menuPlacement="top"
            />
          }
          tooltipSummary={date => {
            const total = _(isOld ? series.byContributor : sortedSeries.byContributor)
              .flatMap(vals =>
                _(vals.data)
                  .filter(d => isSameDay(d.x, date))
                  .value()
              )
              .sumBy('y')
            const rounded = formatNumberValue(total, true, byContributorUnit)
            return [
              {
                title: 'Total',
                value: rounded,
                icon: <SumIndicator />,
                unit: getYAxisText(byContributorUnit)
              }
            ]
          }}
          yAxisTitle={getYAxisText(byContributorUnit)}
          isSort={isOld ? true : false}
          withDynamicFormatting
        />
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Energy consumption by location"
        description="This visualization shows the refrigerants consumption in each location’s operation. In total energy footprint also refrigerants are included but they can't be visualized in kWh and due to this only seen in graphs expressing tonnes CO2e."
        {...totals.byLocation}
      >
        <ChartContainer
          testId="energy-location-chart"
          generator={lineChart}
          series={series.byLocation}
          domain={dates}
          dateFormat={timeRange === 'monthly' ? 'monthWithYear' : 'fy'}
          lineChartConfiguration={{ focusStyle: isSite ? 'top' : 'nearest', startFromZero: false }}
          disableLegendItems={isSite}
          showDeselectAll={!isSite}
          highLightedSerieName={isSite ? series.byLocation?.find(s => s.name !== 'Other locations')?.name : undefined}
          additionalComponents={
            <UnitSelector
              enabled={hasRelative}
              options={[GraphUnit.ConvertedUnits, GraphUnit.RawUnits, GraphUnit.RelativeRawUnits]}
              value={byLocationUnit}
              onChange={setByLocationUnit}
              menuPlacement="top"
            />
          }
          yAxisTitle={getYAxisText(byLocationUnit)}
        />
      </ExploreGraphCard>
      <ExploreGraphCard heading="Renewable energy share" amount={averageRenewableShare.toFixed(1)} unit="% avg.">
        <ChartContainer
          testId="energy-renewable-chart"
          generator={lineChart}
          series={renewableSeriesWithUnit}
          domain={dates}
          dateFormat={timeRange === 'monthly' ? 'monthWithYear' : 'fy'}
          lineChartConfiguration={{ focusStyle: isSite ? 'top' : 'nearest', startFromZero: false }}
          disableLegendItems={isSite}
          showDeselectAll={!isSite}
          highLightedSerieName={isSite ? renewableSeries?.find(s => s.name !== 'Other locations')?.name : undefined}
          yAxisTitle="Renewable energy %"
        />
      </ExploreGraphCard>
    </>
  )
}

const RefrigerantsGraphs = ({
  footprint,
  dates,
  timeRange,
  locationId,
  isSite,
  refrigerantFactors
}: RefrigerantsGraphProps) => {
  const refrigerantsColors = createColorScheme(refrigerantFactors || {})
  const [byContributorUnit, setByContributorUnit] = React.useState(GraphUnit.ConvertedUnits)
  const [byLocationUnit] = React.useState(GraphUnit.ConvertedUnits)

  const isGlobalSelector = locationId === 'ALL'
  const locationGroupingKey = isGlobalSelector ? 'countryName' : 'siteId'

  const commonChartProps = {
    generator: lineChart,
    domain: dates,
    dateFormat: timeRange === 'monthly' ? 'monthWithYear' : 'fy'
  } as const

  const { series, totals } = React.useMemo(() => {
    const locationFootprint = footprint && footprint.filter(f => (isSite ? f.siteId === locationId : true))

    let byContributor = createSeriesWithKey({
      groupingKey: 'footprintContributor',
      data: locationFootprint,
      dates,
      timeRange,
      colors: refrigerantsColors,
      dataReduceFn:
        byContributorUnit === GraphUnit.MassKg ? sumByUnit(byContributorUnit) : sumByUnit(byContributorUnit),
      unit: byContributorUnit === GraphUnit.RelativeRawUnits ? '%' : '',
      withDynamicFormatting: true
    })

    if (['raw', 'relativeraw'].includes(byContributorUnit))
      byContributor = byContributor?.filter(({ name }) => name !== 'Refrigerants')

    const byLocation = createSeriesByLocation(
      footprint,
      dates,
      timeRange,
      locationId,
      isSite,
      sumByUnit(GraphUnit.ConvertedUnits),
      undefined,
      true
    )
    return {
      hasRelative: hasRelativeDataAvailable(footprint),
      series: { byContributor, byLocation },
      totals: {
        byContributor: getTotal(locationFootprint, byContributorUnit),
        byLocation: getTotal(
          isSite ? locationFootprint : footprint,
          byLocationUnit,
          locationGroupingKey,
          byLocationUnit !== GraphUnit.ConvertedUnits ? _.mean : scaledMean,
          'avg.'
        )
      }
    }
  }, [JSON.stringify(footprint), JSON.stringify(dates), timeRange, locationId, byContributorUnit, byLocationUnit])

  const customOrdering = (series: Series<number, string>[]): number[] => {
    return series
      .sort(
        (first, second) =>
          (refrigerantFactors ? refrigerantFactors[first.key] : 0) -
          (refrigerantFactors ? refrigerantFactors[second.key] : 0)
      )
      .map((_, index) => index)
  }

  return (
    <>
      <ExploreGraphCard
        heading="Refrigerants leakage by type"
        description="This visualization shows refrigerants in different ways. You can change in bottom right corner between showing mass of refrigirants in kilograms or as the equivalent amount of Carbon Dioxide (CO2e) released into the atmosphere by refrigirants used in this location’s operation."
        {...totals.byContributor}
      >
        <ChartContainer
          testId="refrigerants-type-chart"
          generator={stackedBarChart('descending', customOrdering)}
          series={series.byContributor}
          domain={dates}
          dateFormat={timeRange === 'monthly' ? 'monthWithYear' : 'fy'}
          CustomTooltip={RefrigerantsTooltip}
          additionalComponents={
            <UnitSelector
              enabled
              options={[GraphUnit.ConvertedUnits, GraphUnit.MassKg]}
              onChange={setByContributorUnit}
              value={byContributorUnit}
              menuPlacement="top"
            />
          }
          tooltipSummary={date => {
            const total = _(series.byContributor)
              .flatMap(vals =>
                _(vals.data)
                  .filter(d => isSameDay(d.x, date))
                  .value()
              )
              .sumBy('y')
            const rounded = formatNumberValue(total, true, byContributorUnit)
            return [
              {
                title: 'Total',
                value: rounded,
                icon: <SumIndicator />,
                unit: getYAxisText(byContributorUnit)
              }
            ]
          }}
          yAxisTitle={getYAxisText(byContributorUnit)}
          withDynamicFormatting
        />
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Refrigerants consumption by location"
        description="This visualization shows the amount and source of refrigerants used by each location."
        {...totals.byLocation}
      >
        <ChartContainer
          {...commonChartProps}
          testId="refrigerants-location-chart"
          series={series.byLocation}
          lineChartConfiguration={{ focusStyle: isSite ? 'top' : 'nearest', startFromZero: false }}
          disableLegendItems={isSite}
          showDeselectAll={!isSite}
          highLightedSerieName={isSite ? series.byLocation?.find(s => s.name !== 'Other locations')?.name : undefined}
          yAxisTitle={getYAxisText(GraphUnit.ConvertedUnits)}
          isBoldGraphLine
        />
      </ExploreGraphCard>
    </>
  )
}

const WaterGraphs = ({ footprint, dates, timeRange, locationId, isSite }: GraphProps) => {
  const [byTypeUnit, setByTypeUnit] = React.useState(GraphUnit.RawWater)

  const isGlobalSelector = locationId === 'ALL'
  const locationGroupingKey = isGlobalSelector ? 'countryName' : 'siteId'

  const commonChartProps = {
    generator: lineChart,
    domain: dates,
    dateFormat: timeRange === 'monthly' ? 'monthWithYear' : 'fy'
  } as const

  const { series, totals } = React.useMemo(() => {
    const waterForLocation = footprint ? footprint.filter(f => (isSite ? f.siteId === locationId : true)) : undefined
    const byType = createSeriesWithKey({
      groupingKey: 'treatmentType',
      data: waterForLocation,
      dates,
      timeRange,
      colors: waterColorMap,
      dataReduceFn: sumByUnit(byTypeUnit)
    })
    const byLocation = createSeriesByLocation(
      footprint,
      dates,
      timeRange,
      locationId,
      isSite,
      sumByUnit(GraphUnit.RawWater),
      undefined,
      true
    )
    return {
      series: { byType, byLocation },
      totals: {
        byType: getTotal(waterForLocation, byTypeUnit),
        byLocation: getTotal(waterForLocation, GraphUnit.RawWater, locationGroupingKey, _.mean, 'avg.')
      }
    }
  }, [JSON.stringify(footprint), JSON.stringify(dates), byTypeUnit])

  return (
    <>
      <ExploreGraphCard
        heading="Water consumption by type"
        description="This visualization shows the amount and source of water used by this location."
        {...totals.byType}
      >
        <WasteByTypeGraph
          series={series.byType}
          unit={byTypeUnit}
          onUnitChange={setByTypeUnit}
          unitTypes={[GraphUnit.RawWater, GraphUnit.ConvertedUnits]}
          {...commonChartProps}
        />
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Water consumption by location"
        description="This visualization shows the amount and source of water used by each location."
        {...totals.byLocation}
      >
        <ChartContainer
          {...commonChartProps}
          testId="water-location-chart"
          series={series.byLocation}
          lineChartConfiguration={{ focusStyle: isSite ? 'top' : 'nearest', startFromZero: false }}
          disableLegendItems={isSite}
          showDeselectAll={!isSite}
          highLightedSerieName={isSite ? series.byLocation?.find(s => s.name !== 'Other locations')?.name : undefined}
          yAxisTitle={getYAxisText(GraphUnit.RawWater)}
        />
      </ExploreGraphCard>
    </>
  )
}

interface WasteByTypeGraphProps {
  dateFormat: DateFormat
  domain: Date[] | undefined
  series: Serie[] | undefined
  unit: GraphUnit
  unitDescription?: string
  unitTypes?: GraphUnit[]
  onUnitChange: (unit: GraphUnit) => void
}

export const WasteByTypeGraph: React.FC<WasteByTypeGraphProps> = ({
  dateFormat,
  domain,
  series,
  unit,
  unitDescription,
  onUnitChange,
  unitTypes
}) => {
  return (
    <ChartContainer
      testId="water-type-chart"
      dateFormat={dateFormat}
      domain={domain}
      generator={stackedBarChart('descending')}
      series={series}
      yAxisTitle={unitDescription ?? getYAxisText(unit)}
      additionalComponents={
        <UnitSelector
          enabled
          options={unitTypes || [GraphUnit.RawWaste, GraphUnit.ConvertedUnits]}
          value={unit}
          onChange={onUnitChange}
          menuPlacement="top"
        />
      }
      showDeselectAll
      tooltipSummary={date => {
        const total = _(series)
          .flatMap(vals =>
            _(vals.data)
              .filter(d => isSameDay(d.x, date))
              .value()
          )
          .sumBy('y')
        return [
          {
            title: 'Total',
            value: Number.isNaN(total) ? 'N/A' : formatNumberValue(total, true, ''),
            icon: <SumIndicator />,
            unit: unitDescription ?? getYAxisText(unit)
          }
        ]
      }}
      withDynamicFormatting
    />
  )
}

interface CreateSeriesParams<D> {
  data: D[]
  domain: Date[]
  interval: TimeRange
  groupingKey: keyof D
  dataReduceFn: (slice: D[]) => number | null
  getSerieName: (key: string, slice: D[]) => string
  getSerieColor: (key: string, slice: D) => string
  unit?: string
  withDynamicFormatting?: boolean
  getSerieId?: (key: string, slice: D[]) => string
}

const createSeries = <D extends { readableDate: string; fiscalYear: number }>({
  data,
  domain,
  interval,
  groupingKey,
  dataReduceFn,
  getSerieName,
  getSerieColor,
  unit,
  withDynamicFormatting,
  getSerieId
}: CreateSeriesParams<D>): Serie[] => {
  const isRecordForDate = <D extends { readableDate: string; fiscalYear: number }>(
    record: D,
    date: Date,
    interval: TimeRange
  ) =>
    interval === 'monthly'
      ? isSameDay(date, new Date(record.readableDate))
      : date.getFullYear() === record.fiscalYear + 1999

  const series = _(data)
    .groupBy(groupingKey)
    .entries()
    .map(([key, slice]) => ({
      name: getSerieName(key, slice),
      color: getSerieColor(key, slice[0]),
      id: getSerieId ? getSerieId(key, slice) : key,
      data: _(domain)
        .map(date => [slice.filter(record => isRecordForDate(record, date, interval)), date] as const)
        .map(([slice, date]) => [dataReduceFn(slice), date] as const)
        .map(([value, date]) => ({ y: value, x: date }))
        .filter((datapoint): datapoint is DataPoint => !_.isNull(datapoint.y) && _.isFinite(datapoint.y))
        .value(),
      unit: unit
    }))
    .value()

  if (!withDynamicFormatting) return series

  const isMoreThenHundred = Math.max(...series.map(serie => Math.max(...serie.data.map(item => item.y)))) > 100

  return series.map(serie => {
    return {
      ...serie,
      data: serie.data.map(item => ({ ...item, y: isMoreThenHundred ? Math.round(item.y) : item.y }))
    }
  })
}

interface SeriesConfig<K extends keyof Omit<ApiTotalFootprint, 'selectable'>> {
  groupingKey: K
  data: ApiTotalFootprint[] | undefined
  dates: Date[] | undefined
  timeRange: TimeRange
  colors: Record<Required<Omit<ApiTotalFootprint, 'selectable'>>[K], string>
  dataReduceFn: (dataForDate: ApiTotalFootprint[]) => number | null
  unit?: string
  withDynamicFormatting?: boolean
  customNameFunc?: (groupKey: string, slice: Array<ApiTotalFootprint>) => string
  customIdFunc?: (groupKey: string, slice: Array<ApiTotalFootprint>) => string
  colorKey?: K
}

export const createSeriesWithKey = <K extends keyof Omit<ApiTotalFootprint, 'selectable'>>(config: SeriesConfig<K>) => {
  const {
    groupingKey,
    data,
    dates,
    timeRange,
    colors,
    dataReduceFn,
    unit,
    withDynamicFormatting,
    customNameFunc,
    customIdFunc,
    colorKey
  } = config

  if (data === undefined || dates === undefined) return undefined

  const getSerieName =
    customNameFunc ||
    function (key) {
      return key
    }

  const getSerieId =
    customIdFunc ||
    function (key) {
      return key
    }

  return createSeries({
    data,
    domain: dates,
    interval: timeRange,
    groupingKey,
    dataReduceFn,
    getSerieName,
    getSerieColor: (key, slice) => {
      const colorId = colorKey ? slice[colorKey] : key
      return colors[colorId as Required<ApiTotalFootprint>[K]]
    },
    unit,
    withDynamicFormatting,
    getSerieId
  })
}

export const createSeriesByLocation = (
  data: ApiTotalFootprint[] | undefined,
  dates: Date[] | undefined,
  timeRange: TimeRange,
  selectedLocationId: string,
  isSite: boolean,
  dataReduceFn: (dataForDate: ApiTotalFootprint[]) => number | null,
  byLocationUnit?: string,
  withDynamicFormatting?: boolean
): Serie[] | undefined => {
  if (data === undefined || dates === undefined) return undefined

  const wildcardLabel = 'Other locations'
  const isGlobalSelector = selectedLocationId === 'ALL'
  const groupingKey = isGlobalSelector ? 'countryName' : 'siteId'
  const theme = colorScheme(_.uniq(data.map(d => d[groupingKey])))

  const getColorByLocationId = isSite
    ? (locationId: string) => (locationId === selectedLocationId ? colours.purple2 : colours.lightBlue4)
    : (locationId: string) => theme[locationId]

  const getSerieName = (_: string, data: ApiTotalFootprint[]) => {
    const locationId = data[0].siteId
    const fullName = data[0][isGlobalSelector ? 'countryName' : 'siteName']
    const shortName = fullName?.split(' ')[0] ?? ''
    if (isSite) {
      return locationId === selectedLocationId ? shortName : wildcardLabel
    } else {
      return isGlobalSelector ? fullName : shortName
    }
  }

  const unit = byLocationUnit === 'relative' || byLocationUnit === 'relativeraw' ? '%' : undefined

  return createSeries({
    data,
    domain: dates,
    interval: timeRange,
    groupingKey,
    dataReduceFn,
    getSerieName,
    getSerieColor: getColorByLocationId,
    unit,
    withDynamicFormatting
  }).sort((a, b) => {
    if (a.name === wildcardLabel) return -1
    if (b.name === wildcardLabel) return +1
    return 0
  })
}

function createRenewableEnergySeriesByLocation(
  data: RenewableEnergyShare[] | undefined,
  dates: Date[] | undefined,
  timeRange: TimeRange,
  selectedLocation: string,
  isSite: boolean
) {
  if (data === undefined || dates === undefined) return undefined

  const wildcardLabel = 'Other locations'
  const isGlobalSelector = selectedLocation === 'ALL'
  const theme = colorScheme(_.uniq(data.map(d => d['label'])))

  const dataReduceFn = (slice: RenewableEnergyShare[]) => {
    if (slice.length === 0) return null
    return 100 * (_.sumBy(slice, 'renewableShare') / slice.length)
  }

  const getColorByLocationId = isSite
    ? (location: string) => (location === selectedLocation ? colours.purple2 : colours.lightBlue4)
    : (location: string) => theme[location]

  const getSerieName = isSite
    ? (location: string) => (location === selectedLocation ? location.split(' ')[0] : wildcardLabel)
    : (location: string) => (isGlobalSelector ? location : location.split(' ')[0])

  return createSeries({
    data,
    domain: dates,
    interval: timeRange,
    groupingKey: 'label',
    dataReduceFn,
    getSerieName,
    getSerieColor: getColorByLocationId
  }).sort((a, b) => {
    if (a.name === wildcardLabel) return -1
    if (b.name === wildcardLabel) return +1
    return 0
  })
}

function getDataField(graphUnit: GraphUnit) {
  switch (graphUnit) {
    case GraphUnit.ConvertedUnits:
      return 'footprint'
    case GraphUnit.RelativeUnits:
      return 'footprintPerArea'
    case GraphUnit.RawUnits:
    case GraphUnit.RawWaste:
    case GraphUnit.RawWasteKg:
    case GraphUnit.RawWater:
    case GraphUnit.MassKg:
      return 'quantity'
    default:
      return 'quantityPerArea'
  }
}

function getTotal(
  footprint: ApiTotalFootprint[] | undefined,
  unit: GraphUnit,
  groupingKey?: keyof ApiTotalFootprint,
  reduceFn?: (data: number[]) => number,
  relativity?: 'total' | 'avg.'
): { amount: string; unit: string } {
  if (!footprint) return { amount: '', unit: '' }

  const field = getDataField(unit)
  const data = _(footprint)
    .groupBy(record => (groupingKey ? `${record[groupingKey]}/${record.readableDate}` : record.readableDate))
    .values()
    .map(groupedRecords => _.sumBy(groupedRecords, field))

  const reduced = reduceFn?.(data.value())
  const byAverage = data.mean()
  const bySum = data.sum()

  const fmtNumber = (value: number): string => {
    return formatNumberValue(value, true, unit).toString()
  }

  switch (unit) {
    case GraphUnit.ConvertedUnits:
      return { amount: fmtNumber(reduced ?? bySum / 1000), unit: `tonnes CO2e ${relativity ?? 'total'}` }
    case GraphUnit.RelativeUnits:
      return { amount: formatRelativeNumber(reduced ?? byAverage), unit: `kg CO2e / m2 ${relativity ?? 'avg.'}` }
    case GraphUnit.RawWater:
      return { amount: fmtNumber(reduced ?? bySum), unit: `litres ${relativity ?? 'total'}` }
    case GraphUnit.RawWaste:
      return { amount: fmtNumber(reduced ?? bySum), unit: `tonnes ${relativity ?? 'total'}` }
    case GraphUnit.RawWasteKg:
      return { amount: fmtNumber(reduced ?? bySum), unit: `kg ${relativity ?? 'total'}` }
    case GraphUnit.MassKg:
      return { amount: fmtNumber(reduced ?? bySum), unit: `kg ${relativity ?? 'total'}` }
    case GraphUnit.RawUnits:
      return { amount: fmtNumber(reduced ?? bySum), unit: `kWh ${relativity ?? 'total'}` }
    case GraphUnit.RecycledWaste:
    case GraphUnit.Landfill:
      return { amount: formatPercentage(reduced ?? byAverage), unit: `% avg.` }
    case GraphUnit.RelativeRawUnits:
      return { amount: '', unit: '' }
  }
}

export function sumByUnit(graphUnit: GraphUnit) {
  return (data: ApiTotalFootprint[]) => {
    if (data.length === 0) return null

    const field = getDataField(graphUnit)
    const total = _.sumBy(data, field)

    return graphUnit !== GraphUnit.ConvertedUnits ? total : total / 1000
  }
}

function byUnitAndTotalArea(
  graphUnit: GraphUnit,
  timeRange: TimeRange,
  totalAreasByReadableDate: _.Dictionary<number>
) {
  const byArea = (data: ApiTotalFootprint[]) => {
    const sum = _.sumBy(data, 'quantity')
    return sum / totalAreasByReadableDate[data[0].readableDate]
  }

  return (data: ApiTotalFootprint[]) => {
    if (data.length === 0) return null

    if (timeRange === 'annual') {
      return _.sum(Object.values(_.groupBy(data, 'readableDate')).map(byArea))
    }
    return byArea(data)
  }
}

function hasRelativeDataAvailable(footprint: ApiTotalFootprint[] | undefined) {
  if (footprint === undefined) {
    return false
  }
  return _.some(footprint, f => f.footprintPerArea != null || f.quantityPerArea != null)
}

function sortAndReplace(obj: { byContributor: Serie[] | undefined; byLocation: Serie[] | undefined }) {
  const arr = obj.byContributor

  function customSort(a: { name: string; id?: string }, b: { name: string; id?: string }) {
    const order: { [key: string]: number } = {
      'upstream-emissions-grid-loss-electricity': 1,
      'upstream-emissions-fuel-production': 2,
      'district-heating-cooling-building': 3
    }
    return ((a.id && order[a.id]) || 4) - ((b.id && order[b.id]) || 4)
  }

  arr && arr.sort(customSort)

  return { ...obj, byContributor: arr }
}
