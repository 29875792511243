import React from 'react'
import { isAfter } from 'date-fns'

import { AsIsSales, WeeklySalesData } from '../../../../api/src/common-types'
import '../../KPIPages/KPIPage.scss'
import colours from '../../../Colours.module.scss'
import { TopBar } from '../../../components/TopBar'
import { PageHeader } from '../../../components/PageHeader'
import { fyBetween, useFilters } from '../ExplorePage'
import { getAsIsSales, getWeeklySales } from '../../../lib/APIClient'
import { ChartContainer, DataPoint, isDataPoint } from '../../../components/BaseGraphs/ChartContainer'
import { barChart, lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { ExploreGraphCard } from '../ExploreGraphCard'
import { getCurrencyCode, getCurrencySymbol, getLocationOrDefault } from '../../../components/Utils/utils'
import { formatAbsoluteNumber } from '../../../components/Utils/format'
import { sumBy } from 'lodash'
import { PPPAndTotalSalesGraph } from '../../KPIPages/PppSales/PppSalesKPIPage'
import { useLocations } from '../../../context'
import { Route } from '../../../routes'
import { useGetDataAvailability } from '../../../services/general/service'

const kpis = ['ppp', 'buyback'] as const
const kpiLabels = {
  ppp: 'People + Planet Positive Sales',
  buyback: 'Buy Back & Resell'
}

export const PppSalesExplorePage = () => {
  const { data: dataAvailability } = useGetDataAvailability()
  const { currentLocation } = useLocations()
  const [allBuyback, setAllBuyback] = React.useState<AsIsSales[]>()
  const [allPppSales, setAllPppSales] = React.useState<WeeklySalesData[]>()
  const [allDates, setAllDates] = React.useState<string[]>()
  const [selectedBuyback, setSelectedBuyback] = React.useState<AsIsSales[]>()
  const [selectedPppSales, setSelectedPppSales] = React.useState<WeeklySalesData[]>()
  const [selectedDates, setSelectedDates] = React.useState<Date[]>()
  const [{ kpi, rangeFrom, rangeTo }, filterSelectors] = useFilters(
    kpis,
    kpiLabels,
    dataAvailability?.profitCurrentFY ?? 2000,
    false
  )

  const locationId = getLocationOrDefault()
  const currencyCode = getCurrencyCode(currentLocation)

  const filterData = (
    rangeFrom: string,
    rangeTo: string,
    buyback?: AsIsSales[],
    pppSales?: WeeklySalesData[],
    dates?: string[]
  ) => {
    const rangeFromFy = parseInt(rangeFrom) - 2000
    const rangeToFy = parseInt(rangeTo) - 2000

    setSelectedBuyback(buyback?.filter(fyBetween(rangeFromFy, rangeToFy)))
    setSelectedPppSales(pppSales?.filter(fyBetween(rangeFromFy, rangeToFy)))
    setSelectedDates(
      dates
        ?.filter(date => date >= `${parseInt(rangeFrom) - 1}-09-01` && date <= `${parseInt(rangeTo)}-08-31`)
        .map(d => new Date(d))
    )
  }

  React.useEffect(() => {
    const loadData = async () => {
      const [{ data: buyback, dates }, ppp] = await Promise.all([
        getAsIsSales(locationId, 2016, dataAvailability?.profitCurrentFY ?? 2000),
        getWeeklySales(locationId, 2016, dataAvailability?.profitCurrentFY ?? 2000, 'all')
      ])

      const pppSales = ppp.filter(d => d.lastUpdated != null)

      setAllBuyback(buyback)
      setAllPppSales(pppSales)
      setAllDates(dates)
    }

    setAllBuyback(undefined)
    setAllPppSales(undefined)
    setSelectedBuyback(undefined)
    setSelectedPppSales(undefined)
    setAllDates(undefined)
    loadData()
  }, [locationId, dataAvailability?.profitCurrentFY])

  React.useEffect(() => {
    filterData(rangeFrom, rangeTo, allBuyback, allPppSales, allDates)
  }, [rangeFrom, rangeTo, JSON.stringify(allBuyback), JSON.stringify(allPppSales), JSON.stringify(allDates)])

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.PppSalesExplorePage} useInFlexLayout />
      <PageHeader className="PppSalesHeader" route={Route.PppSalesExplorePage}></PageHeader>
      <div className="PageContent">
        {filterSelectors}
        {kpi === 'buyback' ? (
          <BuybackGraphs currencyCode={currencyCode} data={selectedBuyback} dates={selectedDates} />
        ) : (
          <PppSalesGraphs
            locationId={locationId}
            currencyCode={currencyCode}
            data={selectedPppSales}
            dates={selectedDates}
          />
        )}
      </div>
    </div>
  )
}

interface BuybackGraphsProps {
  currencyCode?: string
  data?: AsIsSales[]
  dates?: Date[]
}

const BuybackGraphs: React.FC<BuybackGraphsProps> = ({ currencyCode, data, dates }) => {
  const buybackSeries = getAsisSalesSeries(data)
  const indexSeries = getAsisIndexSeries(data)

  const totalBuyback = data ? formatAbsoluteNumber(Math.round(sumBy(data, 'rbbSalesLocal'))) : 'N/A'

  return (
    <>
      <ExploreGraphCard heading="Buy Back &amp; Resell" amount={totalBuyback} unit={currencyCode}>
        <ChartContainer
          generator={barChart}
          domain={dates}
          series={buybackSeries}
          dateFormat="week"
          yAxisTitle={getCurrencySymbol(currencyCode)}
        />
      </ExploreGraphCard>
      <ExploreGraphCard heading="Buy Back &amp; Resell Index">
        <ChartContainer
          generator={lineChart}
          domain={dates}
          series={indexSeries}
          lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
          maxValue={500}
          dateFormat="week"
        />
      </ExploreGraphCard>
    </>
  )
}

interface PppSalesGraphsProps {
  locationId: string
  currencyCode?: string
  data?: WeeklySalesData[]
  dates?: Date[]
}

const PppSalesGraphs: React.FC<PppSalesGraphsProps> = ({ locationId, currencyCode, data, dates }) => {
  const indexSeries = data
    ? [
        {
          name: 'Weekly Index',
          color: colours.lightPink4,
          data: data
            .map(d => ({
              x: new Date(d.readableDate),
              y: d.weeklyIndex ? d.weeklyIndex * 100 : undefined
            }))
            .filter(isDataPoint)
        },
        {
          name: 'YTD Index',
          color: colours.salmon1,
          data: data
            .map(d => ({
              x: new Date(d.readableDate),
              y: d.ytdIndex ? d.ytdIndex * 100 : undefined
            }))
            .filter(isDataPoint)
        }
      ]
    : undefined

  const asisKey = locationId === 'ALL' ? 'asisSales' : 'asisSalesLocal'
  const pppKey = locationId === 'ALL' ? 'pppSales' : 'pppSalesLocal'
  const totalPppSales = data
    ? formatAbsoluteNumber(Math.round(data.reduce((acc, curr) => acc + curr[asisKey] + curr[pppKey], 0)))
    : 'N/A'

  const currency = locationId === 'ALL' ? '' : 'Local'

  return (
    <>
      <ExploreGraphCard
        heading="People + Planet Positive Sales vs. Other Sales"
        description="Other Sales = Total Sales - People and Planet Positive Sales (Incl. As-Is Sales)"
        amount={totalPppSales}
        unit={`${currencyCode} P+PP Sales`}
      >
        <PPPAndTotalSalesGraph
          weeklySales={data?.map(d => ({
            readableDate: d.readableDate,
            week: d.week,
            pppSales: d[`asisSales${currency}`] + d[`pppSales${currency}`],
            totalSales: d[`totalSales${currency}`]
          }))}
          currencyCode={currencyCode}
          isSales
        />
      </ExploreGraphCard>
      <ExploreGraphCard heading="People + Planet Positive Sales Index">
        <ChartContainer
          generator={lineChart}
          domain={dates}
          series={indexSeries}
          lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
          dateFormat="week"
        />
      </ExploreGraphCard>
    </>
  )
}

const getAsisSalesSeries = (data?: AsIsSales[]) =>
  data
    ? [
        {
          name: 'Buy Back & Resell',
          color: colours.salmon1,
          data: data.map(d => ({ x: new Date(d.readableDate), y: d.rbbSalesLocal }))
        }
      ]
    : undefined

const calculateIndex = (date: string, curr: number, prev: number) => {
  // data before FY21 is not reliable, thus indices are available only from FY22 onwards
  const startOfFy22 = new Date('2021-09-01')
  if (prev === 0 || isAfter(startOfFy22, new Date(date))) {
    return undefined
  }
  if (prev > 0) {
    return (curr / prev) * 100
  }
  return (curr + Math.abs(prev)) / Math.abs(prev)
}

const getAsisIndexSeries = (data?: AsIsSales[], filter?: (x: DataPoint) => boolean) =>
  data
    ? [
        {
          name: 'Weekly Index',
          color: colours.salmon1,
          data: data
            .map(d => ({
              x: new Date(d.readableDate),
              y: calculateIndex(d.readableDate, d.rbbSalesLocal, d.rbbSalesLocalPrevYear)
            }))
            .filter(isDataPoint)
            .filter(filter ? filter : () => true)
        },
        {
          name: 'YTD Index',
          color: colours.salmon,
          data: data
            .map(d => ({
              x: new Date(d.readableDate),
              y: calculateIndex(d.readableDate, d.rbbSalesLocalCum, d.rbbSalesLocalCumPrevYear)
            }))
            .filter(isDataPoint)
            .filter(filter ? filter : () => true)
        }
      ]
    : undefined
