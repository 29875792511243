import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import {
  getDataAvailability,
  getGoals,
  getNotificationsLastSeen,
  getUserInformation,
  saveNotificationsLastSeen
} from '../../lib/APIClient'
import { isInStorePage } from '../../routes/Utils'
import { useLocations, useLocationsFilter } from '../../context'
import { useSharedSelections } from '../../SharedSelections'
import { GoalsResponse } from '../../../api/src/common-types'

const serviceName = 'general'

export function useGetDataAvailability() {
  return useQuery({
    queryKey: [serviceName, 'data-availability'],
    queryFn: getDataAvailability
  })
}

export function useGetUserInformation() {
  const { pathname } = useLocation()
  return useQuery({
    queryKey: [serviceName, 'user-information'],
    queryFn: getUserInformation,
    staleTime: Infinity,
    enabled: !isInStorePage(pathname)
  })
}

export function useNotificationsState() {
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const queryKey = [serviceName, 'notifications-last-seen']

  const notificationsLastSeenQuery = useQuery({
    queryKey,
    queryFn: getNotificationsLastSeen,
    staleTime: Infinity,
    enabled: !isInStorePage(pathname)
  })
  const markNotificationsSeen = () => {
    queryClient.setQueryData(queryKey, () => ({ lastSeen: format(new Date(), 'yyyy-MM-dd') }))
    saveNotificationsLastSeen()
  }

  return {
    lastSeen: new Date(notificationsLastSeenQuery.data?.lastSeen ?? '2036-01-01'), // do not show new notifications by default
    markAsSeen: markNotificationsSeen
  }
}

export const useGetGoals = <TSelected = GoalsResponse>(
  financialYear: number,
  options?: UseQueryOptions<GoalsResponse, unknown, TSelected>
): UseQueryResult<TSelected> => {
  const { locations } = useLocations()
  const { countryCode } = useLocationsFilter()
  const [{ func }] = useSharedSelections()

  return useQuery<GoalsResponse, unknown, TSelected>({
    enabled: !!locations.length,
    queryKey: [serviceName, 'goals', countryCode, func, financialYear],
    select: options?.select,
    queryFn: async () => {
      if (!countryCode) {
        return {
          countries: [],
          sites: []
        }
      }
      return getGoals(countryCode, func, financialYear)
    }
  })
}
