import React from 'react'
import classNames from 'classnames'
import qs from 'qs'
import { useLocation } from 'react-router-dom'

import { FootprintInsightSlide } from '../../components/InStore/ClimateFootprintLargestContributorSlide'
import { EnergyEfficiencySlide } from '../../components/InStore/EnergyEfficiencySlide'
import { HfbSlide } from '../../components/InStore/HfbSlide'
import { IntroSlide } from '../../components/InStore/IntroSlide'
import { PppSalesSlide } from '../../components/InStore/PppSalesSlide'
import { SlideContainer } from '../../components/InStore/SlideContainer'
import { TotalClimateFootprintSlide } from '../../components/InStore/ClimateFootprintYtdSlide'
import { WasteRecyclingRateSlide } from '../../components/InStore/WasteRecyclingRateSlide'
import { WasteTreatmentByLocationSlide } from '../../components/InStore/WasteTreatmentSlide'
import { LoadingSkeleton } from '../../components/LoadingSkeleton'
import { PageNotFound } from '../../components/PageNotFound'
import { getLocationId, getLocationOrDefault, getLocationSelector, toSiteFunction } from '../../components/Utils/utils'
import { isSite, WasteSelector, SelectorWithCountryCodes, SelectorWithFunctions } from '../../lib/APIClient'

import './InStorePage.scss'
import { parseLanguageCode } from '../../Localisation'
import { LanguageContext, useLocations, InStoreContext } from '../../context'
import { analyticsEvent, pageView } from '../../components/Utils/analytics'
import { WaterEfficiencySlide } from '../../components/InStore/WaterEfficiencySlide'
import { useGetDataAvailability } from '../../services/general/service'

export type Slide =
  | 'climatefootprintinsight'
  | 'climatefootprintytd'
  | 'energyefficiency'
  | 'hfbbottom'
  | 'hfbtop'
  | 'intro'
  | 'pppsales'
  | 'wasterecycling'
  | 'wastetreatment'
  | 'waterefficiency'

type TransitionState = 'entering' | 'in' | 'exiting'

const slideVisibleInMs = 30 * 1000

export const InStorePage = () => {
  const { currentLocation } = useLocations()
  const { data: dataAvailability } = useGetDataAvailability()
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [transitionState, setTransitionState] = React.useState<TransitionState>('in')
  const [hasError, setError] = React.useState(false)
  const siteOrCountry = getLocationOrDefault()
  const { search } = useLocation()
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true })
  const slides = (queryParams['slides'] as Slide[]).filter(
    slide => siteOrCountry !== 'ALL' || slide !== 'climatefootprintinsight'
  )
  const language = parseLanguageCode(queryParams['lang'] as string)
  React.useEffect(() => {
    const token = queryParams['token']
    const key = queryParams['key']
    if (!token || !key) {
      setError(true)
    }
  }, [])

  React.useEffect(() => {
    const oneWeekInMs = 604800000
    setTimeout(() => window.location.reload(), oneWeekInMs)
  }, [])

  const location = getLocationSelector(siteOrCountry)

  const locationId = getLocationId(currentLocation)

  // Clusters are not currently supported in instore displays, thus casting an empty object
  const selector: SelectorWithFunctions = currentLocation.isCluster
    ? ({} as SelectorWithFunctions)
    : {
        locationId: locationId,
        func: isSite(currentLocation.location) ? toSiteFunction(currentLocation.location.functionCode) : ['ALL']
      }

  const selector2: SelectorWithCountryCodes = currentLocation.isCluster
    ? ({} as SelectorWithCountryCodes)
    : {
        locationId: isSite(currentLocation.location)
          ? currentLocation.location.siteId
          : currentLocation.location.countryCode,
        site: location.site,
        countryCodes: location.countryCodes,
        func: isSite(currentLocation.location) ? toSiteFunction(currentLocation.location.functionCode) : ['ALL'],
        isOld: true
      }

  const wasteSelector: WasteSelector = currentLocation.isCluster
    ? ({} as WasteSelector)
    : {
        locationId: isSite(currentLocation.location)
          ? currentLocation.location.siteId
          : currentLocation.location.countryCode,
        func: isSite(currentLocation.location) ? toSiteFunction(currentLocation.location.functionCode) : ['ALL'],
        timeRange: 'monthly'
      }

  React.useEffect(() => {
    if (slides == null || slides.length <= 1) {
      setTransitionState('in')
      setCurrentIndex(0)
      return
    }
    const timeout = transitionState === 'in' ? slideVisibleInMs : 500
    const timerId = setTimeout(() => {
      switch (transitionState) {
        case 'entering':
          setCurrentIndex(currentIndex < slides.length - 1 ? currentIndex + 1 : 0)
          setTransitionState('in')
          break
        case 'in':
          setTransitionState('exiting')
          break
        case 'exiting':
          setTransitionState('entering')
          break
      }
    }, timeout)
    return () => clearTimeout(timerId)
  }, [transitionState])

  React.useEffect(() => {
    // If there is only a single screen selected,
    // send a pageview event periodically to keep pageview numbers comparable
    let intervalId: ReturnType<typeof setInterval> | null = null
    if (slides.length === 1) {
      intervalId = setInterval(() => {
        pageView({ title: slides[currentIndex] })
      }, slideVisibleInMs)
    }
    return () => {
      if (intervalId) {
        window.clearInterval(intervalId)
      }
    }
  }, [])

  React.useEffect(() => {
    analyticsEvent({
      category: 'Language',
      action: 'select',
      // Intl.DisplayNames is not yet in the standard, so TypeScript doesn't know about it.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      label: (Intl as any).DisplayNames
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          new (Intl as any).DisplayNames(['en'], { type: 'language' }).of(language)
        : language
    })
  }, [])

  React.useEffect(() => {
    pageView({ title: slides[currentIndex] })
  }, [currentIndex])

  return hasError || currentLocation.isCluster ? (
    <PageNotFound />
  ) : (
    <InStoreContext.Provider value={true}>
      <LanguageContext.Provider value={language}>
        <div className="InstorePage">
          {!currentLocation || !dataAvailability ? (
            <LoadingSkeleton />
          ) : slides != null && slides.length !== 0 ? (
            <div className={classNames('AnimatedContainer', transitionState)}>
              <SlideContainer name="climatefootprintinsight" currentSlide={slides[currentIndex]} slides={slides}>
                <FootprintInsightSlide
                  currentLocation={currentLocation.location}
                  selector={{
                    ...selector,
                    start_fy: dataAvailability.planetPreviousFY,
                    end_fy: dataAvailability.planetCurrentFY
                  }}
                />
              </SlideContainer>
              <SlideContainer name="hfbtop" currentSlide={slides[currentIndex]} slides={slides}>
                <HfbSlide
                  currentLocation={currentLocation.location}
                  selector={{
                    ...selector,
                    start_fy: dataAvailability.profitPreviousFY,
                    end_fy: dataAvailability.profitCurrentFY
                  }}
                  siteOrCountry={siteOrCountry}
                  type="top"
                />
              </SlideContainer>
              <SlideContainer name="hfbbottom" currentSlide={slides[currentIndex]} slides={slides}>
                <HfbSlide
                  currentLocation={currentLocation.location}
                  selector={{
                    ...selector,
                    start_fy: dataAvailability.profitPreviousFY,
                    end_fy: dataAvailability.profitCurrentFY
                  }}
                  siteOrCountry={siteOrCountry}
                  type="bottom"
                />
              </SlideContainer>
              <SlideContainer name="intro" currentSlide={slides[currentIndex]} slides={slides}>
                <IntroSlide siteOrCountry={siteOrCountry} />
              </SlideContainer>
              <SlideContainer name="pppsales" currentSlide={slides[currentIndex]} slides={slides}>
                <PppSalesSlide
                  currentLocation={currentLocation.location}
                  selector={{
                    ...selector,
                    start_fy: dataAvailability.profitPreviousFY,
                    end_fy: dataAvailability.profitCurrentFY
                  }}
                  siteOrCountry={siteOrCountry}
                />
              </SlideContainer>
              <SlideContainer name="climatefootprintytd" currentSlide={slides[currentIndex]} slides={slides}>
                <TotalClimateFootprintSlide />
              </SlideContainer>
              <SlideContainer name="wastetreatment" currentSlide={slides[currentIndex]} slides={slides}>
                <WasteTreatmentByLocationSlide
                  currentLocation={currentLocation.location}
                  selector={{
                    ...selector2,
                    start_fy: dataAvailability.planetPreviousFY,
                    end_fy: dataAvailability.planetCurrentFY
                  }}
                  selectorLastFY={{
                    ...selector2,
                    start_fy: dataAvailability.planetPreviousFY,
                    end_fy: dataAvailability.planetPreviousFY
                  }}
                  wasteSelector={{
                    ...wasteSelector,
                    start_fy: dataAvailability.planetPreviousFY - 2000,
                    end_fy: dataAvailability.planetCurrentFY - 2000
                  }}
                />
              </SlideContainer>
              <SlideContainer name="wasterecycling" currentSlide={slides[currentIndex]} slides={slides}>
                <WasteRecyclingRateSlide
                  currentLocation={currentLocation.location}
                  selector={{ ...selector, start_fy: dataAvailability.planetPreviousFY - 1 }}
                />
              </SlideContainer>
              <SlideContainer name="energyefficiency" currentSlide={slides[currentIndex]} slides={slides}>
                <EnergyEfficiencySlide
                  currentLocation={currentLocation.location}
                  selector={{
                    ...selector2,
                    start_fy: dataAvailability.planetPreviousFY,
                    end_fy: dataAvailability.planetCurrentFY
                  }}
                />
              </SlideContainer>
              <SlideContainer name="waterefficiency" currentSlide={slides[currentIndex]} slides={slides}>
                <WaterEfficiencySlide currentLocationWithType={currentLocation.location} />
              </SlideContainer>
            </div>
          ) : (
            <ErrorCheckUrl />
          )}
        </div>
      </LanguageContext.Provider>
    </InStoreContext.Provider>
  )
}

const ErrorCheckUrl = () => <div>Please check the URL</div>
