import React from 'react'
import { format, isWithinInterval } from 'date-fns'
import { WeeklySalesData } from '../../../api/src/common-types'
import { getWeeklySales } from '../../lib/APIClient'
import { getCurrentLocationLabel } from '../Utils/utils'
import { SlideProps } from './ClimateFootprintLargestContributorSlide'
import { InStoreSlide } from './InStoreSlide'

import { InStoreI18n } from '../../Localisation'
import { datefnsLocale, formatLocalisationString } from '../../Localisation'
import { LanguageContext } from '../../context'
import { formatAbsoluteNumber } from '../Utils/format'
import { PPPAndTotalSalesGraph } from '../../pages/KPIPages/PppSales/PppSalesKPIPage'
import { useGetDataAvailability } from '../../services/general/service'

interface YearlySales {
  currentShare: number
  totalRolling: string
  pppAsisRolling: string
}

interface PppSalesSlideProps extends SlideProps {
  siteOrCountry: string
}

export const PppSalesSlide: React.FC<PppSalesSlideProps> = ({ currentLocation, selector, siteOrCountry }) => {
  const [dataSource, setDataSource] = React.useState('')
  const [weeklySalesData, setWeeklySalesData] = React.useState<WeeklySalesData[]>([])
  const [pppSalesShare, setPppSalesShare] = React.useState<number>()
  const lang = React.useContext(LanguageContext)
  const i18n = InStoreI18n[lang]
  const { data: dataAvailability } = useGetDataAvailability()
  const isSite = currentLocation.type === 'site'

  const currentLocationLabel = getCurrentLocationLabel(currentLocation, lang)

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getWeeklySales(siteOrCountry, selector.start_fy!, selector.end_fy!, 'all').then(data => {
      const latest = data
        .filter(d => d.lastUpdated != null)
        .sort((a, b) => new Date(b.readableDate).getTime() - new Date(a.readableDate).getTime())[0]

      const rolling12Start = new Date(latest.compDate)
      const rolling12End = new Date(latest.readableDate)
      const rolling12Months = data
        .filter(r => isWithinInterval(new Date(r.readableDate), { start: rolling12Start, end: rolling12End }))
        .slice(1)

      setWeeklySalesData(rolling12Months)
      setPppSalesShare(
        calculateYearlySales(
          data.filter(({ fiscalYear }) => fiscalYear === (dataAvailability?.profitCurrentFY ?? 2000) - 2000),
          siteOrCountry
        )?.currentShare
      )
      setDataSource(
        `${InStoreI18n[lang].Generic.deliveredSales} ${format(new Date(rolling12Months[0].readableDate), 'MMM yyyy', {
          locale: datefnsLocale(lang)
        })} - ${format(new Date(rolling12Months[rolling12Months.length - 1].readableDate), 'MMM yyyy', {
          locale: datefnsLocale(lang)
        })}`
      )
    })
  }, [])

  const onTrack = pppSalesShare ? currentLocation.profitShareGoalCurrFy - pppSalesShare : 0
  const salesShareLabel = ((pppSalesShare ?? 0) * 100).toFixed(1)
  const goalPercentageLabel = (currentLocation.profitShareGoalCurrFy * 100).toFixed(1)

  return (
    <InStoreSlide
      className="PppSalesThreePartSlide"
      header={{
        location: currentLocationLabel,
        description: i18n['PPP Sales'].description,
        title: i18n['PPP Sales'].title,
        dataSource: dataSource
      }}
      series={{
        name: i18n['PPP Sales'].share,
        value: salesShareLabel,
        unit: '%',
        badge: isSite
          ? ''
          : salesShareLabel === goalPercentageLabel
          ? formatLocalisationString(i18n['PPP Sales'].reachingGoal, { goalPercentageLabel })
          : onTrack < 0
          ? formatLocalisationString(i18n['PPP Sales'].aboveGoal, { goalPercentageLabel })
          : formatLocalisationString(i18n['PPP Sales'].belowGoal, { goalPercentageLabel }),
        onTrack
      }}
      graph={
        <PPPAndTotalSalesGraph
          weeklySales={weeklySalesData}
          currencyCode={currentLocation.currencyCode}
          isSales={true}
        />
      }
      isLoading={weeklySalesData.length === 0}
    />
  )
}

function calculateYearlySales(result: WeeklySalesData[] | undefined, siteOrCountry: string): YearlySales | null {
  if (result == null || result.length === 0) {
    return null
  }

  const pppSalesField = siteOrCountry === 'ALL' ? 'pppSales' : 'pppSalesLocal'
  const asisSalesField = siteOrCountry === 'ALL' ? 'asisSales' : 'asisSalesLocal'
  const totalSalesField = siteOrCountry === 'ALL' ? 'totalSales' : 'totalSalesLocal'

  const rollingSales = result.reduce(
    (sum, d) => ({
      pppAsis: sum.pppAsis + d[pppSalesField] + d[asisSalesField],
      total: sum.total + d[totalSalesField]
    }),
    { pppAsis: 0, total: 0 }
  )

  const d = result[result.length - 1]
  return {
    currentShare: (d.asisSalesFY + d.pppSalesFY) / d.totalSalesFY,
    totalRolling: formatAbsoluteNumber(Math.round(rollingSales.total)),
    pppAsisRolling: formatAbsoluteNumber(Math.round(rollingSales.pppAsis))
  }
}
