import React from 'react'
import { formatAbsoluteNumber, formatRelativeNumber } from '../../../../components/Utils/format'
import { KPIPerformance } from '../../../../components/KPIPage'
import { useDataContext } from './DataProvider'
import { useLocations } from '../../../../context'
import { getGoalsForLocation, getLocationId } from '../../../../components/Utils/utils'
import { isNil } from 'lodash'
import { useGetDataAvailability } from '../../../../services/general/service'

export const PerformanceBlock = () => {
  const { performance, recoveredProducts, goals, benchmarks } = useDataContext()
  const { data: dataAvailability } = useGetDataAvailability()
  const { currentLocation } = useLocations()

  const locationId = getLocationId(currentLocation)
  const currLocationRecyclingRateGoal = getGoalsForLocation(locationId, goals)

  const recoveredProductsYtdShare = React.useMemo(() => {
    if (!recoveredProducts) return 0

    const ytdShare = recoveredProducts
      .filter(r => r.fiscalYear === (dataAvailability?.planetCurrentFY ?? 2000) - 2000)
      .reduce(
        (acc, r) => ({
          recovered: acc.recovered + r.productsRecovered,
          potential: acc.potential + r.potentialRecoverable
        }),
        { recovered: 0, potential: 0 }
      )

    return ytdShare && ytdShare.potential > 0 ? ytdShare.recovered / ytdShare.potential : undefined
  }, [dataAvailability, recoveredProducts])

  const colorClassRecyclingRate = React.useMemo(() => {
    if (isNil(currLocationRecyclingRateGoal?.recyclingRateGoal)) return ''

    return (currLocationRecyclingRateGoal?.recyclingRateGoal ?? 0) <= (benchmarks.selectedLocation?.currentYtd ?? 0)
      ? 'OnTrack'
      : 'NotOnTrack'
  }, [currLocationRecyclingRateGoal, benchmarks.selectedLocation])

  return (
    <KPIPerformance
      heading="KPI Performance"
      units={['YTD']}
      kpis={
        !performance
          ? []
          : [
              {
                key: 'Share of Recovered Products',
                unit: '%',
                value: recoveredProductsYtdShare ? formatRelativeNumber(recoveredProductsYtdShare * 100) : ''
              },
              {
                key: 'Non-recycled waste',
                unit: 'tonnes',
                value: performance?.ytd_non_recycled_waste
                  ? formatAbsoluteNumber(performance.ytd_non_recycled_waste)
                  : ''
              },
              {
                key: 'Total waste',
                unit: 'tonnes',
                value: performance?.ytd_total_waste ? formatAbsoluteNumber(performance.ytd_total_waste) : ''
              },
              {
                key: 'Recycling Rate',
                unit: '%',
                value: performance?.recycling_rate ? formatRelativeNumber(performance.recycling_rate) : '',
                keyClassNames: ['Bold'],
                valueClassNames: ['Bold'],
                colorClass: colorClassRecyclingRate
              },
              {
                key: `FY${(dataAvailability?.planetCurrentFY || 2025) - 2000} Goal`,
                unit: '%',
                value: currLocationRecyclingRateGoal?.recyclingRateGoal
                  ? formatRelativeNumber(currLocationRecyclingRateGoal.recyclingRateGoal)
                  : '',
                keyClassNames: ['Bold'],
                valueClassNames: ['Bold']
              }
            ]
      }
    />
  )
}
