import { ApiNonRecycledAndGeneratedByType } from '../../../../api/src/common-types'

export function getNonRecycledAndGeneratedByTypeSeries(
  data: ApiNonRecycledAndGeneratedByType[] | undefined,
  categoryColors: Record<string, string>,
  isMonthly: boolean,
  isFootprint: boolean
) {
  if (!data) return []

  const descriptionById = data.reduce<Record<string, string>>((descr, item) => {
    descr[item.wasteTypeId] = item.description
    return descr
  }, {})
  const groupByField = isMonthly ? 'readable_date' : 'fiscal_year'
  const groupedData = data.reduce(
    (result: Record<string, Record<string, { waste: number; footprint: number }>>, item) => {
      const { wasteTypeId, waste, footprint } = item
      const groupValue = item[groupByField]

      if (!result[groupValue]) {
        result[groupValue] = {}
      }

      if (!result[groupValue][wasteTypeId]) {
        result[groupValue][wasteTypeId] = {
          waste: 0,
          footprint: 0
        }
      }

      result[groupValue][wasteTypeId].waste += waste
      result[groupValue][wasteTypeId].footprint += footprint

      return result
    },
    {}
  )

  const categories = new Set(Object.values(groupedData).flatMap(group => Object.keys(group)))

  const transformedData = Array.from(categories).map(categoryId => {
    const dataPoints = Object.entries(groupedData).map(([groupValue, values]) => ({
      x: isMonthly ? new Date(groupValue) : new Date(`${1999 + parseInt(groupValue)}-09-30T00:00:00.000Z`),
      y: isFootprint ? values[categoryId]?.footprint : values[categoryId]?.waste
    }))

    return {
      name: descriptionById[categoryId],
      color: categoryColors[categoryId] || '#000000',
      id: categoryId,
      data: dataPoints
    }
  })

  return transformedData
}
