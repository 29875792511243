import React from 'react'
import { ChartContainer } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { formatCustomerDistanceSeries } from './data/distance'
import { CustomerTravelContext } from './CustomerTravelProvider'
import { formatRelativeNumber } from '../../../components/Utils/format'
import { useDynamicLabel } from './data/useDynamicLabel'

export const CustomerDistanceKPI = () => {
  const { dates, footprint } = React.useContext(CustomerTravelContext)

  const personLabel = useDynamicLabel()

  const avgValue = React.useMemo(() => {
    const [sum, count] = footprint.reduce(
      (result, current) => {
        if (!current.curr_customer_distance) return result
        return [result[0] + (current.curr_customer_distance || 0), result[1] + 1]
      },
      [0, 0]
    )

    return sum / count
  }, [footprint])

  return (
    <div className="CardContainer">
      <div className="CardHeading FirstItem">
        Distance Traveled per {personLabel.single}
        <div className="Right">
          <span className="Amount">{formatRelativeNumber(avgValue)}</span>{' '}
          <span className="Unit">km per {personLabel.single} avg.</span>
        </div>
      </div>
      <ChartContainer
        domain={dates}
        series={formatCustomerDistanceSeries(footprint, dates)}
        generator={lineChart}
        dateFormat="month"
        lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
        yAxisTitle={`km/${personLabel.single}`}
        showDecimals
        isSmallGraph
        withDynamicFormatting
      />
    </div>
  )
}
