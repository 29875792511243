import React from 'react'

import { getFoodFootprint } from '../../../../lib/APIClient'
import { Serie } from '../../../../components/BaseGraphs/ChartContainer'
import { getIngkaFinancialYear } from '../../../../components/Utils/dates'
import { mapToClimateGraphIntensitySeries } from '../utils/mappers'
import colours from '../../../../Colours.module.scss'
import { isSiteId } from '../../../../components/Utils/utils'
import { useGetDataAvailability } from '../../../../services/general/service'

export type ClimateGraphDataContextType = {
  globalData: Serie
  countryData: Serie
  isLoading: boolean
}

type Props = React.PropsWithChildren<{
  countryCode?: string
  locationId: string
  loading: boolean
}>

const initialCountry: Serie = {
  color: colours.yellow1,
  name: 'FY24 Country',
  data: []
}

const initialGlobal: Serie = {
  color: colours.orange2,
  name: 'FY24 Global',
  data: []
}

const initial = {
  globalData: initialGlobal,
  countryData: initialCountry,
  isLoading: true
}

export const ClimateGraphDataContext = React.createContext<ClimateGraphDataContextType>(initial)

export const ClimateGraphProvider: React.FC<Props> = props => {
  const { countryCode, locationId, loading, children } = props

  const { data: dataAvailability } = useGetDataAvailability()

  const [countryData, setCountryData] = React.useState(initialCountry)
  const [globalData, setGlobalData] = React.useState(initialGlobal)
  const [isLoading, setLoadingData] = React.useState(true)
  const isSiteLvl = isSiteId(locationId)

  React.useEffect(() => {
    if (loading) setLoadingData(loading)
  }, [loading])

  React.useEffect(() => {
    setCountryData(initialCountry)
    setGlobalData(initialGlobal)
    loadData().then(success => {
      if (success) setLoadingData(false)
    })
  }, [countryCode])

  const loadData = async () => {
    if (!countryCode) return false

    const isGlobal = countryCode === 'ALL'
    const currentFY = dataAvailability?.foodCurrentFY || getIngkaFinancialYear(new Date()).getFullYear()

    if (isGlobal) return true

    if (!isSiteLvl) {
      const { data } = await getFoodFootprint('ALL', currentFY)

      setGlobalData({
        ...initialGlobal,
        data: mapToClimateGraphIntensitySeries(data)
      })
      return true
    }

    const [globalData, countryData] = await Promise.all([
      getFoodFootprint('ALL', currentFY),
      getFoodFootprint(countryCode, currentFY)
    ])

    setGlobalData({
      ...initialGlobal,
      data: mapToClimateGraphIntensitySeries(globalData.data)
    })
    setCountryData({
      ...initialCountry,
      data: mapToClimateGraphIntensitySeries(countryData.data)
    })

    return true
  }

  return (
    <ClimateGraphDataContext.Provider value={{ countryData, globalData, isLoading }}>
      {children}
    </ClimateGraphDataContext.Provider>
  )
}
