import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

import { ApiCluster, DisabledFeatures } from '../../api/src/common-types'
import * as APIClient from '../lib/APIClient'
import { DisabledFeaturesContext } from './DisabledFeaturesContext'
import { LocationsContext } from './LocationsContext'
import { FeedbackContext } from './FeedbackContext'
import { ThemeProvider } from './ThemeContext'
import { isLocal, msalApp, tenantId } from '../Authentication'
import { useGetDataAvailability, useGetUserInformation } from '../services/general/service'
import { isInStorePage } from '../routes/Utils'

export const Providers: React.FC<{ notLoaded: ReactNode }> = ({ children, notLoaded }) => {
  const [featureFlags, setFeatureFlags] = React.useState<DisabledFeatures[] | null>(null)
  const [clusters, setClusters] = React.useState<ApiCluster[]>([])
  const [locations, setLocations] = React.useState<APIClient.LocationWithType[]>([])
  const [isFeedbackOpen, setFeedbackOpen] = React.useState(false)
  const { data: dataAvailability } = useGetDataAvailability()
  const { data: userInformation } = useGetUserInformation()
  const { accounts } = useMsal()
  const { pathname } = useLocation()

  const openFeedbackModal = () => {
    setFeedbackOpen(true)
  }
  const closeFeedbackModal = () => {
    setFeedbackOpen(false)
  }

  React.useEffect(() => {
    const ingkaAccounts = isLocal()
      ? [
          {
            homeAccountId: 'dummy',
            environment: 'localhost',
            tenantId: 'tenantId',
            username: 'localuser',
            localAccountId: 'localAccountId'
          }
        ]
      : accounts.filter(a => a.tenantId === tenantId)
    const inInStorePage = isInStorePage(pathname)
    if (!inInStorePage && ingkaAccounts.length === 0) {
      return
    } else if (!inInStorePage) {
      msalApp.setActiveAccount(ingkaAccounts[0])
    }

    async function refreshLocations() {
      const [locations, clusters] = await Promise.all([APIClient.getAllLocations(), APIClient.getClusters()])
      setLocations(locations)
      setClusters(clusters)
    }
    refreshLocations()

    async function loadDisabledFeatures() {
      const disabledFeatures = await APIClient.getDisabledFeatures()
      setFeatureFlags(disabledFeatures)
    }
    loadDisabledFeatures()
  }, [JSON.stringify(accounts)])

  const isDisabledFeature = React.useCallback(
    feature => !featureFlags || featureFlags.includes(feature),
    [featureFlags]
  )

  return (
    <>
      {clusters.length > 0 &&
      locations.length > 0 &&
      dataAvailability &&
      (isInStorePage(location.pathname) || userInformation != null) ? (
        <ThemeProvider>
          <DisabledFeaturesContext.Provider value={isDisabledFeature}>
            <LocationsContext.Provider value={{ clusters, locations }}>
              <FeedbackContext.Provider value={{ isFeedbackOpen, openFeedbackModal, closeFeedbackModal }}>
                {children}
              </FeedbackContext.Provider>
            </LocationsContext.Provider>
          </DisabledFeaturesContext.Provider>
        </ThemeProvider>
      ) : (
        notLoaded
      )}
    </>
  )
}
